import { ISavedFilter } from 'src/@types/endpoints/other'
import { savedViewLocalStorageKey } from 'src/components/Overview/constants'
import { setLocalStorage } from 'src/components/Common/Table/DataTable/TableWrapper.utils'

export const setLocalStorageFromSavedView = (
  savedView: ISavedFilter | undefined
) => {
  if (!savedView) {
    return
  }

  setLocalStorage(savedViewLocalStorageKey, savedView.id)
}
