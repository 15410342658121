import { Box, Typography, Paper, Link as MuiLink } from '@mui/material'
import { useTranslation } from 'react-i18next'
import NotificationsContentListItemAttachments from './NotificationsContentListItemAttachments'
import { LinkToChat } from './NotificationsSavedMessage'

interface NotificationsContentNotificationProps {
  comment: INavigationNotification
  onCommentUpdate: (comment: INavigationNotification) => void
  isSaved: boolean
}

const NotificationsContentListItem: React.FC<NotificationsContentNotificationProps> = ({
  comment,
  onCommentUpdate,
  isSaved,
}) => {
  const { t } = useTranslation()
  return (
    <Box p={2} data-testid="navigation-notification-list-item">
      <Typography variant="body1Strong">
        {comment.author.full_name} | {comment.author.organization.name}
      </Typography>
      <Typography variant="subtitle1" color="grey.600" mt={0.5}>
        <LinkToChat comment={comment} />
      </Typography>
      <LinkToChat comment={comment}>
        <Paper
          elevation={0}
          sx={{
            backgroundColor: isSaved ? 'var(--grey-A100)' : 'secondary.50',
            mt: 2,
            p: 1.5,
          }}
        >
          <Typography
            component="div"
            dangerouslySetInnerHTML={{ __html: comment.content }}
          />
          <NotificationsContentListItemAttachments
            attachments={comment.comment_attachments}
          />
        </Paper>
      </LinkToChat>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle1" color="grey.600" mt={2}>
          {comment.human_time} ago
        </Typography>
        <MuiLink
          variant="body1"
          component="button"
          onClick={() => onCommentUpdate(comment)}
        >
          {isSaved
            ? t(
                'top_navigation.notifications.saved_comments.remove_link',
                'Remove from saved'
              )
            : t('common.save', 'Save')}
        </MuiLink>
      </Box>
    </Box>
  )
}

export default NotificationsContentListItem
