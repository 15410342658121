import { useState } from 'react'
import { Box, Paper } from '@mui/material'
import {
  getLocalStorage,
  setLocalStorage,
} from 'src/components/Common/Table/DataTable/TableWrapper.utils'
import ShipmentDocumentsFclGroupSelect from './ShipmentDocumentsFclGroupSelect'
import ShipmentDocumentsFclUngrouped from './ShipmentDocumentsFclUngrouped'
import ShipmentDocumentsFclGroupedByContainer from './ShipmentDocumentsFclGroupedByContainer'

const shipmentDocumentsGroupingStorageKey = 'shipmentDocumentsGrouping'

const ShipmentDocumentsFcl = (props) => {
  const defaultGrouping = getLocalStorage(
    shipmentDocumentsGroupingStorageKey,
    ''
  )
  const [documentGroup, setDocumentGroup] = useState<string>(defaultGrouping)
  const isGroupedByContainer = documentGroup === 'container'

  const onGroupChange = (group) => {
    setDocumentGroup(group)
    setLocalStorage(shipmentDocumentsGroupingStorageKey, group)
  }

  return (
    <Box pb={2}>
      <Box display="flex" justifyContent="end" mb={2}>
        <ShipmentDocumentsFclGroupSelect
          defaultGrouping={defaultGrouping}
          onClick={onGroupChange}
        />
      </Box>
      <Paper variant="outlined" elevation={0}>
        {!isGroupedByContainer && <ShipmentDocumentsFclUngrouped {...props} />}
        {isGroupedByContainer && (
          <ShipmentDocumentsFclGroupedByContainer {...props} />
        )}
      </Paper>
    </Box>
  )
}

export default ShipmentDocumentsFcl
