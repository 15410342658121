import { FunctionComponent, useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { showNotification } from 'src/stores/actionCreators/notifications'
import UserChannelClient from 'src/components/SocketHandlers/UserChannelClient'
import InlineNotification from 'src/components/Common/InlineNotification'
import RoomServiceIcon from '@mui/icons-material/RoomServiceOutlined'
import { promisifyAction } from '../../../utils'
import {
  shipmentsGetTimeline,
  shipmentsClearTimeline,
} from '../../../stores/actionCreators'
import ShipmentTimelinePhases from '../ShipmentTimelinePhases'
import ShipmentServicesWindow from '../ShipmentServicesWindow'
import ShipmentTrackTraceSubscriptions from '../../ShipmentTrackTraceSubscriptions'
import '../styles.scss'

interface IProps {
  shipmentId: number | string
}

const ShipmentTimelineSection: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)
  const dispatch = useDispatch()
  const getTimeline = promisifyAction(dispatch, shipmentsGetTimeline)
  const clearTimeline = promisifyAction(dispatch, shipmentsClearTimeline)

  const { services } = useSelector((state: IGlobalState) => ({
    services: state.shipmentOverview.services,
  }))

  const onReceivedSocketMessage = useCallback(
    (data) => {
      if (data.message_type === 'task_resolved') {
        if (data.message.resolved) {
          dispatch(
            showNotification({
              message: t('common.notifications.task_resolved', {
                task: data.message.task_type.name,
                defaultValue: '{{task}} task has been resolved',
              }),
              severity: 'success',
            })
          )
        }
        getTimeline(props.shipmentId)
      }
    },
    [props.shipmentId]
  )

  useEffect(() => {
    getTimeline(props.shipmentId)
    return () => {
      clearTimeline()
    }
  }, [props.shipmentId])

  const showServicesWindow = () => {
    setShow(true)
  }

  const hideServicesWindow = () => {
    setShow(false)
  }

  return (
    <article
      className="shipment-timeline-section"
      data-testid="shipment-timeline-section"
    >
      <ShipmentTrackTraceSubscriptions />
      <Box sx={{ marginBottom: 2 }}>
        <InlineNotification
          color="success"
          show={true}
          action={showServicesWindow}
          actionName={t('common.buttons.request_service', {
            defaultValue: 'Request service',
          })}
          message={t('common.notifications.request_shipment_service', {
            defaultValue: 'Can we help with anything?',
          })}
          icon={<RoomServiceIcon fontSize="inherit" color="inherit" />}
        />
      </Box>
      <UserChannelClient onReceived={onReceivedSocketMessage} />
      <ShipmentTimelinePhases />
      <ShipmentServicesWindow
        open={show}
        shipmentId={props.shipmentId}
        onClose={hideServicesWindow}
        defaultServices={services}
        title={t('shipments.request_service_modal.title', {
          defaultValue: 'What can we help you with?',
        })}
        header={t('shipments.request_service_modal.description', {
          defaultValue: 'Please select any services you require.',
        })}
        showSwitch={false}
      />
    </article>
  )
}

export default ShipmentTimelineSection
