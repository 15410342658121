import { useTranslation } from 'react-i18next'
import { useForm, FormProvider } from 'react-hook-form'
import { Divider } from '@mui/material'
import Content from 'src/components/Overview/Content'
import Stack from '@mui/material/Stack'
import { OverviewFilters } from 'src/services/Api/overview/types'
import {
  getLocallyStoredView,
  getDateOptionRangeForWeek,
  getDateOptionRange,
} from 'src/components/Overview/utils'
import { defaultValues } from './constants'
import './index.scss'

const Overview = () => {
  const { t } = useTranslation()
  const savedView = getLocallyStoredView()
  const savedViewFilter = savedView?.filter ?? {}
  const hasSavedArrivalDate = !!savedViewFilter?.['arrival_date']
  const dateSelectionOptions = getDateOptionRangeForWeek(t)
  const defaultETARangeOption = dateSelectionOptions[0]

  const defaultETA =
    !hasSavedArrivalDate && defaultETARangeOption
      ? { arrival_date: getDateOptionRange(defaultETARangeOption) }
      : {}

  const methods = useForm<OverviewFilters>({
    defaultValues: {
      ...defaultValues,
      ...defaultETA,
      ...savedViewFilter,
    },
  })

  return (
    <FormProvider {...methods}>
      <Stack
        component="main"
        direction="column"
        spacing={0}
        divider={<Divider />}
        sx={{
          height: '100%',
          flexGrow: 1,
          position: 'relative',
        }}
        data-testid="overview-page"
      >
        <Content />
      </Stack>
    </FormProvider>
  )
}

export default Overview
