import { Fragment, memo, useCallback, useRef, useState } from 'react'
import { renderToString } from 'react-dom/server'
import { ModalityEnum } from 'src/config/constants'
import MarkerClusterGroup from 'react-leaflet-cluster'
import {
  MapContainer,
  Marker,
  TileLayer,
  Polyline,
  ZoomControl,
  Popup,
} from 'react-leaflet'
import L, { Icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import './index.scss'
import { useTheme } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { Box, FormControlLabel, Paper, Switch, Typography } from '@mui/material'
import boatIconUrl from '../../assets/images/map_overview/boat_icon.svg'
import airIconUrl from '../../assets/images/map_overview/air_icon.svg'
import trainIconUrl from '../../assets/images/map_overview/train_icon.svg'
import startMarkerUrl from '../../assets/images/map_overview/start_marker.svg'
import endMarkerUrl from '../../assets/images/map_overview/end_marker.svg'
import MapTooltip from './MapTooltip'
import SmoothScroll from './SmoothScroll'

const SHOW_ROUTES_KEY = 'show_map_routes'

SmoothScroll(L)

const boatIcon = (count: number) =>
  L.divIcon({
    html: renderToString(
      <>
        <img src={boatIconUrl} alt="Boat Icon" width={32} height={32} />
        {count > 1 && <div className="badge">{count}</div>}
      </>
    ),
    iconSize: L.point(32, 32, true),
  })

const airIcon = (count: number) =>
  L.divIcon({
    html: renderToString(
      <>
        <img src={airIconUrl} alt="Boat Icon" width={32} height={32} />
        {count > 1 && <div className="badge">{count}</div>}
      </>
    ),
    iconSize: L.point(32, 32, true),
  })

const trainIcon = (count: number) =>
  L.divIcon({
    html: renderToString(
      <>
        <img src={trainIconUrl} alt="Boat Icon" width={32} height={32} />
        {count > 1 && <div className="badge">{count}</div>}
      </>
    ),
    iconSize: L.point(32, 32, true),
  })

const startMarker = (count: number) =>
  new Icon({
    iconUrl: startMarkerUrl,
    iconSize: [16, 16],
  })

const endMarker = (count: number) =>
  new Icon({
    iconUrl: endMarkerUrl,
    iconSize: [16, 16],
  })

const createClusterCustomIcon = function (cluster) {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: 'custom-marker-cluster',
    iconSize: L.point(40, 40, true),
  })
}

const getIconBasedOnModality = (modality: ModalityEnum, count: number) => {
  if (modality === ModalityEnum.Sea) {
    return boatIcon(count)
  }
  if (modality === ModalityEnum.Air) {
    return airIcon(count)
  }
  return trainIcon(count)
}

interface Props {
  markers: IShipmentTrack[]
  styles?: any
}

const CustomMap = memo((props: Props) => {
  const { t } = useTranslation()
  const featureGroupRef: any = useRef()
  const theme = useTheme()
  const [showRoutes, setShowRoutes] = useState(
    window.localStorage.getItem(SHOW_ROUTES_KEY) === 'true'
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowRoutes(event.target.checked)
    window.localStorage.setItem(
      SHOW_ROUTES_KEY,
      JSON.stringify(event.target.checked)
    )
  }

  const onMapReady = useCallback(
    (map) => {
      if (map !== null && props.markers.length > 0) {
        const bounds = L.latLngBounds(
          props.markers.reduce(
            (res, marker) => [...res, ...marker.route],
            [] as any
          )
        )
        map.fitBounds(bounds)
      }
    },
    [props.markers]
  )

  return (
    <MapContainer
      ref={onMapReady}
      attributionControl={false}
      center={[0, 0]}
      minZoom={2}
      maxZoom={8}
      zoomControl={false}
      scrollWheelZoom={false}
      style={props.styles}
    >
      <Box position="absolute" left={8} bottom={8} zIndex={1000}>
        <Paper sx={{ pl: 1, borderRadius: '4px' }} elevation={1}>
          <FormControlLabel
            disableTypography
            control={
              <Switch
                checked={showRoutes}
                onChange={handleChange}
                // bug in MUI TS? data-testid is not recognized
                inputProps={
                  {
                    'data-testid': 'show-routes-checkbox',
                  } as any
                }
              />
            }
            label={<Typography variant="subtitle1">Show routes</Typography>}
            labelPlacement="start"
          />
        </Paper>
      </Box>
      <ZoomControl
        ref={featureGroupRef}
        position="bottomright"
        zoomInTitle={t('common.maps.controls.zoom_in')}
        zoomOutTitle={t('common.maps.controls.zoom_out')}
      />
      <>
        {props.markers.map((marker) => (
          <Fragment key={marker.shipment_code}>
            <Marker position={marker.route[0]} icon={startMarker(0)} />
            <Marker
              position={marker.route[marker.route.length - 1]}
              icon={endMarker(0)}
            />
            {showRoutes && (
              <Polyline
                pathOptions={{
                  color: theme.palette.primary.main,
                  dashArray: '5, 10',
                }}
                positions={marker.route.map((route) => [route.lat, route.lng])}
              />
            )}
          </Fragment>
        ))}
        <MarkerClusterGroup
          chunkedLoading
          maxClusterRadius={15}
          spiderLegPolylineOptions={{
            weight: 0,
            color: 'transparent',
            opacity: 0,
          }}
          showCoverageOnHover={false}
          iconCreateFunction={createClusterCustomIcon}
          zoomToBoundsOnClick={false}
          onClick={(e) => {
            const map = e.layer._map
            if (map.getZoom() === map.getMaxZoom()) return
            map.flyToBounds(e.layer, { duration: 1 })
          }}
        >
          {props.markers.map(
            (marker) =>
              marker.current_position &&
              marker.current_position.lat !== 0 &&
              marker.current_position.lng !== 0 && (
                <Marker
                  key={marker.shipment_code}
                  position={marker.current_position}
                  icon={getIconBasedOnModality(
                    marker.type,
                    marker.shipment_content.shipments.length
                  )}
                >
                  <Popup>
                    <MapTooltip shipments={marker.shipment_content.shipments} />
                  </Popup>
                </Marker>
              )
          )}
        </MarkerClusterGroup>
      </>
      <TileLayer
        crossOrigin
        url="https://api.maptiler.com/maps/basic-v2/{z}/{x}/{y}.png?key=Mo18Hv7gYYAKwx9mN7wz"
      />
    </MapContainer>
  )
})

export default CustomMap
