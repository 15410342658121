import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider, Tab, Tabs } from '@mui/material'
import { showNotification } from 'src/stores/actionCreators/notifications'
import TabPanel from 'src/components/Common/TabPanel'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import promisifyAction from 'src/utils/promisifyAction'
import { chatSaveMessage } from 'src/stores/actionCreators'
import { updateNotification } from 'src/stores/actionCreators/navigationNotifications'
import NotificationsContentList from './NotificationsContentList'
import NotificationsSavedMessagesContainer from './NotificationsSavedMessagesContainer'

const NotificationsContent = () => {
  const [selectedTab, setTab] = useState('unread')
  const { currentUser } = useSelector(
    (state: IGlobalState) => ({
      currentUser: state.user,
    }),
    shallowEqual
  )
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const saveMessageAsync = promisifyAction(dispatch, chatSaveMessage)
  const updateMessage = promisifyAction(dispatch, updateNotification)

  const onCommentUpdate = async (
    comment: IComment | INavigationNotification
  ) => {
    const isSaved = currentUser?.id
      ? comment.saved_for_user_ids.includes(currentUser.id)
      : false
    dispatch(
      showNotification({
        message: isSaved
          ? t(
              'top_navigation.notifications.saved_comments.notifications.comment_unsaved',
              'Comment unsaved'
            )
          : t(
              'top_navigation.notifications.saved_comments.notifications.comment_saved',
              'Comment saved'
            ),
        severity: 'success',
      })
    )
    await saveMessageAsync(comment.chat_id, comment.id)
    await updateMessage(comment, currentUser?.id)
  }

  return (
    <Box width="500px">
      <Tabs value={selectedTab} onChange={(_event, newTab) => setTab(newTab)}>
        <Tab
          label={t(
            'top_navigation.notifications.unread_title',
            'Unread Notifications'
          )}
          data-testid="unread-notifications-tab"
          value="unread"
        />
        <Tab
          label={t('top_navigation.notifications.saved_title', 'Saved')}
          data-testid="saved-comments-tab"
          value="saved"
        />
      </Tabs>
      <Divider />
      <TabPanel
        value={selectedTab}
        index="unread"
        data-testid="unread-comments-tab-content"
      >
        <NotificationsContentList onCommentUpdate={onCommentUpdate} />
      </TabPanel>
      <TabPanel
        value={selectedTab}
        index="saved"
        data-testid="saved-comments-tab-content"
      >
        <NotificationsSavedMessagesContainer
          onCommentUpdate={onCommentUpdate}
        />
      </TabPanel>
    </Box>
  )
}

export default NotificationsContent
