import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useGetHubSpotTokenAsync } from 'src/services/Api/hubspot'
import { hubSpotTracking } from './constants'

const useHubSpot = (href: string): void => {
  const location = useLocation()
  const { fetchAsync: getHubSpotToken } = useGetHubSpotTokenAsync()
  const [identificationToken, setIdentificationToken] = useState<string | null>(
    null
  )

  const { currentUser } = useSelector((globalState: IGlobalState) => ({
    currentUser: globalState.user,
  }))

  const isProduction: boolean = href.includes('app.shypple.com')

  const identifyWithToken = async () => {
    const data = await getHubSpotToken({ refetchOnMount: false })
    const token = data?.data?.token ?? null
    if (token) {
      setIdentificationToken(token)
      window.hsConversationsSettings = {
        identificationEmail: currentUser.email,
        identificationToken: token,
      }
      const status = window.HubSpotConversations?.widget?.status()
      if (!status?.loaded) {
        window.HubSpotConversations.widget.load()
      }
    }
  }

  const initHubSpot = () => {
    const getToken = async () => {
      if (window.HubSpotConversations?.widget) {
        identifyWithToken()
      }
    }
    getToken()
  }

  useEffect(() => {
    if (!isProduction) return

    const script = document.createElement('script')

    script.onload = () => {
      window.hsConversationsSettings = {
        loadImmediately: false,
      }
    }

    script.src = hubSpotTracking
    script.async = true
    script.defer = true
    script.id = 'hs-script-loader'
    script.type = 'text/javascript'
    document.body.appendChild(script)
  }, [currentUser.uid])

  useEffect(() => {
    const { HubSpotConversations } = window
    if (!HubSpotConversations) {
      return
    }
    const status = HubSpotConversations?.widget?.status()

    if (status?.loaded) {
      HubSpotConversations?.widget?.refresh()
    } else {
      if (!identificationToken) {
        identifyWithToken()
      } else {
        window.hsConversationsSettings = {
          identificationEmail: currentUser.email,
          identificationToken,
        }
        HubSpotConversations?.widget?.load()
      }
    }
  }, [location])

  useEffect(initHubSpot, [window.HubSpotConversations?.widget])
}

export default useHubSpot
