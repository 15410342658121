import { FunctionComponent } from 'react'
import { TableCell, TableRow } from '@mui/material'
import ShipmentDocumentRowViewableBy from './ShipmentDocumentRowViewableBy'
import ShipmentDocumentRowActionsDocumentName from './ShipmentDocumentRowActionsDocumentName'
import ShipmentDocumentRowActions from './ShipmentDocumentRowActions'
import ShipmentDocumentRowActionsDocumentTypes from './ShipmentDocumentRowActionsDocumentTypes'
import ShipmentDocumentRowContainer from './ShipmentDocumentRowContainer'
import ShipmentDocumentRowBooking from './ShipmentDocumentRowBooking'
import { ShipmentDocumentRowProps } from './props'
import ShipmentDocumentRowDate from './ShipmentDocumentRowDate'

const ShipmentDocumentRow: FunctionComponent<ShipmentDocumentRowProps> = (
  props
) => {
  const { isFcl = false, isGroupedByContainer = false } = props

  return (
    <TableRow
      key={props.document.id}
      data-testid="shipment-documents-list-item"
    >
      {isFcl && isGroupedByContainer && <TableCell>&nbsp;</TableCell>}
      <ShipmentDocumentRowActionsDocumentName
        document={props.document}
        downloadable={props.downloadable}
      />
      <ShipmentDocumentRowActionsDocumentTypes
        documentTypes={props.document.types}
      />
      {isFcl && !isGroupedByContainer && (
        <ShipmentDocumentRowContainer
          shipmentId={props.shipmentId}
          containers={props.document.containers}
          containerCount={props.containersCount}
        />
      )}
      {props.withBooking && (
        <ShipmentDocumentRowBooking
          document={props.document}
          shipmentId={props.shipmentId}
        />
      )}
      <ShipmentDocumentRowDate document={props.document} />
      <ShipmentDocumentRowViewableBy
        viewableBy={props.document.viewable_by}
        uploadedBy={props.document.uploaded_by}
        documentId={props.document.id}
      />
      <ShipmentDocumentRowActions
        edit={props.onEdit}
        remove={props.onDelete}
        documentId={props.document.id}
        showPublicLink={props.showPublicLink}
        documentManageable={!props.disabled}
      />
    </TableRow>
  )
}

export default ShipmentDocumentRow
