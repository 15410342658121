import {
  useGetClustersAsync,
  ClustersResponse,
} from 'src/services/Api/clusters'
import AutocompleteAsyncBase from './AutocompleteAsyncBase'

const ClustersAutocompleteAsync = ({ token, onDelete }) => {
  const getOptions = (data: ClustersResponse) => {
    return (
      data?.map((cluster) => ({
        label: cluster.name,
        value: cluster.id,
      })) ?? []
    )
  }
  return (
    <AutocompleteAsyncBase
      token={token}
      onDelete={onDelete}
      formatter={getOptions}
      fetchFunction={useGetClustersAsync}
    />
  )
}

export default ClustersAutocompleteAsync
