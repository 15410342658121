import { Link } from 'react-router-dom'
import { Link as MuiLink, TableCell } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ShipmentDocumentRowContainerContent = ({
  containers,
  shipmentId,
  containerCount,
}) => {
  const { t } = useTranslation()
  if (containers.length === 0) {
    return '-'
  }
  if (containers.length === containerCount) {
    return (
      <MuiLink
        component={Link}
        variant="body1"
        to={`/shipments/${shipmentId}/containers/`}
      >
        {t('shipment_documents.table.all_containers', 'All containers')}
      </MuiLink>
    )
  }

  const text = containers
    .map((container: IContainerShort, index: number) => {
      return container.number ?? container.container_index
    })
    .join(', ')

  return containers.map((container: IContainerShort) => {
    return (
      <MuiLink
        component={Link}
        variant="body1"
        key={container.id}
        to={`/shipments/${shipmentId}/containers/#${container.id}`}
      >
        {text}
      </MuiLink>
    )
  })
}

const ShipmentDocumentRowContainer = ({
  containers,
  containerCount,
  shipmentId,
}) => {
  return (
    <TableCell
      className="text-wrapper s-medium left-aligned"
      data-testid="shipment-documents-list-item-containers"
    >
      <ShipmentDocumentRowContainerContent
        shipmentId={shipmentId}
        containers={containers}
        containerCount={containerCount}
      />
    </TableCell>
  )
}

export default ShipmentDocumentRowContainer
