import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { t } from 'i18next'
import { useState } from 'react'

const ShipmentDocumentsFclGroupSelect = ({ onClick, defaultGrouping }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [grouping, setGrouping] = useState<string>(defaultGrouping)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleItemClick = (group: string) => {
    setGrouping(group)
    handleClose()
    onClick(group)
  }

  const groups = [
    {
      label: t('shipment_documents.groups.ungrouped', 'Ungrouped'),
      value: '',
    },
    {
      label: t('shipment_documents.groups.container', 'Container'),
      value: 'container',
    },
  ]

  return (
    <div>
      <Button
        aria-haspopup="true"
        onClick={handleClick}
        id="documents-grouping-button"
        data-testid="documents-grouping-button"
        aria-expanded={open ? 'true' : undefined}
        aria-controls={open ? 'documents-grouping-menu' : undefined}
        endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      >
        {grouping === ''
          ? t('shipment_documents.groups.group_by_none', 'Group documents')
          : t(
              'shipment_documents.groups.group_by_container',
              'Group by container'
            )}
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        id="documents-grouping-menu"
        PaperProps={{
          elevation: 8,
          sx: {
            minWidth: '165px',
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'documents-grouping-button',
        }}
      >
        {groups.map((group) => (
          <MenuItem
            key={group.value}
            selected={grouping === group.value}
            onClick={() => handleItemClick(group.value)}
          >
            {group.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default ShipmentDocumentsFclGroupSelect
