import { useTranslation } from 'react-i18next'
import { TableCell, TableHead, TableRow } from '@mui/material'

const ShipmentDocumentsTableHeader = ({ groupByContainer, showContainer }) => {
  const { t } = useTranslation()
  return (
    <TableHead>
      <TableRow>
        {groupByContainer && showContainer && (
          <TableCell className="s-medium">
            {t('shipment_documents.fields.container.label', 'container')}
          </TableCell>
        )}
        <TableCell className="s-wide">
          {t('shipment_documents.fields.name.label', 'name')}
        </TableCell>
        <TableCell className="s-medium">
          {t('shipment_documents.fields.type.label', 'type')}
        </TableCell>
        {!groupByContainer && showContainer && (
          <TableCell className="s-medium">
            {t('shipment_documents.fields.container.label', 'container')}
          </TableCell>
        )}
        <TableCell className="s-medium">
          {t('shipment_documents.fields.shipper.label', 'shipper')}
        </TableCell>
        <TableCell className="s-medium">
          {t('shipment_documents.fields.uploaded_at.label', 'uploaded at')}
        </TableCell>
        <TableCell className="right-aligned s-medium">
          {t('shipment_documents.fields.viewable_by.label', 'viewable by')}
        </TableCell>
        <TableCell className="short"></TableCell>
      </TableRow>
    </TableHead>
  )
}

export default ShipmentDocumentsTableHeader
