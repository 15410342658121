import { Controller, useFormContext } from 'react-hook-form'
import { Box } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import TextArea from 'src/stories/MUI/TextArea'

const charactersPerRow = 36

const BookingPartiesModalFormTextArea = ({
  label,
  name,
  testId = '',
  required = true,
  maxLength = 512,
  placeholder = '',
  disabled = false,
}) => {
  const { control } = useFormContext()
  return (
    <Box>
      <InputLabel required={required} children={label} />
      <Controller
        name={name}
        control={control}
        rules={{
          required,
          maxLength: 512,
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextArea
            maxRows={3}
            value={value}
            error={!!error}
            disabled={disabled}
            onChange={onChange}
            data-testid={testId}
            maxLength={maxLength}
            placeholder={placeholder}
            showCharacterCount={value.length > 0}
            minRows={value.length > charactersPerRow ? 3 : undefined}
          />
        )}
      />
    </Box>
  )
}

export default BookingPartiesModalFormTextArea
