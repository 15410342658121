import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'
import { Chip, Typography, Stack } from '@mui/material'

const ChipLabel = ({ partOne, partTwo }) => {
  return (
    <Stack direction="row">
      <Typography
        component="span"
        mr={0.5}
        data-testid="dropdown-trigger-category"
      >
        {partOne}:
      </Typography>
      <Typography
        component="span"
        variant="body1Strong"
        data-testid="dropdown-trigger-value"
        sx={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '50vw',
          verticalAlign: 'middle',
        }}
      >
        {partTwo}
      </Typography>
    </Stack>
  )
}

const DropdownTrigger = ({
  partOne,
  anchorRef,
  partTwo,
  onClick,
  onDelete,
  open,
  testId = 'dropdown-trigger',
}) => {
  return (
    <Chip
      label={<ChipLabel partOne={partOne} partTwo={partTwo} />}
      title={partTwo}
      color="primary"
      variant="filled"
      ref={anchorRef}
      onClick={onClick}
      onDelete={onDelete}
      icon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      data-testid={testId}
      sx={{
        '&:focus': {
          backgroundColor: 'primary.100',
        },
      }}
    />
  )
}

export default DropdownTrigger
