import { UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { endpoints, queryKeys } from 'src/services/endpoints'
import { apiClient } from 'src/services/http-common'
import { useQueryAsync } from '../hooks'
import { createQueryString } from '../utils'

export type ClustersResponse = {
  id: number
  name: string
}[]

export const useGetClustersAsync = (
  options?: UseQueryOptions<ClustersResponse, AxiosError, ClustersResponse>
) => {
  const { clusters: key } = queryKeys

  const getClusters = (payload: object = {}) => {
    const queryString = createQueryString(payload)
    const url = endpoints.clusters + queryString

    return apiClient.get<any, ClustersResponse>(url)
  }

  return useQueryAsync([key], getClusters, options)
}
