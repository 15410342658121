import { HTMLAttributes, SyntheticEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { uniqBy } from 'lodash'
import Autocomplete, {
  AutocompleteRenderGroupParams,
  AutocompleteRenderOptionState,
  createFilterOptions,
} from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'
import HistoryIcon from '@mui/icons-material/History'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Input from 'src/components/Overview/Search/FormElements/Input'
import { FilterOptionsState } from '@mui/material/useAutocomplete'
import { MenuItemWithIcon } from 'src/stories/Lab/Menu/components/MenuItemWithIcon'
import { StyledPopper } from 'src/stories/Lab/Autocomplete/AutocompleteCommon.styles'
import { FilterOptions } from 'src/components/Overview/Search/types'
import RecentFiltersManager, {
  SavedFilterProps,
} from 'src/components/Overview/Search/RecentSearches'
import { getRenderInput } from 'src/stories/Lab/Autocomplete/Autocomplete.utils'
import PopperComponent from 'src/stories/Lab/Autocomplete/AutocompletePopperComponent'
import {
  searchFilterGroups,
  recentFiltersLimit,
} from 'src/components/Overview/Search/constants'
import { getSearchToken } from 'src/components/Overview/utils'

const filter = createFilterOptions<FilterOptions>()

const CategoryAutocompleteGroups = (params: AutocompleteRenderGroupParams) => {
  const value = searchFilterGroups?.[params.group]
  const { t } = useTranslation()

  return (
    <Stack key={params.group} className="group" data-testid={params.group}>
      <Typography
        variant="body1Strong"
        color="primary.main"
        children={t(value?.i18nKey, value?.label ?? '-')}
        component="div"
        sx={{ p: 2, pt: 1.5 }}
      />
      {params.children}
    </Stack>
  )
}

const CategoryAutocomplete = ({ tokens, options, onCategoryChange }) => {
  const recentSearchManager = new RecentFiltersManager()
  const recentSearches: SavedFilterProps[] = recentSearchManager
    .getRecentFilters()
    .splice(0, recentFiltersLimit)
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const recentSearchesOptions: FilterOptions[] = recentSearches.map(
    (recentSearch) => ({
      value: recentSearch.key,
      label: `${recentSearch.label}: ${recentSearch.text}`,
      component: Input,
      group: 'recent_searches',
      data: recentSearch.data,
      icon: HistoryIcon,
    })
  )

  const categoryOptions: FilterOptions[] = [
    ...uniqBy(recentSearchesOptions, 'label'),
    ...options.filter(
      (option: FilterOptions) =>
        !tokens
          .map((token: FilterOptions) => token.value)
          .includes(option.value)
    ),
  ]

  const getOptionEqualToValue = (option: FilterOptions, value: FilterOptions) =>
    option.value === value.value

  const getFilteredOptions = (
    options: FilterOptions[],
    params: FilterOptionsState<FilterOptions>
  ) => {
    const filtered = filter(options, params)
    const tokenValues = tokens.map((token) => token.value)
    const foundFilters = filtered.filter(
      (filteredItem) =>
        filteredItem.group === 'filters' &&
        !tokenValues.includes(filteredItem.value)
    )
    if (params.inputValue !== '' && foundFilters.length === 0) {
      filtered.push(getSearchToken(params.inputValue))
    }
    return filtered
  }

  const getOptionLabel = (option: FilterOptions) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option
    }
    return option.label
  }

  const getRenderedOption = (
    props: HTMLAttributes<HTMLLIElement>,
    option: FilterOptions,
    { selected }: AutocompleteRenderOptionState
  ) => {
    const { className, ...rest } = props
    return (
      <MenuItemWithIcon
        icon={option?.icon ?? undefined}
        {...rest}
        selected={selected}
        text={option.label}
      />
    )
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'search-and-filter-list' : undefined

  return (
    <>
      <Chip
        label={
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Box>
              {t(
                'components.autocomplete.search_and_filter',
                'Search and filter'
              )}
            </Box>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </Stack>
        }
        color="default"
        variant="filled"
        aria-describedby={id}
        onClick={handleClick}
        sx={{
          '&:focus': {
            backgroundColor: 'grey.300',
          },
        }}
        data-testid="search-and-filter-trigger"
      />
      <StyledPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Autocomplete
              open
              fullWidth
              autoHighlight
              onChange={(
                _event: SyntheticEvent<Element, Event>,
                value: FilterOptions | null
              ) => {
                onCategoryChange(value)
                handleClose()
              }}
              options={categoryOptions}
              isOptionEqualToValue={getOptionEqualToValue}
              value={null}
              blurOnSelect={true}
              filterOptions={getFilteredOptions}
              getOptionLabel={getOptionLabel}
              renderOption={getRenderedOption}
              renderInput={(props) => {
                return getRenderInput({
                  ...props,
                  inputProps: {
                    ...props.inputProps,
                    placeholder: t(
                      'vessels_page.search_placeholder',
                      'Type to find filter'
                    ),
                  },
                })
              }}
              PopperComponent={PopperComponent}
              groupBy={(option) => option.group}
              ListboxProps={{ style: { maxHeight: '64vh' } }}
              renderGroup={(params) => {
                return <CategoryAutocompleteGroups {...params} />
              }}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </>
  )
}

export default CategoryAutocomplete
