// import { routerReducer, RouterState } from 'connected-react-router'
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import account, { initialAccountState } from './account'
import user, { initialUserState } from './user'
import users, { initialUsersState } from './users'
import countries, { initialCountriesState } from './countries'
import personalDetails, { initialPersonalDetailsState } from './personalDetails'
import addresses, { initialAddressesState } from './addresses'
import companies, { initialCompaniesState } from './companies'
import dashboard, { initialDashboardState } from './dashboard'
import documents, { initialDocumentsState } from './documents'
import searches, { initialSearchQuotesState } from './searches'
import carriers, { initialCarriersState } from './carriers'
import carrierOrganizations, {
  initialCarriersOrgState,
} from './carrierOrganization'
import vessels, { initialVesselsState } from './vessels'
import hsCodes, { initialHsCodesState } from './hsCodes'
import shipmentTrack, { initialShipmentTrackState } from './shipmentTrack'
import chat, { initialChatState } from './chat'
import shipmentLink, { initialShipmentLinkState } from './shipmentLink'
import publicPO, { initialPublicPOState } from './publicPO'
import shipmentPrice, { initialShipmentPriceState } from './shipmentPrice'
import shipmentPurchaseOrders, {
  initialShipmentPurchaseOrdersState,
} from './shipmentPurchaseOrders'
import quotes, { initialQuotesState } from './quotes'
import shipments, { initialShipmentsState } from './shipments'
import connections, { initialConnectionsState } from './connections'
import tasks, { initialTasksState } from './tasks'
import searchPrevious, { initialSearchPreviousState } from './searchesPrevious'
import linkedCustomers, { initialLinkedCustomersState } from './linkedCustomers'
import searchOverview, {
  initialSearchQuoteOverviewState,
} from './searchOverview'
import searchBooking, { initialSearchQuoteBookingState } from './searchBooking'
import shipmentLayout, { initialShipmentLayoutState } from './shipmentLayout'
import shipmentOverview, {
  initialShipmentOverviewState,
} from './shipmentOverview'
import shipmentDocs, { initialShipmentDocsState } from './shipmentDocs'
import organizations, { initialOrganizationsState } from './organizations'
import organizationSettings, {
  initialOrganizationSettingsState,
} from './organizationSettings'
import flags, { initialFlagsState } from './flags'
import messages, { initialMessagesState } from './messageCenter'
import shipmentPickupAndDelivery, {
  initialShipmentPickupAndDelivery,
} from './shipmentPickupAndDelivery'
import bookings, { initialBookingsState } from './bookings'
import packageTypes, { initialPackageTypesState } from './packageTypes'
import purchaseOrders, { initialPurchaseOrdersState } from './purchaseOrders'
import pickUpsAndDeliveriesReducer, {
  initialPickUpAndDeliveryState,
} from './pickupsAndDeliveries'
import socketMessages, { initialSocketsState } from './webSocketReducers'
import shipmentQuotations, {
  initialShipmentQuotationState,
} from './shipmentQuotations'
import shipmentCommentsReducer, {
  initialState as initialShipmentCommentsState,
} from './shipmentComments'
import portsReducer, { initialPortsState } from './ports'
import reportsDashboardReducer, {
  initialState as reportsDashboardInitialState,
} from './reportsDashboard'
import serviceItemsReducer, {
  initialState as serviceItemsInitialState,
} from './serviceItems'
import notifications, {
  initialState as notificationsInitialState,
} from './notifications'
import navigationNotifications, {
  initialState as navigationNotificationsInitialState,
} from './navigationNotifications'
import watchShipmentRules, {
  initialWatchShipmentRulesState,
} from './watchShipmentRules'
import shipmentsWatchedBy, {
  initialShipmentsWatchedByState,
} from './shipmentsWatchedBy'
import lclForm, { initialLCLFormState } from './lclForm'

declare global {
  interface IGlobalState {
    navigationNotifications: INavigationNotificationState
    notifications: INotificationsState
    router?: any
    account: IAccountState
    user: IUserState
    users: IUsersState
    countries: ICountriesState
    addresses: IAddressesState
    personalDetails: IPersonalDetailsState
    companies: ICompaniesState
    dashboard: IDashboard
    documents: IDocuments
    rates: IRatesState
    hsCodes: IHsCodesState
    shipments: IShipmentsState
    connections: IConnectionsState
    shipmentTrack: IShipmentTrackState
    shipmentLink: IShipmentLinkState
    publicPO: IPublicPOState
    chat: IChatState
    shipmentPrice: IShipmentPriceState
    shipmentPurchaseOrders: IShipmentPurchaseOrdersState
    quotes: IQuotesState
    tasks: ITasksState
    shipmentLayout: IShipmentLayoutState
    searches: ISearchResult
    carriers: ICarriersResult
    carrierOrganizations: ICarrierOrganizationResult
    vessels: IVesselsResult
    linkedCustomers: ILinkedCustomersResult
    searchPrevious: ISearchPreviousResult
    searchOverview: ISearchOverview
    searchBooking: ISearchBooking
    shipmentOverview: IOverviewShipment
    shipmentDocs: IShipmentDocsState
    organizations: IOrganizationsState
    organizationSettings: IOrganizationsSettingsState
    flags: IFlagsState
    messages: IMessagesState
    shipmentPickupAndDelivery: IShipmentPickupAndDelivery
    bookings: IBookingsState
    packageTypes: IPackageTypeState
    purchaseOrders: IPurchaseOrdersState
    socketMessages: ISocketMessagesState
    shipmentQuotations: IShipmentQuotationReducerState
    shipmentComments: IShipmentCommentsState
    ports: IPortsReducerState
    pickUpsAndDeliveries: IPickUpAndDeliveryState
    reportsDashboard: IReportsDashboardState
    serviceItems: IServiceItemsState
    bookingTemplates: IBookingTemplates
    watchShipmentRules: IWatchShipmentRulesState
    shipmentsWatchedBy: IShipmentsWatchedByState
    lclForm: ILCLForm
  }
}

export const initialState = {
  navigationNotifications: navigationNotificationsInitialState,
  notifications: notificationsInitialState,
  user: initialUserState,
  users: initialUsersState,
  account: initialAccountState,
  countries: initialCountriesState,
  addresses: initialAddressesState,
  personalDetails: initialPersonalDetailsState,
  companies: initialCompaniesState,
  dashboard: initialDashboardState,
  documents: initialDocumentsState,
  searches: initialSearchQuotesState,
  carriers: initialCarriersState,
  carrierOrganizations: initialCarriersOrgState,
  vessels: initialVesselsState,
  linkedCustomers: initialLinkedCustomersState,
  hsCodes: initialHsCodesState,
  shipments: initialShipmentsState,
  connections: initialConnectionsState,
  shipmentTrack: initialShipmentTrackState,
  shipmentLink: initialShipmentLinkState,
  publicPO: initialPublicPOState,
  pickUpsAndDeliveries: initialPickUpAndDeliveryState,
  shipmentPrice: initialShipmentPriceState,
  shipmentLayout: initialShipmentLayoutState,
  shipmentPurchaseOrders: initialShipmentPurchaseOrdersState,
  quotes: initialQuotesState,
  tasks: initialTasksState,
  searchPrevious: initialSearchPreviousState,
  searchOverview: initialSearchQuoteOverviewState,
  searchBooking: initialSearchQuoteBookingState,
  shipmentOverview: initialShipmentOverviewState,
  chat: initialChatState,
  flags: initialFlagsState,
  shipmentDocs: initialShipmentDocsState,
  organizations: initialOrganizationsState,
  organizationSettings: initialOrganizationSettingsState,
  messages: initialMessagesState,
  shipmentPickupAndDelivery: initialShipmentPickupAndDelivery,
  bookings: initialBookingsState,
  packageTypes: initialPackageTypesState,
  purchaseOrders: initialPurchaseOrdersState,
  socketMessages: initialSocketsState,
  shipmentQuotations: initialShipmentQuotationState,
  shipmentComments: initialShipmentCommentsState,
  ports: initialPortsState,
  reportsDashboard: reportsDashboardInitialState,
  serviceItems: serviceItemsInitialState,
  watchShipmentRules: initialWatchShipmentRulesState,
  shipmentsWatchedBy: initialShipmentsWatchedByState,
  lclForm: initialLCLFormState,
}

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    navigationNotifications,
    notifications,
    user,
    users,
    account,
    addresses,
    personalDetails,
    companies,
    countries,
    dashboard,
    documents,
    searches,
    carriers,
    carrierOrganizations,
    vessels,
    linkedCustomers,
    hsCodes,
    shipments,
    connections,
    shipmentTrack,
    shipmentLink,
    publicPO,
    shipmentPrice,
    shipmentPurchaseOrders,
    quotes,
    tasks,
    searchPrevious,
    searchOverview,
    searchBooking,
    shipmentLayout,
    shipmentOverview,
    chat,
    flags,
    shipmentDocs,
    organizations,
    organizationSettings,
    messages,
    shipmentPickupAndDelivery,
    bookings,
    packageTypes,
    purchaseOrders,
    socketMessages,
    shipmentQuotations,
    shipmentComments: shipmentCommentsReducer,
    ports: portsReducer,
    pickUpsAndDeliveries: pickUpsAndDeliveriesReducer,
    reportsDashboard: reportsDashboardReducer,
    serviceItems: serviceItemsReducer,
    watchShipmentRules,
    shipmentsWatchedBy,
    lclForm,
  })

export default createRootReducer
