import {
  RecentSearchesResponse,
  IPlaceSuggestionResponse,
} from 'src/@types/endpoints/common'
import { FilterOptionValue } from 'src/components/Common/AutocompleteWrappers/types'

import { getPortLabel } from 'src/utils/ports'

export const getFormattedPorts = (
  data: IPlaceSuggestionResponse,
  group: string
): FilterOptionValue[] => {
  return (data?.data?.places ?? []).map((port) => ({
    value: Number(port.port_id),
    label: getPortLabel(port),
    group,
  }))
}

export const getFormattedRecentPorts = (
  data: RecentSearchesResponse,
  name: string,
  group: string
): FilterOptionValue[] => {
  const recentSearches =
    data?.data?.find((recentSearch) => recentSearch.name === name)?.values ?? []
  return recentSearches.map((port) => ({
    value: Number(port.id),
    label: getPortLabel(port),
    group,
  }))
}
