import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import MuiAccordionDetails, {
  AccordionDetailsProps,
} from '@mui/material/AccordionDetails'
import { KeyboardArrowDown } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  border: `1px solid ${theme.palette.grey[50]}`,
  '&::before': {
    display: 'none',
  },
}))

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<KeyboardArrowDown />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper': {
    '& svg': {
      color: theme.palette.primary.main,
    },
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(-180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

export const AccordionDetails = styled((props: AccordionDetailsProps) => (
  <MuiAccordionDetails {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[50]}`,
  padding: 0,
  '& tr:last-child': {
    '& td': {
      borderBottom: 0,
    },
  },
}))
