import { KeyboardArrowDown, KeyboardArrowUp, Close } from '@mui/icons-material'
import Box from '@mui/material/Box'
import { StyledTriggerButton } from 'src/components/Overview/styled'

const TriggerButton = (props) => {
  const { open, onReset, onClick, label, value, id, ...rest } = props
  const onResetClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    onReset()
  }
  return (
    <Box>
      <StyledTriggerButton
        size="medium"
        type="button"
        tabIndex={0}
        value={value}
        onClick={onClick}
        aria-describedby={id}
        variant={'contained'}
        endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        {...rest}
      >
        <Box component="span">{label}</Box>
        {rest.children}
        {value.length > 0 && (
          <Box
            sx={{ ml: 0.75, width: 18, height: 18 }}
            onClick={onResetClick}
            data-testid="clear-button"
          >
            <Close fontSize="inherit" sx={{ color: 'primary.main' }} />
          </Box>
        )}
      </StyledTriggerButton>
    </Box>
  )
}

export default TriggerButton
