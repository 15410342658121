import { Paper } from '@mui/material'
import MuiLink from '@mui/material/Link'
import { isEqual, omit } from 'lodash'
import { FunctionComponent, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import DueDateComponent from 'src/pages/ShipmentLayout/components/DueDateComponent'
import TaskStatusCell from 'src/pages/ShipmentLayout/components/TaskStatusCell'
import { useGetTasks } from 'src/services/Api/dashboard'
import { Table } from 'src/stories'
import { queryCachingOptions } from 'src/services/http-common'
import { NoTasks } from 'src/pages/EmptyStates'
import { convertDateToDateWithoutYear } from 'src/utils/helpers/shipmentDate'
import './styles.scss'
import { ITasksFilters, TasksFilters } from './TasksFilters'

interface RowProps {
  row: IMilestoneTask
}

const columns = {
  name: {
    name: 'Tasks Name',
    component: (rowProps: RowProps) => rowProps.row.task_type.name,
  },
  status: {
    name: 'Status',
    component: (rowProps: RowProps) => (
      <TaskStatusCell
        shipmentId={rowProps.row.shipment.id}
        {...rowProps}
        key={rowProps.row.task_status?.id || 'no-status'}
      />
    ),
  },
  due_date: {
    name: 'Due Date',
    component: (rowProps: RowProps) => (
      <DueDateComponent {...rowProps} key={rowProps.row.due_date} />
    ),
  },
  shipment: {
    name: 'shipment',
    component: (rowProps: RowProps) => {
      if (!rowProps.row.shipment) return null
      return (
        <>
          {rowProps.row.shipment.our_reference ? (
            <MuiLink
              component={Link}
              target="_blank"
              to={`/shipments/${rowProps.row.shipment.id}`}
            >
              {rowProps.row.shipment.our_reference}
            </MuiLink>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  reference: {
    name: 'reference',
    component: (rowProps: RowProps) => {
      if (!rowProps.row.shipment) return null
      return (
        <>
          {rowProps.row.shipment.reference &&
          rowProps.row.shipment.internal_reference ? (
            <MuiLink
              component={Link}
              target="_blank"
              to={`/shipments/${rowProps.row.shipment.id}`}
            >
              {rowProps.row.shipment.internal_reference || ''}{' '}
              {`- ${rowProps.row.shipment.reference || ''}`}
            </MuiLink>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  etd: {
    name: 'ETD',
    component: (rowProps: RowProps) =>
      rowProps.row.shipment.estimated_departure_date
        ? convertDateToDateWithoutYear(
            rowProps.row.shipment.estimated_departure_date
          )
        : 'TBA',
  },
  port_of_loading: {
    name: 'port of loading',
    component: (rowProps: RowProps) =>
      rowProps.row.shipment.port_pair.loading_port.name || '',
  },
  eta: {
    name: 'ETA',
    component: (rowProps: RowProps) =>
      rowProps.row.shipment.estimated_arrival_date
        ? convertDateToDateWithoutYear(
            rowProps.row.shipment.estimated_arrival_date
          )
        : 'TBA',
  },
  port_of_discharge: {
    name: 'port of discharge',
    component: (rowProps: RowProps) =>
      rowProps.row.shipment.port_pair.discharge_port.name || '',
  },
}

const initialFilters = {
  page: 0,
  search: '',
  due_date_start: undefined,
  due_date_end: undefined,
  loading_port_id: null,
  discharge_port_id: null,
  task_status_type_ids: [],
  tasks_per_page: 10,
}

const DashboardTaskManager: FunctionComponent<{}> = () => {
  const [filters, setFilters] = useState<ITasksFilters>(initialFilters)
  const { onlyWatchedShipments, settings } = useSelector(
    ({ shipmentsWatchedBy, user }: IGlobalState) => ({
      onlyWatchedShipments: shipmentsWatchedBy.onlyWatchedShipments,
      settings: user.user_dashboard_settings,
    })
  )
  const compact = settings.find(({ code }) => code === 'compact_table_density')

  const createFiltersQuery = () => {
    return { ...filters, task_status_type_ids: filters.task_status_type_ids }
  }

  const { data, isLoading } = useGetTasks(
    {
      ...createFiltersQuery(),
      page: filters.page + 1,
      only_watched_shipments: onlyWatchedShipments,
    },
    {
      onSuccess: (data) => {
        const tasks_per_page = data?.per_page || initialFilters.tasks_per_page
        setFilters({ ...filters, tasks_per_page })
      },
      ...queryCachingOptions.long,
    }
  )

  const onPageChange = (page: number) => {
    setFilters({ ...filters, page })
  }

  const onChangeFilters = (name, value) => {
    setFilters({ ...filters, [name]: value })
  }

  const onChangeDateFilter = (newDueDate) => {
    setFilters({
      ...filters,
      ...newDueDate,
    })
  }

  const resetFilters = () => {
    setFilters(initialFilters)
  }

  return (
    <div className="dashboard-tasks-table-container">
      <TasksFilters
        onChangeFilters={onChangeFilters}
        onChangeDateFilter={onChangeDateFilter}
        filters={filters}
        resetFilters={resetFilters}
        disableFilters={isEqual(
          omit(filters, 'page'),
          omit(initialFilters, 'page')
        )}
      />
      <Table
        component={Paper}
        loading={isLoading}
        columns={columns}
        rows={data?.records || []}
        size={compact?.enabled ? 'small' : 'medium'}
        emptyState={<NoTasks />}
        maxHeight={583}
        pagination={{
          rows: data?.per_page || 0,
          total: data?.total_count || 0,
          current: Math.max(0, (data?.page || 0) - 1),
          onChange: onPageChange,
        }}
      />
    </div>
  )
}

export default DashboardTaskManager
