import { FunctionComponent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import capitalize from 'lodash/capitalize'
import { Trans, useTranslation } from 'react-i18next'
import { Box, Button, Link, Paper, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import { statuses } from 'src/pages/ShipmentOverview/constants'
import ShipmentOverviewDrawer from 'src/pages/ShipmentOverview/ShipmentOverviewDrawer'
import {
  shipmentOverviewGetData,
  shipmentsOverviewSetDrawer,
  setShipmentEditModalOpen,
} from 'src/stores/actionCreators'
import {
  trackAndTraceResume,
  trackAndTraceUsePortPair,
} from 'src/stores/actionCreators/trackAndTrace'
import { promisifyAction } from 'src/utils'
import SelectableChip from '../SelectableChip'

interface ISubscriptionBody {
  shipment: IOverviewShipment
  track_trace_subscription: ITrackTraceSubscription | null
  tts_status: string
}

const ShipmentTrackTraceSubscriptionsBody: FunctionComponent<ISubscriptionBody> = ({
  shipment,
  track_trace_subscription,
  tts_status,
}) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const trackAndTraceResumeAsync = promisifyAction(
    dispatch,
    trackAndTraceResume
  )
  const trackAndTraceUsePortPairAsync = promisifyAction(
    dispatch,
    trackAndTraceUsePortPair
  )
  const shipmentOverviewGetDataAsync = promisifyAction(
    dispatch,
    shipmentOverviewGetData
  )

  const shipmentsOverviewSetDrawerAsync = promisifyAction(
    dispatch,
    shipmentsOverviewSetDrawer
  )

  const setShipmentEditModalOpenAsync = promisifyAction(
    dispatch,
    setShipmentEditModalOpen
  )

  const resumeTrackAndTrace = async () => {
    await trackAndTraceResumeAsync(shipment.id, {
      trackId: track_trace_subscription?.id,
    })
    dispatch(shipmentOverviewGetData(`${shipment.id}`))
  }

  const onActivateClick = () => {
    if (
      (shipment.modality !== 'sea' ||
        shipment.operation_type !== 'visibility_only') &&
      shipment.load_type !== 'lcl'
    ) {
      shipmentsOverviewSetDrawerAsync(true)
    } else {
      setShipmentEditModalOpenAsync(true)
    }
  }

  switch (tts_status) {
    case 'completed':
      return (
        <>
          {t('shipment_track_and_trace.status.completed.description', {
            defaultValue: 'Your shipment has been delivered',
          })}
        </>
      )
    case 'paused':
      return (
        <Trans
          i18nKey="shipment_track_and_trace.status.paused.description"
          defaults="Your track and trace subscription has been paused. <0>Click here to resume it.</0>"
          components={[
            <Link
              sx={{ ':hover': { cursor: 'pointer' } }}
              variant="body1"
              data-testId="resume-t-t"
              onClick={resumeTrackAndTrace}
            />,
          ]}
        />
      )
    case 'paused_eta_updates':
    case 'active':
      if (!track_trace_subscription?.last_provider_update)
        return (
          <>
            {t('shipment_track_and_trace.status.active.description', {
              context: 'without_provider_update',
              defaultValue:
                'Vessel schedule and location will be automatically updated.',
            })}
          </>
        )

      return (
        <>
          {t('active_with_provider_update.status.active', {
            context: 'with_provider_update',
            defaultValue: 'Last updated {{lastUpdate}}',
            lastUpdate: DateTime.fromISO(
              track_trace_subscription.last_provider_update
            ).toRelative({
              locale: i18n.language,
            }),
          })}
        </>
      )
    case 'pending':
      return (
        <>
          {t('shipment_track_and_trace.status.pending.description', {
            defaultValue:
              'Track & trace will be activated after ETD. For some shipments, this can take up to 2 days after ETD.',
          })}
        </>
      )
    case 'invalid':
      return (
        <Trans
          i18nKey="shipment_track_and_trace.status.invalid.description"
          defaults="Your booking number seems to be invalid. <0>Please change it here.</0>"
          components={[
            <Link
              sx={{ ':hover': { cursor: 'pointer' } }}
              variant="body1"
              data-testId="invalid-t-t"
              onClick={() => {
                shipmentsOverviewSetDrawerAsync(true)
              }}
            />,
          ]}
        />
      )
    case 'error':
      const pol = track_trace_subscription?.loading_port?.name || '-'
      const pod = track_trace_subscription?.discharge_port?.name || '-'

      return (
        <Trans
          i18nKey="shipment_track_and_trace.status.error.description"
          defaults="Ports from track and trace do not match shipment's ports. Tracking is available from: <strong>{{portPair}}</strong>. You can <linkPortPair>use this port pair</linkPortPair>, or <linkTrackAndTrace>add a new track and trace subscription</linkTrackAndTrace>."
          values={{ portPair: `${pol} - ${pod}` }}
          components={{
            strong: <strong />,
            linkPortPair: (
              <Link
                sx={{ ':hover': { cursor: 'pointer' } }}
                variant="body1"
                data-testId="error-t-t"
                onClick={async () => {
                  await trackAndTraceUsePortPairAsync(shipment.id, {
                    trackId: track_trace_subscription?.id,
                  })

                  shipmentOverviewGetDataAsync(`${shipment.id}`)
                }}
              />
            ),
            linkTrackAndTrace: (
              <Link
                sx={{ ':hover': { cursor: 'pointer' } }}
                variant="body1"
                data-testId="error-add-new-t-t"
                onClick={() => {
                  shipmentsOverviewSetDrawerAsync(true)
                }}
              />
            ),
          }}
        />
      )
    default:
      return (
        <Trans
          i18nKey="shipment_track_and_trace.status.default.description"
          defaults="Please add tracking keys to get tracking updates. <0>Activate track and trace</0>"
          components={[
            <Button
              data-testid="activate-t-n-t"
              className="mt-10"
              variant="contained"
              onClick={onActivateClick}
            />,
          ]}
        />
      )
  }
}

const ShipmentTrackTraceSubscriptions: FunctionComponent = () => {
  const { t } = useTranslation()
  const { shipment, visibility_only, subscription, tts_status } = useSelector(
    (state: IGlobalState) => ({
      shipment: state.shipmentOverview,
      visibility_only: state.shipmentOverview.visibility_only,
      subscription: state.shipmentOverview.relevant_subscription,
      tts_status: state.shipmentOverview.track_trace_status,
    })
  )

  if (!visibility_only) return null

  const translationKey = `shipment_track_and_trace.status.${tts_status}.title`

  const text = t(translationKey, {
    defaultValue: capitalize(tts_status.split('_').join(' ')),
  })

  return (
    <Paper
      sx={{ marginBottom: 2, padding: 2 }}
      data-testid="shipment-t-and-t-subscriptions"
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          '@media (min-width: 600px) and (max-width: 1424px)': {
            alignItems: 'baseline',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            '@media (min-width: 600px) and (max-width: 1424px)': {
              flexDirection: 'column',
              alignItems: 'baseline',
            },
          }}
        >
          <Typography variant="h5" children={t('common.track_and_trace')} />
          <Box pr={1}>
            <SelectableChip
              disabled
              sx={{
                marginLeft: 2,
                '@media (min-width: 600px) and (max-width: 1424px)': {
                  marginLeft: 0,
                  marginTop: 1,
                },
              }}
              size="small"
              variant="filled"
              value={{
                name:
                  tts_status === 'paused_eta_updates'
                    ? t('shipment_track_and_trace.status.active.title', {
                        defaultValue: 'Active',
                      })
                    : text,
                color: statuses[tts_status] ?? 'default',
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box mt={2} data-testid="shipment-t-and-t-subscriptions--body">
        <Typography variant="body2" component="div">
          <ShipmentTrackTraceSubscriptionsBody
            shipment={shipment}
            track_trace_subscription={subscription}
            tts_status={tts_status}
          />
        </Typography>
      </Box>
      <ShipmentOverviewDrawer />
    </Paper>
  )
}
export default ShipmentTrackTraceSubscriptions
