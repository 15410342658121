import {
  FunctionComponent,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showNotification } from 'src/stores/actionCreators/notifications'
import {
  Grid,
  Button,
  Checkbox,
  Typography,
  FormControlLabel,
  SelectChangeEvent,
} from '@mui/material'
import LinkIcon from '@mui/icons-material/Link'
import LoadingButton from '@mui/lab/LoadingButton'
import { Dialog, DialogContent } from 'src/stories'
import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'
import FormLabel from 'src/stories/Lab/FormLabel'
import Input from '../../Common/Input/MuiInput'
import { promisifyAction } from '../../../utils'
import {
  updateContainerInformation,
  updateShipmentContainer,
  createShipmentContainer,
  updateShipmentReference,
} from '../../../stores/actionCreators'
import UseAsSharedReferenceConfirmation from './UseAsSharedReferenceConfirmation'
import ReeferSettings from './ReeferSettings'
import './styles.scss'

interface IProps {
  isNewContainer?: boolean
  shipmentId: string | number
  container: IShipmentContainer | null
  open: boolean
  close: (status: boolean) => void
  fetchData: () => void
  isLclOrAir?: boolean
  sharedReference?: string | null
}

export interface IContainerDetails {
  container_number: string
  seal_number: string
  temperature: string | number
  humidity: string | number
  ventilation: string | number
  vents_open: boolean | null
  drains_open: boolean | null
  food_grade: boolean
  heavy_tested: boolean
  non_operating_reefer: boolean
  shippers_owned: boolean
  co2_regulation: string
  comments: string
  container_type_id: number | string
  shipment_id: string | number
  tare_weight_kg: number | string
}

const initialContainerDetails: IContainerDetails = {
  container_number: '',
  seal_number: '',
  temperature: '',
  humidity: '',
  ventilation: '',
  vents_open: null,
  drains_open: null,
  food_grade: false,
  heavy_tested: false,
  non_operating_reefer: false,
  shippers_owned: false,
  co2_regulation: '',
  comments: '',
  container_type_id: '',
  shipment_id: '',
  tare_weight_kg: '',
}

const containerNumberExp: RegExp = /\b[A-Z]{4}[0-9]{7}\b/

const getContainerNumberIsValid = (containerNumber: string): boolean => {
  return containerNumberExp.test(containerNumber)
}

const ContainerEditWindow: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const sharedReference = props?.sharedReference ?? ''
  const [containerDetails, setContainerDetails] = useState<IContainerDetails>(
    initialContainerDetails
  )
  const [sealError, setSealError] = useState<string>('')
  const [isRefeer, setIsRefeer] = useState<boolean>(false)
  const [loadingButton, setLoadingButton] = useState<boolean>(false)
  const [updatingReference, setUpdatingReference] = useState<boolean>(false)
  const { containerTypes } = useSelector((state: IGlobalState) => ({
    containerTypes: state.packageTypes.container_types,
  }))
  const [isContainerNumberValid, setIsContainerNumberValid] = useState<boolean>(
    getContainerNumberIsValid(props?.container?.number ?? '')
  )
  const [
    useAsSharedReferenceDialogOpen,
    setUseAsSharedReferenceDialogOpen,
  ] = useState<boolean>(false)

  const createContainerAsync = promisifyAction(
    dispatch,
    createShipmentContainer
  )

  const updateContainerAsync = promisifyAction(
    dispatch,
    updateContainerInformation
  )

  const updateReferenceAsync = promisifyAction(
    dispatch,
    updateShipmentReference
  )

  const containerTypesOptions = containerTypes.map((containerType) => ({
    id: containerType.id,
    label: containerType.name,
  }))

  const setValuesFromProps = (props) => {
    setContainerDetails({
      container_number: props.container.number || '',
      seal_number: props.container.seal_number || '',
      temperature: props.container.temperature || '',
      humidity: props.container.humidity || '',
      ventilation: props.container.ventilation || '',
      vents_open: props.container.vents_open,
      drains_open: props.container.drains_open,
      food_grade: props.container.food_grade,
      heavy_tested: props.container.heavy_tested,
      non_operating_reefer: props.container.non_operating_reefer,
      shippers_owned: props.container.shippers_owned,
      co2_regulation: props.container.co2_regulation || '',
      comments: props.container.comments || '',
      container_type_id: props.container?.container_type?.id || '',
      shipment_id: props.shipmentId,
      tare_weight_kg: props.container?.tare_weight_kg || '',
    })
    setIsRefeer(props.container.equipment_controlled_atmosphere)
  }

  useEffect(() => {
    if (props.container) {
      setValuesFromProps(props)
    } else {
      setContainerDetails(initialContainerDetails)
      setIsRefeer(false)
    }
  }, [props.container])

  const onSave = async (): Promise<any> => {
    if (!disableCondition) {
      setLoadingButton(true)
      setSealError('')
      if (props.isNewContainer) {
        const extendedData = containerDetails
        extendedData.shipment_id = props.shipmentId
        await createContainerAsync(extendedData)
      } else {
        await updateContainerAsync(props.container?.id, containerDetails)

        dispatch(
          updateShipmentContainer({
            id: props.container?.id,
            ...containerDetails,
          })
        )
      }
      dispatch(
        showNotification({
          message: props.isNewContainer
            ? t(
                'shipment_containers.notifications.container_created',
                'Container created.'
              )
            : t(
                'shipment_containers.notifications.container_updated',
                'Container updated.'
              ),
          severity: 'success',
        })
      )
      props.close(!!props.isNewContainer)
      props.fetchData()
      setLoadingButton(false)
    }
  }

  const onClose = useCallback((): void => {
    setLoadingButton(false)
    setSealError('')
    props.close(!!props.isNewContainer)
  }, [props.close])

  const handleContainerDetailsChange = useCallback(
    (value: string | number, field: string): void => {
      if (field === 'container_number') {
        setIsContainerNumberValid(getContainerNumberIsValid(`${value}`))
        setSealError('')
      }
      setContainerDetails({
        ...containerDetails,
        [field]: value,
      })
    },
    [containerDetails]
  )

  const checkContainerDetails = useCallback(
    (value: string, field: string): void => {
      if (field === 'container_number' && !!value) {
        setSealError(
          !isContainerNumberValid
            ? t(
                'shipment_containers.validations.container_number',
                'Container number should be 4 capital letters followed by 7 numbers'
              )
            : ''
        )
      }
    },
    [containerDetails]
  )

  const renderInput = useCallback(
    (
      label: string,
      field: string,
      placeholder: string = t(
        'shipment_containers.placeholders.enter_value',
        'Enter value'
      ),
      disabled: boolean = false,
      error: boolean = false,
      helperText: string = '',
      type: string = 'text'
    ): React.ReactNode => {
      return (
        <>
          <FormLabel label={label} />
          <Input
            type={type}
            name={field}
            value={containerDetails[field] || ''}
            onChange={handleContainerDetailsChange}
            onBlur={checkContainerDetails}
            disabled={disabled}
            placeholder={placeholder}
            error={error}
            helperText={helperText}
          />
        </>
      )
    },
    [containerDetails, sealError, t]
  )

  const disableCondition: boolean = useMemo(() => {
    const isFieldEmpty = Object.values(containerDetails).every(
      (value) => value === ''
    )
    return Boolean(isFieldEmpty || loadingButton || sealError)
  }, [loadingButton, sealError, containerDetails])

  const onCheckboxChange = (e, checked) => {
    setContainerDetails({
      ...containerDetails,
      [e.target.name]: checked,
    })
  }

  const onContainerTypeChange = (event: SelectChangeEvent<unknown>) => {
    const containerTypeId = event.target.value as number
    const container = containerTypes.find(
      (containerType) => containerType.id === containerTypeId
    )
    setContainerDetails({
      ...containerDetails,
      container_type_id: containerTypeId,
    })

    if (container) {
      setIsRefeer(container.equipment_controlled_atmosphere)
    }
  }

  const renderCheckbox = (name: string, label: string) => {
    return (
      <div className="edit-container--input">
        <FormControlLabel
          control={
            <Checkbox
              name={name}
              checked={containerDetails[name]}
              onChange={onCheckboxChange}
            />
          }
          label={label}
        />
      </div>
    )
  }

  const saveShipmentReference = async (sharedRef: string): Promise<void> => {
    setUseAsSharedReferenceDialogOpen(false)
    setUpdatingReference(true)
    updateReferenceAsync(props.shipmentId, {
      shared_reference: sharedRef,
    })
      .then(() => {
        dispatch(
          showNotification({
            message: t(
              'shipment_containers.notifications.reference_number_updated',
              'Successfully set reference number. Your shipment has been updated.'
            ),
            severity: 'success',
          })
        )
      })
      .finally(() => {
        setUpdatingReference(false)
      })
  }

  const handleClickOnUseAsSharedReference = () => {
    if (!!sharedReference) {
      return setUseAsSharedReferenceDialogOpen(true)
    }
    saveShipmentReference(containerDetails.container_number)
  }

  return (
    <Dialog
      maxWidth="lg"
      open={props.open}
      onClose={onClose}
      title={
        props.isNewContainer
          ? t(
              'shipment_containers.headings.add_new_container',
              'Add new container'
            )
          : t(
              'shipment_containers.headings.edit_container_setting',
              'Edit container settings'
            )
      }
      actions={
        <>
          <Button variant="text" onClick={onClose}>
            {t('common.buttons.cancel')}
          </Button>
          <LoadingButton
            onClick={onSave}
            variant="contained"
            loading={loadingButton}
            disabled={disableCondition}
          >
            {t('common.buttons.save', 'Save')}
          </LoadingButton>
        </>
      }
    >
      <DialogContent>
        <div className="edit-container--wrapper">
          <Grid container spacing={2}>
            <Grid item xs={3} data-testid="shipment-container-type">
              <SingleSelect
                placeholder={t(
                  'common.forms.fields.container_type.placeholder',
                  'Container type'
                )}
                value={containerDetails.container_type_id}
                onChange={onContainerTypeChange}
                options={containerTypesOptions}
                label={t(
                  'common.forms.fields.container_type.label',
                  'Container type'
                )}
                disabled={props.isLclOrAir}
              />
            </Grid>
            <Grid item xs={2} data-testid="shipment-seal-number">
              {renderInput(
                t('common.forms.fields.seal_number.label', 'Seal number'),
                'seal_number'
              )}
            </Grid>
            <Grid item xs={3} data-testid="shipment-container-number">
              {renderInput(
                t(
                  'common.forms.fields.container_number.label',
                  'Container number'
                ),
                'container_number',
                t(
                  'common.forms.fields.container_number.placeholder',
                  'AAAA1234567'
                ),
                false,
                !!sealError,
                sealError
              )}
            </Grid>
            <Grid item xs={2} data-testid="shipment-tare-weight">
              {renderInput(
                t('common.forms.fields.tare_weight.label', 'Tare weight (kg)'),
                'tare_weight_kg',
                undefined,
                false,
                false,
                '',
                'number'
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                size="large"
                onClick={handleClickOnUseAsSharedReference}
                disabled={!isContainerNumberValid || updatingReference}
              >
                <LinkIcon fontSize="small" />
                {t(
                  'common.buttons.use_as_shared_reference',
                  'Use as shared reference'
                )}
              </Button>
            </Grid>
          </Grid>
          <div className="edit-container--block">
            <Typography
              variant="h5"
              children={t(
                'common.forms.fields.special_requirements.label',
                'Special requirements'
              )}
            />
            <div
              className="flex"
              data-testid="shipment-container-special-requirements"
            >
              {renderCheckbox(
                'food_grade',
                t(
                  'common.forms.fields.special_requirements.options.food_grade',
                  'Food grade'
                )
              )}
              {renderCheckbox(
                'heavy_tested',
                t(
                  'common.forms.fields.special_requirements.options.heavy_tested',
                  'Heavy tested'
                )
              )}
              {renderCheckbox(
                'shippers_owned',
                t(
                  'common.forms.fields.special_requirements.options.shippers_owned',
                  'Shippers owned'
                )
              )}
            </div>
          </div>
          {isRefeer && (
            <ReeferSettings
              containerDetails={containerDetails}
              setContainerDetails={setContainerDetails}
              sealError={sealError}
              onCheckboxChange={onCheckboxChange}
            />
          )}
        </div>
      </DialogContent>
      {useAsSharedReferenceDialogOpen && (
        <UseAsSharedReferenceConfirmation
          sharedReference={sharedReference}
          open={useAsSharedReferenceDialogOpen}
          containerNumber={containerDetails.container_number}
          onClose={() => {
            setUseAsSharedReferenceDialogOpen(false)
          }}
          onSave={(sharedRef: string) => {
            saveShipmentReference(sharedRef)
          }}
        />
      )}
    </Dialog>
  )
}

export default ContainerEditWindow
