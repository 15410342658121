// @ts-nocheck
// FIXME

import { FunctionComponent, useEffect, useState, useCallback } from 'react'
import { useDispatch, useStore, useSelector } from 'react-redux'
import { LoadingButton } from '@mui/lab'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { useTranslation } from 'react-i18next'
import { orderBy, each, filter, find } from 'lodash'
import Dropzone from 'react-dropzone'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { Box, Button, Link } from '@mui/material'
import CustomInput, {
  NumberFormatCustom,
} from 'src/components/Common/Input/MuiInput'
import { makeStyles } from '@mui/styles'
import TabPanel from 'src/components/Common/TabPanel'
import { promisifyAction } from '../../../utils'
import {
  purchaseOrderCreate,
  updatePurchaseOrder,
  uploadPDFDocument,
  assignSellerOrganization,
} from '../../../stores/actionCreators'
import { Dialog, DialogContent } from '../../../stories/'
import DetailsBlock from './DetailsBlock'
import ProductsBlock from './ProductsBlock'
import ProductsItem from './ProductsItem'
import UploadFilesBlock from './UploadFilesBlock'
import { orderValidation } from './validations'
import './styles.scss'

interface IProps {
  open: boolean
  close: () => void
  fetchData: () => void
  purchaseOrder: any
  files: File[]
}

const useStyles = makeStyles((theme) => ({
  root: {
    '&.MuiDialogContent-root': {
      padding: '0px 24px',
    },
  },
  container: {
    '&.MuiTabs-root': {
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
  },
}))

const initialPurchaseOrderLine = (count: number) => {
  return {
    units_ordered: count,
    product_code: null,
    product_name: null,
    booking_lines: [
      {
        weight_kg: null,
        volume_cbm: null,
      },
    ],
  }
}

const initialPurchaseOrder = {
  purchase_order_number: '',
  seller_address_id: null,
  incoterm_code: null,
  loading_port_code: null,
  ex_factory_date: null,
  order_date: null,
  purchase_order_lines: [initialPurchaseOrderLine(0)],
  seller: {
    address: null,
    organization: null,
  },
}

const PurchaseModalWindow: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const { configuredPOUpload, addresses } = useSelector(
    (globalState: IGlobalState) => ({
      configuredPOUpload: globalState.user.configured_po_upload,
      addresses: globalState.addresses.list,
    })
  )

  const [loadingButton, setLoadingButton] = useState<boolean>(false)
  const [uploadButtonLoad, setUploadButtonLoad] = useState<boolean>(false)
  const [showUploadError, setShowUploadError] = useState<boolean>(false)
  const [purchaseOrder, setPurchaseOrder] = useState<any>(initialPurchaseOrder)
  const [productsState, setProductsState] = useState<string>('')
  const [showErrors, setShowErrors] = useState<boolean>(false)
  const [isAlreadyExist, setIsAlreadyExist] = useState<boolean>(false)
  const [isAlreadyExistShipper, setIsAlreadyExistShipper] = useState<boolean>(
    false
  )
  const [isNewShipper, setIsNewShipper] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [disableProductsState, setDisableProductsState] = useState<boolean>(
    false
  )
  const [assignCondition, setAssignCondition] = useState<boolean>(false)
  const [tabValue, setTabValue] = useState<string>(
    isEdit || !configuredPOUpload ? 'manual' : 'upload'
  )
  const [filesArr, setFilesArr] = useState<File[]>([])
  const [isDropzoneActive, setIsDropzoneActive] = useState<boolean>(false)
  const store = useStore()

  const createPurchaseOrderAsync = promisifyAction(
    dispatch,
    purchaseOrderCreate
  )
  const updatePurchaseOrderAsync = promisifyAction(
    dispatch,
    updatePurchaseOrder
  )

  const uploadPDFDocumentAsync = promisifyAction(dispatch, uploadPDFDocument)
  const assignSellerOrganizationAsync = promisifyAction(
    dispatch,
    assignSellerOrganization
  )

  useEffect(() => {
    if (props.purchaseOrder) {
      setPurchaseOrder(props.purchaseOrder)
      if (!!props.purchaseOrder.purchase_order_lines.length) {
        const {
          purchase_order_lines,
          total_volume_cbm,
          total_weight_kg,
        } = props.purchaseOrder

        switch (true) {
          case !!purchase_order_lines[0].units_ordered:
            setProductsState('add')
            setDisableProductsState(true)
            break
          case !!total_volume_cbm || !!total_weight_kg:
            setProductsState('total')
            break
          case !total_volume_cbm && !total_weight_kg:
            setProductsState('unknown')
            break
        }
      }
      setIsEdit(true)
    } else {
      setPurchaseOrder({ ...initialPurchaseOrder })
      setProductsState('')
      setIsEdit(false)
    }
  }, [props.purchaseOrder])

  useEffect(() => {
    if (productsState === 'add' && !props.purchaseOrder) {
      setPurchaseOrder({
        ...purchaseOrder,
        ...{ purchase_order_lines: [initialPurchaseOrderLine(1)] },
      })
    }
    if (productsState === 'total' && !props.purchaseOrder) {
      setPurchaseOrder({
        ...purchaseOrder,
        ...{ purchase_order_lines: [initialPurchaseOrderLine(0)] },
      })
    }
  }, [productsState])

  useEffect(() => {
    const invalidFiles: File[] = filter(
      filesArr,
      (file) => file.type !== 'application/pdf'
    )
    setShowUploadError(!!invalidFiles.length)
  }, [filesArr])

  useEffect(() => {
    setFilesArr(props.files || [])
    if (props.files && !!props.files.length) {
      setTabValue('upload')
    }
  }, [props.files])

  const onSaveAndClose = useCallback(async () => {
    setLoadingButton(true)
    if (orderValidation(purchaseOrder, productsState) && !loadingButton) {
      await onSave()
      if (assignCondition) {
        const currentPO = isEdit
          ? purchaseOrder
          : store.getState().purchaseOrders.singlePurchaseOrder
        const sellerAddress = find(addresses, {
          id: isEdit
            ? purchaseOrder.seller.address.id
            : purchaseOrder.seller_address_id,
        })
        if (!!sellerAddress && !!sellerAddress.connection) {
          await assignSellerOrganizationAsync(currentPO.id, {
            party_id: sellerAddress.connection.id || null,
          })
        }
      }
      setLoadingButton(false)
      setShowErrors(false)
      onClose()
      await props.fetchData()
    } else {
      setLoadingButton(false)
      setShowErrors(true)
    }
  }, [purchaseOrder, productsState, assignCondition])

  const onSave = async (): Promise<any> => {
    let purchaseOrderData = purchaseOrder

    try {
      if (isEdit) {
        await updatePurchaseOrderAsync(purchaseOrderData.id, purchaseOrderData)
      } else {
        await createPurchaseOrderAsync(purchaseOrderData)
      }
    } catch {
      dispatch(
        showNotification({
          message: isEdit
            ? t(
                'purchase_orders.errors.order_edit_error',
                'Order editing error.'
              )
            : t(
                'purchase_orders.errors.order_creating_error',
                'Order creating error.'
              ),
          severity: 'error',
        })
      )
    }
  }

  const onClose = (): void => {
    setPurchaseOrder({ ...initialPurchaseOrder })
    setShowErrors(false)
    setIsAlreadyExist(false)
    setDisableProductsState(false)
    setIsNewShipper(false)
    setLoadingButton(false)
    setIsEdit(false)
    setUploadButtonLoad(false)
    setProductsState('')
    setTabValue(isEdit || !configuredPOUpload ? 'manual' : 'upload')
    setShowUploadError(false)
    setFilesArr([])
    props.close()
  }

  const disabledCondition = (): boolean => {
    let sellerId: number = 0
    if (isEdit && purchaseOrder.seller && purchaseOrder.seller.address) {
      sellerId = purchaseOrder.seller.address.id
    }

    const shipperCondition: boolean = isNewShipper
      ? isAlreadyExistShipper
      : !purchaseOrder.seller_address_id && !sellerId

    return (
      loadingButton ||
      !productsState ||
      !purchaseOrder.purchase_order_number ||
      shipperCondition
    )
  }

  const changeProductsState = (productsState: string): void => {
    setProductsState(productsState)
  }

  const addNewProduct = (): void => {
    const updatedProductsArr = [
      ...purchaseOrder.purchase_order_lines,
      {
        units_ordered: 1,
        product_code: null,
        product_name: null,
        booking_lines: [
          {
            weight_kg: null,
            volume_cbm: null,
          },
        ],
      },
    ]

    setPurchaseOrder({
      ...purchaseOrder,
      ...{ purchase_order_lines: [...updatedProductsArr] },
    })
  }

  const removeProductItem = (productIndex: number): void => {
    const updatedProductsArr = [
      ...orderBy(purchaseOrder.purchase_order_lines).filter(
        (item, index: number) => index !== productIndex
      ),
    ]

    setPurchaseOrder({
      ...purchaseOrder,
      ...{ purchase_order_lines: [...updatedProductsArr] },
    })
  }

  const handleChangeVolume = (values): void => {
    const updatedProductsArr = purchaseOrder.purchase_order_lines.map(
      (product) => {
        if (product.booking_lines.length) {
          product.booking_lines[0].volume_cbm = values || 0
        } else {
          product.booking_lines[0] = { volume_cbm: values || 0 }
        }
        return product
      }
    )

    setPurchaseOrder({
      ...purchaseOrder,
      ...{ purchase_order_lines: [...updatedProductsArr] },
    })
  }

  const handleChangeWeight = (values): void => {
    const updatedProductsArr = purchaseOrder.purchase_order_lines.map(
      (product) => {
        if (product.booking_lines.length) {
          product.booking_lines[0].weight_kg = values || 0
        } else {
          product.booking_lines[0] = { weight_kg: values || 0 }
        }
        return product
      }
    )

    setPurchaseOrder({
      ...purchaseOrder,
      ...{ purchase_order_lines: [...updatedProductsArr] },
    })
  }

  const handleChangeOrder = (value: number | string, type: string): void => {
    setPurchaseOrder((prev) => ({
      ...prev,
      [type]: type === 'seller' ? { ...value } : value,
    }))
  }

  const onChangeProductItem = useCallback(
    (itemIndex: number, name: string, value: string | number): void => {
      const updatedProductsArr = orderBy(
        purchaseOrder.purchase_order_lines
      ).map((product, index: number) => {
        if (index === itemIndex) {
          if (name === 'volume_cbm' || name === 'weight_kg') {
            if (product.booking_lines.length) {
              product.booking_lines[0][name] = value
            } else {
              product.booking_lines[0] = { [name]: value }
            }
          } else {
            product[name] = value
          }
        }
        return product
      })

      setPurchaseOrder({
        ...purchaseOrder,
        ...{ purchase_order_lines: [...updatedProductsArr] },
      })
    },
    [purchaseOrder]
  )

  const getVolumeOrWeightValue = (fieldName: string): number => {
    if (purchaseOrder.purchase_order_lines.length) {
      const { booking_lines } = purchaseOrder.purchase_order_lines[0]
      return booking_lines.length ? booking_lines[0][fieldName] : 0
    }
    return 0
  }

  const handleAlreadyExist = (status: boolean): void => {
    setIsAlreadyExist(status)
  }

  const handleAlreadyExistShipper = (status: boolean): void => {
    setIsAlreadyExistShipper(status)
  }

  const handleAssignCondition = (status: boolean): void => {
    setAssignCondition(status)
  }

  const handleNewShipper = (status: boolean): void => {
    setIsNewShipper(status)
  }

  const onTabsChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTabValue(newValue)
  }

  const setFiles = (files: File[]): void => {
    setFilesArr([...files, ...filesArr])
  }

  const removeFile = (index: number): void => {
    const data: File[] = [...filesArr]
    data.splice(index, 1)
    setFilesArr(data)
  }

  const uploadPDFFiles = async (): Promise<any> => {
    setUploadButtonLoad(true)
    each(filesArr, (file: File) => {
      if (file) {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = async () => {
          await uploadPDFDocumentAsync({
            file,
          })
        }
      }
    })
    await props.fetchData()
    setUploadButtonLoad(false)
    onClose()
  }

  const uploadFilesButton = () => {
    return (
      <div className="purchase-orders-window--upload-files-action">
        <Button
          variant="contained"
          onClick={uploadPDFFiles}
          disabled={
            uploadButtonLoad ||
            (configuredPOUpload
              ? showUploadError
              : showUploadError || filesArr.length < 3)
          }
        >
          {t('purchase_orders.modal.add_orders_button', 'Add orders')}
        </Button>
      </div>
    )
  }

  const addOrEditBlock = (): React.ReactNode => {
    return (
      <>
        <div className="purchase-orders-window--block">
          <DetailsBlock
            order={purchaseOrder}
            handleChangeOrder={handleChangeOrder}
            isAlreadyExist={isAlreadyExist}
            isAlreadyExistShipper={isAlreadyExistShipper}
            handleAlreadyExist={handleAlreadyExist}
            handleAlreadyExistShipper={handleAlreadyExistShipper}
            newShipper={handleNewShipper}
            handleChangeAssignCondition={handleAssignCondition}
            isEdit={isEdit}
            existOrderNumber={
              props.purchaseOrder
                ? props.purchaseOrder.purchase_order_number
                : null
            }
          />
        </div>
        <div className="purchase-orders-window--block">
          <ProductsBlock
            productsState={productsState}
            changeProductsState={changeProductsState}
            disableProductsState={disableProductsState}
          />
        </div>
        {productsState === 'add' && (
          <>
            <div className="products-item--table-body">
              {orderBy(purchaseOrder.purchase_order_lines).map(
                (product, index) => {
                  return (
                    <ProductsItem
                      key={index}
                      product={product}
                      productIndex={index}
                      productsLength={purchaseOrder.purchase_order_lines.length}
                      changeProductItem={onChangeProductItem}
                      removeProduct={removeProductItem}
                      showErrors={showErrors}
                    />
                  )
                }
              )}
            </div>
            <div className="products-item--add-line">
              <Link component="button" variant="body1" onClick={addNewProduct}>
                <AddRoundedIcon />
                {t(
                  'purchase_orders.modal.products.add_product_button',
                  'Add more products'
                )}
              </Link>
            </div>
          </>
        )}
        {productsState === 'total' && (
          <div className="products-item--table-body short-body">
            <div className="products-item--table-row">
              <div className="block medium">
                <CustomInput
                  name="total_volume_cbm"
                  label="Volume"
                  className="input-field"
                  placeholder="0"
                  value={getVolumeOrWeightValue('volume_cbm') || ''}
                  onChange={(value) => {
                    handleChangeVolume(value)
                  }}
                  inputComponent={NumberFormatCustom as any}
                  endAdornment={
                    <div>
                      m<sup>3</sup>
                    </div>
                  }
                />
              </div>
              <div className="block medium">
                <CustomInput
                  name="weight"
                  label="Weight"
                  className="input-field"
                  placeholder="0.00"
                  value={getVolumeOrWeightValue('weight_kg') || ''}
                  onChange={handleChangeWeight}
                  inputComponent={NumberFormatCustom as any}
                  endAdornment={<div>kg</div>}
                />
              </div>
            </div>
          </div>
        )}
      </>
    )
  }

  const onDrop = (files: File[]): void => {
    if (files != null && files.length > 0) {
      setFilesArr([...files, ...filesArr])
    }
    setIsDropzoneActive(false)
  }

  const onDragEnter = (): void => {
    setIsDropzoneActive(true)
  }

  const onDragLeave = (): void => {
    setIsDropzoneActive(false)
  }

  const modalTitle = () => {
    if (isEdit) {
      return t('purchase_orders.modal.order_creation.edit_title', 'Edit order')
    }
    if (configuredPOUpload) {
      return t('purchase_orders.modal.order_creation.add_title', 'Add order')
    }
    return t(
      'purchase_orders.modal.order_creation.configure_title',
      'Configure order'
    )
  }

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      maxWidth="lg"
      title={modalTitle()}
      actions={
        (tabValue === 'manual' || isEdit) && (
          <LoadingButton
            variant="contained"
            onClick={onSaveAndClose}
            disabled={disabledCondition()}
            loading={loadingButton}
          >
            {t('purchase_orders.modal.products.save_product_button', 'Save')}
          </LoadingButton>
        )
      }
    >
      <Box
        style={{
          height: '100vh',
          overflowY: tabValue === 'manual' ? 'auto' : 'hidden',
          overflowX: 'hidden',
        }}
      >
        <Dropzone
          disabled={tabValue === 'manual' || isEdit}
          onDrop={onDrop}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          multiple={true}
        >
          {({ getRootProps }) => (
            <section>
              <div {...getRootProps()} className="relative">
                {isDropzoneActive && (
                  <div className="shipment-docs-page__documents-overlay">
                    <i className="icon folder" />
                    <div className="shipment-docs-page__documents-overlay--title">
                      {t(
                        'purchase_orders.dropzone.documents.title',
                        'Release your documents'
                      )}
                    </div>
                    <div className="shipment-docs-page__documents-overlay--text">
                      {t(
                        'purchase_orders.dropzone.documents.description',
                        'Add files by dropping them in this window'
                      )}
                    </div>
                  </div>
                )}
                <DialogContent
                  className={classes.root}
                  sx={{
                    width: (theme) => theme.breakpoints.values['md'],
                  }}
                >
                  {!isEdit && (
                    <>
                      <Tabs
                        className={classes.container}
                        value={tabValue}
                        onChange={onTabsChange}
                      >
                        <Tab
                          label={t(
                            'purchase_orders.modal.products.add_manually',
                            'Add manually'
                          )}
                          value="manual"
                        />
                        <Tab
                          label={
                            configuredPOUpload
                              ? t(
                                  'purchase_orders.modal.products.upload_order',
                                  'Upload order'
                                )
                              : t(
                                  'purchase_orders.modal.products.configure_uplaod',
                                  'Configure file upload'
                                )
                          }
                          value="upload"
                        />
                      </Tabs>
                      <TabPanel
                        value={tabValue}
                        index="upload"
                        className="shipping-bookings--tabpanel"
                      >
                        <UploadFilesBlock
                          files={filesArr}
                          setFiles={setFiles}
                          showError={showUploadError}
                          removeFile={removeFile}
                          configuredPOUpload={configuredPOUpload}
                        />
                      </TabPanel>
                      <TabPanel
                        value={tabValue}
                        index="manual"
                        className="shipping-bookings--tabpanel"
                      >
                        {addOrEditBlock()}
                      </TabPanel>
                    </>
                  )}
                  {isEdit && addOrEditBlock()}
                  {tabValue === 'upload' &&
                    !!filesArr.length &&
                    uploadFilesButton()}
                </DialogContent>
              </div>
            </section>
          )}
        </Dropzone>
      </Box>
    </Dialog>
  )
}

export default PurchaseModalWindow
