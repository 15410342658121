import { FunctionComponent } from 'react'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import { Button } from '@mui/material'
import urlJoin from 'url-join'
import { redirectToDashboard } from 'src/utils/navigation'
import { Trans } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundImage: `url(${urlJoin(
      process.env.BASE_URL || '',
      'assets/500-page-background.png'
    )})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.grey[300],
  },
  typography: {
    ...theme.typography.h3,
    color: 'white',
    fontWeight: 600,
  },
  text: {
    position: 'absolute',
    left: '5%',
    bottom: '15%',
  },
  button: {
    marginTop: '50px',
  },
}))

const UnhandledExceptionPage: FunctionComponent = () => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.text}>
        <Trans
          i18nKey="common.unhandled_exception_page.description"
          defaults="<typography>Oops, it looks like</typography><typography>something went wrong.</typography><button>Go to dashboard</button>"
          components={{
            typography: <div className={classes.typography} />,
            button: (
              <Button
                className={classes.button}
                color="secondary"
                variant="contained"
                onClick={redirectToDashboard}
              />
            ),
          }}
        />
      </Box>
    </Box>
  )
}

export default UnhandledExceptionPage
