import { Box, CircularProgress, Divider, Stack } from '@mui/material'
import { NoSavedMessages } from 'src/pages/EmptyStates'
import NotificationsSavedMessage from './NotificationsSavedMessage'

const NotificationsSavedMessagesView = ({
  comments,
  isLoading,
  onCommentRemove,
}) => {
  if (isLoading)
    return (
      <Box
        display="flex"
        width="100%"
        justifyContent="center"
        alignItems="center"
        height="70vh"
        data-testid="saved-messages-loader"
      >
        <CircularProgress />
      </Box>
    )

  return (
    <Box>
      {comments.length === 0 && (
        <Box height="70vh">
          <NoSavedMessages />
        </Box>
      )}
      {comments.length >= 1 && (
        <Stack divider={<Divider />} height="70vh" overflow="auto">
          {comments.map((comment: IComment) => (
            <NotificationsSavedMessage
              key={comment.id}
              comment={comment}
              onCommentRemove={onCommentRemove}
            />
          ))}
        </Stack>
      )}
    </Box>
  )
}

export default NotificationsSavedMessagesView
