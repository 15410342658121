import { Box, Divider, Link } from '@mui/material'
import { Stack } from '@mui/system'
import { sortBy } from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { chatsMarkAllRead } from 'src/stores/actionCreators'
import { NoUnreadNotifications } from 'src/pages/EmptyStates'
import NotificationsContentListItem from './NotificationsContentListItem'

const NotificationsContentList = ({ onCommentUpdate }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { list, currentUser } = useSelector(
    (state: IGlobalState) => ({
      currentUser: state.user,
      list: sortBy(state.navigationNotifications.list, 'created_at'),
    }),
    shallowEqual
  )

  const onNotificationUpdate = (comment: INavigationNotification) => {
    onCommentUpdate(comment)
  }

  const markAllAsRead = () => {
    dispatch(chatsMarkAllRead())
  }

  if (list.length > 0) {
    return (
      <>
        <Stack divider={<Divider />} height="70vh" overflow="auto">
          {list.map((comment) => (
            <NotificationsContentListItem
              key={comment.id}
              comment={comment}
              isSaved={
                currentUser?.id
                  ? comment.saved_for_user_ids.includes(currentUser?.id)
                  : false
              }
              onCommentUpdate={onNotificationUpdate}
            />
          ))}
        </Stack>
        <Divider />
        <Link
          variant="body1"
          component="button"
          onClick={markAllAsRead}
          sx={{
            p: 2,
            width: '100%',
          }}
        >
          {t(
            'top_navigation.notifications.mark_as_read_link',
            'Mark all as read'
          )}
        </Link>
      </>
    )
  }

  return (
    <Box height="70vh">
      <NoUnreadNotifications />
    </Box>
  )
}

export default NotificationsContentList
