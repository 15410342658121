import Stack from '@mui/material/Stack'
import { useCallback } from 'react'
import { Button } from '@mui/material'
import Edit from '@mui/icons-material/Edit'
import { useTranslation } from 'react-i18next'
import { getTransactionItemGroups } from './utils'
import { ShipmentCostsItemsTable } from './ShipmentCostsItemsTable'
import { ShipmentCostsParty } from './ShipmentCostsParty'
import { ShipmentCostsTransactionItemsProps } from './props'

export const ShipmentCostsTransactionItemsLcl: React.FC<ShipmentCostsTransactionItemsProps> = ({
  canManageCosts,
  transactionItems,
  loading,
  shipmentCollaborators = [],
  onAddOrEditCostsClick = () => {},
}) => {
  const { t } = useTranslation()
  const transactionItemGroups = getTransactionItemGroups(transactionItems)

  const onEditCostsClick = useCallback(
    (buyer, items) => {
      const collaboratorBuyer = shipmentCollaborators.find(
        (collaborator) => collaborator.organization_id === buyer.id
      )
      if (collaboratorBuyer) {
        onAddOrEditCostsClick(collaboratorBuyer, items)
      }
    },
    [onAddOrEditCostsClick, shipmentCollaborators]
  )

  return (
    <Stack data-testid="shipment-costs-lcl">
      {transactionItemGroups.map((group) => (
        <Stack
          spacing={2}
          sx={{ mb: 2 }}
          flexDirection="column"
          key={`${group.parties[0].id}_${group.parties[1].id}`}
        >
          <Stack spacing={2} alignItems="start">
            <ShipmentCostsParty
              marginTop={1}
              name={group.parties[0].name}
              label={group.parties[0].label}
              className={group.parties[0].role_code}
            />
            {canManageCosts && (
              <Button
                variant="outlined"
                endIcon={<Edit />}
                onClick={() => onEditCostsClick(group.parties[1], group.rows)}
                children={t('common.buttons.edit_costs', 'Edit costs')}
              />
            )}
          </Stack>
          <ShipmentCostsItemsTable
            loading={loading}
            rows={group.rows}
            totals={group.totals}
          />
        </Stack>
      ))}
    </Stack>
  )
}
