import {
  FunctionComponent,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Box, Paper, Typography, InputLabel, Grid, Stack } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { findKey, isNull, omit } from 'lodash'
import { history } from 'src/shyppleStore'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { useCreateShipment } from 'src/services/Api/shipments'
import { promisifyAction } from 'src/utils'

import { FormProvider } from 'src/components/Templates/Form/FormContext'
import ShipmentRole from 'src/components/Templates/Form/components/Parts/ShipmentRole'
import BookingParties from 'src/components/Templates/Form/components/Parts/BookingParties/BookingParties'
import TrackingInformation from 'src/components/ShipmentVisibilityModal/TrackingInformation'
import Incoterm from 'src/components/Templates/Form/components/Parts/Incoterm'
import CifValue from 'src/components/Templates/Form/components/Parts/CifValue'
import Address from 'src/components/Templates/Form/components/Parts/Address'
import ServicesContextWrapper from 'src/components/Templates/Form/components/Parts/ServicesContextWrapper'
import { ReferenceTextArea } from 'src/components/ShipmentReferencesModal/'
import { AutoCompleteSelect } from 'src/stories/MUI/Select/AutoCompleteSelect'
import { getLinkedCustomers } from 'src/stores/actionCreators'

import { getShipmentRoles } from 'src/components/Templates/Form/Form.utils'

import { useGetOrganizationShipmentRoles } from 'src/services/Api/common'
import {
  initialState,
  bookingPartyKeys,
  initialFormErrors,
  defaultBookingParties,
} from './Form.constants'
import { RequestHandlingFormContextProps } from './Form.props'
import { getFormPayload } from './Payload.utils'

const RequestLocalHandling: FunctionComponent = () => {
  const dispatch = useDispatch()
  const [formState, setFormState] = useState({ ...initialState })
  const [formErrors, setFormErrors] = useState({ ...initialFormErrors })
  const {
    data: organizationShipmentRoles,
    isFetching: isFetchingOrganizationShipmentRoles,
  } = useGetOrganizationShipmentRoles({
    refetchOnMount: false,
  })

  const getCustomers = promisifyAction(dispatch, getLinkedCustomers)
  const shipmentRoles = getShipmentRoles(organizationShipmentRoles)

  const onChange = (newPartiallyUpdatedForm) => {
    setFormState((prevState) => ({
      ...prevState,
      ...newPartiallyUpdatedForm,
    }))
  }

  const onFormErrorChange = (newPartiallyUpdatedForm) => {
    setFormErrors((prevState) => ({
      ...prevState,
      ...newPartiallyUpdatedForm,
    }))
  }
  const { t } = useTranslation()
  const onChangeSharedReference = useCallback(
    (event) => {
      const value = event.target.value
      onChange({ references: { sharedReference: value } })
      if (value.length > 500) {
        onFormErrorChange({
          sharedReference: t(
            'create.request_local_handling.reference_feedback',
            'Your reference cannot have more than 500 characters'
          ),
        })
      }
    },
    [onChange, onFormErrorChange]
  )

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { mutateAsync: createServicesOnlyShipment } = useCreateShipment()

  const { user } = useSelector(
    (state: IGlobalState) => ({
      user: state.user,
    }),
    shallowEqual
  )

  const noTrackingKey: boolean = useMemo(
    () =>
      !formState.container_number &&
      !formState.booking_number &&
      !formState.bl_number,
    [formState]
  )

  const hasErrors: boolean = useMemo(
    () =>
      !!formErrors.sharedReference ||
      formErrors.bookingParties ||
      !!Object.values(
        omit(formErrors, ['bookingParties', 'sharedReference'])
      ).flat().length,
    [formErrors]
  )

  const isCustomer = user.organizationRole === 'customer'

  const isDisabled: boolean = useMemo(() => {
    return (
      (!isCustomer && isNull(formState.customer)) ||
      isNull(formState.carrier) ||
      !formState.shipmentRole ||
      noTrackingKey ||
      hasErrors ||
      !formState.services ||
      !Object.values(formState.services).some((value) => value) ||
      isLoading
    )
  }, [formState, isLoading, formErrors])

  const createShipment = async () => {
    setIsLoading(true)
    const payload = getFormPayload({
      formState,
      shipmentRoles,
      user,
    })

    try {
      const newShipment = await createServicesOnlyShipment(payload)

      dispatch(
        showNotification({
          message: t(
            'create.request_local_handling.shipment_added',
            'A new shipment has been added'
          ),
          severity: 'success',
          showClose: true,
        })
      )

      history.push(`shipments/${newShipment?.id}`)
    } catch (error) {
      setIsLoading(false)

      dispatch(
        showNotification({
          message: t(
            'create.request_local_handling.shipment_not_added',
            'The shipment has not been added'
          ),
          severity: 'error',
          showClose: true,
        })
      )
    }
  }

  const formProviderValue: RequestHandlingFormContextProps = {
    formState,
    onChange,
    onFormErrorChange,
    isLoading,
    formErrors,
    shipmentRoles,
  }

  const getBookingParties = (shipmentRole: string | number) => {
    const userPreferredShipmentRoleCode =
      findKey(shipmentRoles, (v) => {
        return v === shipmentRole
      }) ?? ''

    const newBookingParties = { ...defaultBookingParties }

    const currentUserOrganization = user
      ? {
          label: user.organizationName,
          value: user.organizationId,
        }
      : null

    const bookingPartyKey = bookingPartyKeys.find(
      (code) => code === userPreferredShipmentRoleCode
    )

    if (bookingPartyKey) {
      newBookingParties[bookingPartyKey] = {
        collaborator: currentUserOrganization,
        address: null,
      }
    }
    return newBookingParties
  }

  useEffect(() => {
    if (isFetchingOrganizationShipmentRoles) {
      return
    }
    const shipmentRole = user?.preferredShipmentRoleId ?? ''
    const bookingParties = getBookingParties(shipmentRole)
    onChange({
      shipmentRole,
      bookingParties,
    })
  }, [isFetchingOrganizationShipmentRoles])

  return (
    <FormProvider value={formProviderValue}>
      <Box
        className="request-local-handling"
        data-testid="request-local-handling-page"
      >
        <Typography
          variant="h2"
          children={t(
            'create.request_local_handling.header',
            'Request local handling'
          )}
        />
        <Paper elevation={0} sx={{ p: 3, pr: 4, pl: 4, mt: 2, width: 1000 }}>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6}>
              <ReferenceTextArea
                isLoading={false}
                name="shared_reference"
                value={formState.references.sharedReference as string}
                onChange={onChangeSharedReference}
                placeholder={t(
                  'common.forms.fields.shipment_shared_reference.placeholder',
                  'e.g. INV12345 (up to 500 characters)'
                )}
                label={t(
                  'common.forms.fields.shipment_shared_reference.label',
                  'Shared reference'
                )}
                error={formErrors['sharedReference']}
                collaborators={[]}
              />
            </Grid>
            <Grid item xs={6}>
              <ShipmentRole
                fieldPath="shipmentRole"
                label={t('templates.shipment_role', 'Shipment role')}
                editable={true}
                required
              />
            </Grid>
            {!isCustomer && (
              <Grid item xs={3}>
                <InputLabel required={true}>
                  {t('common.customer', 'Customer')}
                </InputLabel>
                <AutoCompleteSelect
                  id="customer"
                  data-testid="customer-select"
                  getData={(search) =>
                    getCustomers({ search, role: 'customer' })
                  }
                  onChange={(newValue) => {
                    onChange({ customer: newValue })
                  }}
                  value={formState.customer}
                  placeholder={t('common.search_placeholder', 'Search')}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <BookingParties columnSize={1} editable={true} />
            </Grid>
            <Grid item xs={3}>
              <Incoterm fieldPath="incoterm" label="Incoterm" />
            </Grid>
            <Grid item xs>
              <TrackingInformation />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ mb: 2 }}>
                <InputLabel required={true}>
                  {t(
                    'templates.shipment_details.shipment_services',
                    'Shipment services'
                  )}
                </InputLabel>
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs>
                  <ServicesContextWrapper
                    disabledFreight={true}
                    testId="request-transport-form-services"
                    fieldPath="services"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CifValue fieldPath="cifValue" label="CIF value of goods" />
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <Address
                  fieldPath="pickupAddress"
                  label={t(
                    'templates.shipment_details.pick_up_address',
                    'Pick-up address'
                  )}
                  service="pickup"
                />
                <Address
                  fieldPath="deliveryAddress"
                  label={t(
                    'templates.shipment_details.delivery_address',
                    'Delivery address'
                  )}
                  service="delivery"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'right' }}>
              <LoadingButton
                data-testid="save-shipment-button"
                variant="contained"
                onClick={createShipment}
                disabled={isDisabled}
                loading={isLoading}
                size="large"
              >
                {t('create.request_local_handling.button', 'Create request')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </FormProvider>
  )
}

export default RequestLocalHandling
