import { flatten } from 'lodash'
import { useCallback, useState } from 'react'
import { Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Stack from '@mui/material/Stack'
import Edit from '@mui/icons-material/Edit'
import { getTransactionItemGroups } from './utils'
import { ShipmentCostsItemsTable } from './ShipmentCostsItemsTable'
import { ShipmentCostsTransactionItemsProps } from './props'
import ShipmentCostsTransactionItemsFclGroupsSelect from './ShipmentCostsTransactionItemsFclGroupsSelect'
import ShipmentCostsTransactionItemsFclRowHeader from './ShipmentCostsTransactionItemsFclRowHeader'
import { Accordion, AccordionDetails, AccordionSummary } from './styles'

export const ShipmentCostsTransactionItemsFcl: React.FC<ShipmentCostsTransactionItemsProps> = ({
  loading,
  canManageCosts,
  transactionItems,
  shipmentCollaborators = [],
  defaultGrouping = 'service',
  invoicePerContainer = false,
  onAddOrEditCostsClick = () => {},
}) => {
  const { t } = useTranslation()
  const [grouping, setGrouping] = useState(defaultGrouping)
  const [expanded, setExpanded] = useState<string | false>('panel1')

  const transactionItemGroupsServices = getTransactionItemGroups(
    transactionItems
  )

  const transactionItemGroupsContainers = getTransactionItemGroups(
    transactionItems,
    'container'
  )

  const transactionItemGroups =
    grouping === 'container'
      ? transactionItemGroupsContainers
      : transactionItemGroupsServices

  const handleChange = (panel: string) => (
    _event: React.SyntheticEvent,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false)
  }

  const onEditCostsClick = useCallback(
    (buyer, items) => {
      const collaboratorBuyer = shipmentCollaborators.find(
        (collaborator) => collaborator.organization_id === buyer.id
      )
      if (collaboratorBuyer) {
        onAddOrEditCostsClick(collaboratorBuyer, items)
      }
    },
    [onAddOrEditCostsClick, shipmentCollaborators]
  )

  return (
    <Stack spacing={2} data-testid="shipment-costs-fcl">
      {transactionItemGroups.map((group, index) => {
        const panel = `panel${index + 1}`
        const allRows = flatten(group.rows.map((row) => row.innerRows))
        const showContainer = allRows.some((row) => row.container !== null)

        return (
          <Accordion
            expanded={expanded === panel}
            onChange={handleChange(panel)}
            key={`${group.parties[0].id}_${group.parties[1].id}`}
          >
            <AccordionSummary
              aria-controls={`${panel}-content`}
              id={`${panel}-header`}
            >
              <ShipmentCostsTransactionItemsFclRowHeader
                group={group}
                canManageCosts={canManageCosts}
              />
            </AccordionSummary>
            <AccordionDetails>
              <Stack
                direction="column"
                justifyContent="space-between"
                p={2}
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.palette.grey[50]}`,
                })}
              >
                {canManageCosts && (
                  <Box>
                    <Button
                      variant="outlined"
                      endIcon={<Edit />}
                      onClick={() =>
                        onEditCostsClick(group.parties[1], group.rows)
                      }
                      children={t('common.buttons.edit_costs', 'Edit costs')}
                    />
                  </Box>
                )}
                {invoicePerContainer && showContainer && (
                  <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                    <ShipmentCostsTransactionItemsFclGroupsSelect
                      onClick={setGrouping}
                      defaultGrouping={defaultGrouping}
                    />
                  </Box>
                )}
              </Stack>
              <ShipmentCostsItemsTable
                loading={loading}
                rows={group.rows}
                totals={group.totals}
                showContainer={showContainer}
                grouping={grouping}
              />
            </AccordionDetails>
          </Accordion>
        )
      })}
    </Stack>
  )
}
