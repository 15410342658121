import { TableCell } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ShipmentDocumentRowDate = ({ document }) => {
  const { t } = useTranslation()

  return (
    <TableCell>
      {t('common.date_medium', {
        date: document.created_at,
        defaultValue: '{{date, DATE_MED}}',
      })}
    </TableCell>
  )
}

export default ShipmentDocumentRowDate
