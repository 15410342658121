import { FunctionComponent, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { compact } from 'lodash'
import { ToggleButtonProps } from '@mui/lab'
import { Box, SvgIcon, Typography } from '@mui/material'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import { ButtonGroup } from 'src/stories/ButtonGroup'
import EmailsFormContainer from './SendViaEmail'

interface IProps {
  openWindow: (type: string) => void
}

const PickupsAndDeliveriesTableActions: FunctionComponent<IProps> = ({
  openWindow,
}) => {
  const { t } = useTranslation()
  const [showSendEmailsForm, setShowSendEmailsForm] = useState(false)
  const { selectedRowsIds, scope } = useSelector((state: IGlobalState) => ({
    selectedRowsIds: state.pickUpsAndDeliveries.selectedInlandTransportRowsIds,
    scope: state.pickUpsAndDeliveries.filters.scope,
  }))

  const hideSendEmailForm = () => setShowSendEmailsForm(true)

  const buttons = [
    {
      value: 'counter',
      children: (
        <>
          <Typography
            variant="body1"
            children={t(
              'transports.table_actions.items_selected',
              '{{total}} selected',
              { total: selectedRowsIds.length }
            )}
            data-testid="selected-rows-counter"
          />
        </>
      ),
    },
    {
      value: 'action',
      children: (
        <>
          <SvgIcon>
            <LocalShippingOutlinedIcon color="primary" />
          </SvgIcon>
          <Typography
            variant="body1"
            children={
              scope === 'pickup'
                ? t('transports.table_actions.plan_pickup', 'Plan pickup')
                : t('transports.table_actions.plan_delivery', 'Plan delivery')
            }
          />
        </>
      ),
      onClick: () => openWindow(scope),
      'data-testid': 'open-window-button',
    },
    {
      value: 'action',
      children: (
        <>
          <SvgIcon>
            <MailOutlineIcon color="primary" />
          </SvgIcon>
          <Typography
            variant="body1"
            children={
              scope === 'pickup'
                ? t(
                    'transports.containers_sharing.pickup.dalog.title',
                    'Send pickup email'
                  )
                : t(
                    'transports.containers_sharing.delivery.dalog.title',
                    'Send delivery email'
                  )
            }
          />
        </>
      ),
      onClick: hideSendEmailForm,
      'data-testid': 'tab-send-via-email',
    },
  ]

  return (
    <Box
      m={0.5}
      sx={{
        position: 'absolute',
        backgroundColor: 'grey.50',
        zIndex: '1',
        borderRadius: 1,
      }}
    >
      <ButtonGroup buttons={compact(buttons) as ToggleButtonProps[]} />
      {showSendEmailsForm && (
        <EmailsFormContainer
          selectedRowsIds={selectedRowsIds}
          onClose={() => setShowSendEmailsForm(false)}
          scope={scope}
        />
      )}
    </Box>
  )
}

export default PickupsAndDeliveriesTableActions
