import { useState, useCallback, useMemo } from 'react'
import { uniqBy, debounce } from 'lodash'
import {
  useGetRecentSearches,
  useCreateRecentSearch,
} from 'src/services/Api/recentSearches'
import { FilterOptionValue } from 'src/components/Common/AutocompleteWrappers/types'
import { RecentSearches } from 'src/@types/endpoints/common'
import { queryClient } from 'src/services/http-common'
import { queryKeys } from 'src/services/endpoints'
import { useTranslation } from 'react-i18next'

const BaseAutocompleteWrapper = ({
  name,
  names,
  params,
  formatter,
  defaultValue,
  fetchFunction,
  recentSearchesFormatter,
  WrappedAutocompleteComponent,
}) => {
  const { t } = useTranslation()

  const { fetchAsync, isFetched, isFetching } = fetchFunction()

  const { data: recentSearches } = useGetRecentSearches(
    {
      names,
    },
    {
      refetchOnMount: true,
      retry: 0,
    }
  )

  const recentSearchOptions = useMemo(() => {
    return recentSearchesFormatter(
      recentSearches,
      name,
      t('components.autocomplete.recent_options', 'Recent searches')
    )
  }, [recentSearches])

  const { mutateAsync: createRecentSearch } = useCreateRecentSearch()

  const [defaultOptions, setDefaultOptions] = useState<FilterOptionValue[]>(
    defaultValue
  )

  const [inputValue, setInputValue] = useState('')

  const options: FilterOptionValue[] = uniqBy(
    [...recentSearchOptions, ...defaultOptions],
    'value'
  )

  const handleDebounceFn = async (input: string) => {
    setInputValue(input)
    const hasOption = defaultOptions.find((option) => option.label === input)
    if (input.length === 0 || !!hasOption) {
      return
    }

    const data = await fetchAsync({
      input,
      ...params,
    })

    const newlyFetchedOptions: FilterOptionValue[] = formatter(data)

    setDefaultOptions(uniqBy([...options, ...newlyFetchedOptions], 'value'))
  }

  const handleInputChangeWithDebounce = useCallback(
    debounce(handleDebounceFn, 800),
    [defaultValue]
  )

  const handleOnInputChange = (search: string) => {
    handleInputChangeWithDebounce(search)
  }

  const handleSelectChange = (selected: FilterOptionValue[]) => {
    const values = selected
      ? selected.reduce((acc: number[], item: FilterOptionValue): number[] => {
          const existingRecentSearchIds = recentSearchOptions.map(
            (recentSearch: FilterOptionValue) => recentSearch.value
          )
          if (!existingRecentSearchIds.includes(item.value)) {
            acc.push(Number(item.value))
          }
          return acc
        }, [])
      : []

    if (values.length === 0) return
    createRecentSearch({
      name,
      values: values.splice(0, 5),
    }).then((response) => {
      queryClient.setQueriesData(
        [queryKeys.commonRecentSearches],
        (oldParams: any) => {
          if (!oldParams) return oldParams

          const newRecentSearches = oldParams.data.map(
            (item: RecentSearches) => {
              if (item.name !== name) return item

              return response.data
            }
          )
          return { data: newRecentSearches }
        }
      )
    })
  }

  const noOptionsText =
    isFetched && inputValue.length > 0 ? 'No options' : 'Start typing...'

  return (
    <WrappedAutocompleteComponent
      options={options}
      noOptionsText={noOptionsText}
      onInputChange={handleOnInputChange}
      onChange={handleSelectChange}
      loading={isFetching}
      inputValue={inputValue}
    />
  )
}

export default BaseAutocompleteWrapper
