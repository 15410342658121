import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getSavedMessages } from 'src/stores/actionCreators'
import promisifyAction from 'src/utils/promisifyAction'
import NotificationsSavedMessagesView from './NotificationsSavedMessagesView'

const NotificationsSavedMessagesContainer = ({ onCommentUpdate }) => {
  const [busy, setBusy] = useState<boolean>(true)
  const [comments, setComments] = useState<IComment[]>([])
  const dispatch = useDispatch()

  const getSavedMessagesAsync = promisifyAction(dispatch, getSavedMessages)

  useEffect(() => {
    const fetchDataAsync = async () => {
      const response = await getSavedMessagesAsync()
      setBusy(false)
      setComments(response.comments)
    }
    fetchDataAsync()
  }, [])

  const onCommentRemove = async (comment: IComment) => {
    setComments((prevState) => prevState.filter((c) => c.id !== comment.id))
    onCommentUpdate(comment, true)
  }

  return (
    <NotificationsSavedMessagesView
      comments={comments}
      isLoading={busy}
      onCommentRemove={onCommentRemove}
    />
  )
}

export default NotificationsSavedMessagesContainer
