import { useGetShipmentAddresses } from 'src/services/Api/shipments'
import { searchParams } from '../../constants'
import AutocompleteAsyncBase from './AutocompleteAsyncBase'

const BookingPartiesAutocompleteAsync = ({ token, onDelete }) => {
  const addressType =
    token.value === searchParams.consignee ? 'consignee' : 'shipper'

  const getOptions = (data) =>
    (data ?? []).map((organization) => ({
      label: organization,
      value: organization,
    }))

  return (
    <AutocompleteAsyncBase
      token={token}
      onDelete={onDelete}
      params={{
        address_type: addressType,
        per_page: 200,
        page: 1,
      }}
      fetchFunction={useGetShipmentAddresses}
      formatter={getOptions}
    />
  )
}

export default BookingPartiesAutocompleteAsync
