import { useCallback } from 'react'
import { uniq } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Button, Paper, Stack } from '@mui/material'
import { ShipmentCostsParty } from './ShipmentCostsParty'

type Transaction = {
  value: string
  canAddCosts: boolean
  seller: IShipmentParty
  buyer: IShipmentParty
}

const ShipmentCostsCollaborators = ({
  transactions,
  shipmentCollaborators,
  userOrganizationId,
  transactionItems,
  onAddOrEditCostsClick,
}: {
  transactions: ITransaction[]
  shipmentCollaborators: IShipmentParty[]
  userOrganizationId: string | number
  transactionItems: ITransactionItem[]
  onAddOrEditCostsClick: (
    buyer: IShipmentParty,
    items: ITransactionItem[]
  ) => void
}) => {
  const { t } = useTranslation()
  const buyersFromTransactionItems = uniq(
    transactionItems.map(
      (transactionItem) => transactionItem.buyer_organization.id
    )
  )
  const userOrganization = shipmentCollaborators.find(
    (collaborator) => collaborator.organization_id === userOrganizationId
  )

  const onClick = useCallback(
    (buyer: IShipmentParty) => {
      onAddOrEditCostsClick(buyer, [])
    },
    [onAddOrEditCostsClick, transactionItems, userOrganizationId]
  )

  if (!userOrganization) {
    return null
  }

  const shipmentCollaboratorsThatAreNoBuyersOrCurrentOrganization: Transaction[] = shipmentCollaborators.reduce(
    (filtered: Transaction[], collaborator, index) => {
      if (
        !buyersFromTransactionItems.includes(collaborator.organization_id) &&
        collaborator.organization_id !== userOrganizationId
      ) {
        filtered.push({
          value: `item-${index + 1}`,
          seller: userOrganization,
          buyer: collaborator,
          canAddCosts: transactions.some(
            (transaction) =>
              transaction.seller_organization_id === userOrganizationId &&
              transaction.buyer_organization_id === collaborator.organization_id
          ),
        })
      }
      return filtered
    },
    []
  )

  return (
    <Stack spacing={2} data-testid="shipment-costs-collaborators">
      {shipmentCollaboratorsThatAreNoBuyersOrCurrentOrganization.map(
        (collaborator) => {
          return (
            <Paper key={collaborator.value} variant="outlined" sx={{ p: 2 }}>
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" spacing={2}>
                  <ShipmentCostsParty
                    logo={collaborator.seller.logo}
                    label={t('shipment_costs.offered_by', 'Seller')}
                    name={collaborator.seller.name}
                    className={collaborator.seller.organization_role_code}
                  />
                  <ShipmentCostsParty
                    label={t('shipment_costs.offered_to', 'Buyer')}
                    logo={collaborator.buyer.logo}
                    name={collaborator.buyer.name}
                    className={collaborator.buyer.organization_role_code}
                  />
                </Stack>
                {collaborator.canAddCosts && (
                  <Button
                    onClick={() => onClick(collaborator.buyer)}
                    variant="text"
                    children={t('common.buttons.add_costs', 'Add costs')}
                  />
                )}
              </Stack>
            </Paper>
          )
        }
      )}
    </Stack>
  )
}

export default ShipmentCostsCollaborators
