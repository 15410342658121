import { SyntheticEvent, useCallback } from 'react'
import debounce from 'lodash/debounce'
import { useTranslation } from 'react-i18next'
import InputLabel from '@mui/material/InputLabel'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import { MenuItem } from 'src/stories/Lab/Menu/components/MenuItem'
import {
  getAutocompleteOptionLabel,
  getAutocompleteFilteredOptions,
} from '../../BookingParties.utils'

const AutocompleteBase = ({
  name,
  onInputChange,
  onChange,
  freeSolo,
  testId,
  options,
  label,
  required,
  ...otherProps
}) => {
  const { t } = useTranslation()
  const { control } = useFormContext<FieldValues>()

  const handleDebounceFn = async (search: string) => {
    if (options.some((option) => option.name === search)) {
      return
    }
    onInputChange(search)
  }

  const freeSoloProp = freeSolo ? { freeSolo: true } : undefined

  const handleInputChangeWithDebounce = useCallback(
    debounce(handleDebounceFn, 800),
    [options]
  )

  const handleInputChange = (
    _event: SyntheticEvent<Element, Event>,
    search: string
  ) => {
    handleInputChangeWithDebounce(search)
  }

  return (
    <>
      <InputLabel required={required}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { value } }) => (
          <Autocomplete
            {...freeSoloProp}
            options={options}
            value={value}
            getOptionLabel={getAutocompleteOptionLabel}
            filterOptions={(options, params) =>
              getAutocompleteFilteredOptions(options, params, freeSolo)
            }
            clearOnBlur
            selectOnFocus
            handleHomeEndKeys
            autoHighlight
            onChange={(_event, newValue) => onChange(newValue, value)}
            onInputChange={handleInputChange}
            renderOption={(props, option) => {
              const { id: key, ...optionProps } = props
              return <MenuItem key={key} text={option.name} {...optionProps} />
            }}
            data-testid={testId}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  'data-testid': 'autocomplete-input',
                }}
                placeholder={t('common.start_typing', 'Start typing...')}
              />
            )}
            {...otherProps}
          />
        )}
      />
    </>
  )
}

export default AutocompleteBase
