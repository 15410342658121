import { orderBy } from 'lodash'
import { ListChildComponentProps } from 'react-window'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import SearchIcon from '@mui/icons-material/Search'
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete'
import Typography from '@mui/material/Typography'
import InputBase, { InputBaseComponentProps } from '@mui/material/InputBase'
import { AutocompleteOptionProps } from './Autocomplete.props'
import { StyledInput, StyledListSubheader } from './AutocompleteCommon.styles'

export const listBoxPadding = 8

export const getSortedOptions = (
  options: AutocompleteOptionProps[],
  value: AutocompleteOptionProps[]
) => {
  const ids = value.map((val) => val.id)
  return orderBy(
    options,
    (item) => {
      return `${item.group}-${ids.indexOf(item.id)}`
    },
    ['desc']
  )
}

export const getRenderedInput = (params: {
  InputProps: { ref: React.Ref<unknown> | undefined }
  inputProps: InputBaseComponentProps | undefined
}) => {
  return (
    <StyledInput
      ref={params.InputProps.ref}
      inputProps={{
        ...params.inputProps,
        'data-testid': 'virtualized-autocomplete-search-input',
      }}
      autoFocus
      className="search"
      startAdornment={<SearchIcon sx={{ color: 'grey.600' }} />}
    />
  )
}

export const renderRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props
  const dataSet = data[index]
  const inlineStyle = {
    ...style,
    top: (style.top as number) + listBoxPadding,
  }

  if (dataSet.hasOwnProperty('group')) {
    return (
      <StyledListSubheader
        data-testid="virtualized-autocomplete-group-header"
        key={dataSet.id}
        style={inlineStyle}
      >
        <Typography variant="body1Strong">{dataSet.group}</Typography>
      </StyledListSubheader>
    )
  }

  return (
    <Box
      {...dataSet[0]}
      style={inlineStyle}
      data-testid="virtualized-autocomplete-option"
    >
      <Checkbox
        disableRipple={true}
        sx={{ p: 0, mr: 1, ' svg': { color: 'primary.main' } }}
        checked={dataSet[0]['aria-selected']}
      />
      <Typography component="div" noWrap>
        {dataSet[1].label}
      </Typography>
    </Box>
  )
}

export const getRenderInput = (params: AutocompleteRenderInputParams) => (
  <InputBase
    ref={params.InputProps.ref}
    inputProps={params.inputProps}
    autoFocus
    fullWidth
    className="search"
    data-testid="search-input"
    sx={{
      borderBottom: '1px solid',
      borderColor: 'grey.200',
      px: 2,
      py: 1,
    }}
  />
)
