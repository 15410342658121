import { Link } from 'react-router-dom'
import { Link as MuiLink, TableCell } from '@mui/material'

const ShipmentDocumentRowBooking = ({ document, shipmentId }) => {
  return (
    <TableCell data-testid="shipment-documents-list-item-booking">
      {!document.booking && '-'}
      {document.booking && (
        <MuiLink
          component={Link}
          variant="body1"
          to={`/shipments/${shipmentId}/bookings/#${document.booking.id}`}
        >
          {document.booking?.shipper_name ??
            document.booking?.booking_index ??
            '-'}
        </MuiLink>
      )}
    </TableCell>
  )
}

export default ShipmentDocumentRowBooking
