import { pick } from 'lodash'
import { FunctionComponent, useEffect, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Filters } from 'src/components/Filters'
import { useGetShipmentFilters } from 'src/services/Api/shipments'
import { queryKeys } from 'src/services/endpoints'
import { useTranslation } from 'react-i18next'
import { queryCachingOptions } from 'src/services/http-common'
import {
  shipmentsSetFilterInitial,
  shipmentsUpdateFilters,
} from 'src/stores/actionCreators'
import { parse } from 'query-string'
import { OMIT_FILTERS, yesNoOptions } from './constants'

const ShipmentsFilters: FunctionComponent<{ showAllFilters: boolean }> = ({
  showAllFilters,
}) => {
  const { t } = useTranslation()
  const url = useRef(window.location.href).current

  const dispatch = useDispatch()
  const { data: filterOptions, isFetching } = useGetShipmentFilters(
    queryCachingOptions.long
  )
  const isInitialLoad = useRef(true)
  const { filters } = useSelector(
    (state: IGlobalState) => ({ filters: state.shipments.filters }),
    shallowEqual
  )

  const updateFilters = (newFilters) => {
    const { current: isInitial } = isInitialLoad
    const defaultFiltersValues = isInitial ? pick(parse(url), OMIT_FILTERS) : {}
    isInitialLoad.current = false

    dispatch(shipmentsUpdateFilters({ ...newFilters, ...defaultFiltersValues }))
  }

  useEffect(() => {
    return () => {
      dispatch(shipmentsSetFilterInitial())
    }
  }, [])

  return (
    <Filters
      miniFilterLabels={{
        missing_information: 'Open & Overdue tasks',
        pols: 'POL',
        users: 'Watched by',
        departureDateStart: 'ETD Start',
        departureDateEnd: 'ETD End',
        arrivalDateStart: 'ETA Start',
        arrivalDateEnd: 'ETA End',
        visibility_only: 'OneView shipments',
        track_trace_statuses: 'Track and Trace statuses',
      }}
      page="shipments"
      onFiltersUpdate={updateFilters}
      open={showAllFilters}
      data-testid="shipments-filters"
      defaultFiltersKeys={OMIT_FILTERS}
      isLoading={isFetching}
      filterOptions={isFetching ? undefined : filterOptions}
      filterOptionsQueryKey={queryKeys.shipmentsFiltersData}
      initialFilters={pick(
        filters,
        OMIT_FILTERS.filter((f) => f !== 'filter')
      )}
    >
      <Filters.TextInput
        label={t('shipments.filters.search', 'Search')}
        name="search"
        placeholder={t('common.search_placeholder', 'Search')}
        columnSize={4}
        data-testid="shipments-search"
      />
      <Filters.MultipleAutocomplete
        label={t('shipments.filters.customers', 'Customers')}
        name="customers"
        filterOptionKey="customers"
        placeholder={t('common.customer', 'Customer')}
        permissions={['your_shipments.filters.customer.all']}
        data-testid="shipments-customers"
      />
      <Filters.FormDatePicker
        label={t('shipments.filters.etd', 'ETD')}
        placeholder="DD-MM"
        inputFormat="dd-MM"
        permissions={['your_shipments.filters.shipment_info.all']}
        name={{
          endDateId: 'departureDateEnd',
          startDateId: 'departureDateStart',
        }}
        data-testid="shipments-departure-date-range"
      />
      <Filters.FormDatePicker
        label={t('shipments.filters.eta', 'ETA')}
        placeholder="DD-MM"
        inputFormat="dd-MM"
        permissions={['your_shipments.filters.shipment_info.all']}
        name={{
          endDateId: 'arrivalDateEnd',
          startDateId: 'arrivalDateStart',
        }}
        data-testid="shipments-arrival-date-range"
      />
      <Filters.MultipleAutocomplete
        label={t('shipments.filters.shippers', 'Shippers')}
        name="shippers"
        filterOptionKey="shippers"
        placeholder={t('common.select', 'Select')}
        permissions={['your_shipments.filters.shipper.all']}
        data-testid="shipments-shippers"
      />
      <Filters.MultipleAutocomplete
        label={t(
          'shipments.filters.open_and_overdue_tasks',
          'Open & Overdue tasks'
        )}
        name="missing_information"
        filterOptionKey="missing_information"
        placeholder={t('common.select', 'Select')}
        permissions={['your_shipments.filters.missing_information.all']}
        data-testid="shipments-missing-information"
      />
      <Filters.MultipleAutocomplete
        label={t('shipments.filters.consignees', 'Consignees')}
        name="consignees"
        filterOptionKey="consignees"
        placeholder={t('common.select', 'Select')}
        permissions={['your_shipments.filters.consignee.all']}
        data-testid="shipments-consignees"
      />
      <Filters.MultipleAutocomplete
        label={t('shipments.filters.contacts', 'Contacts')}
        name="contacts"
        filterOptionKey="contacts"
        placeholder={t('common.select', 'Select')}
        permissions={['your_shipments.filters.contact.all']}
        data-testid="shipments-contacts"
      />
      <Filters.MultipleAutocomplete
        label={t('shipments.filters.load_types', 'Load types')}
        name="types"
        filterOptionKey="types"
        placeholder={t('common.select', 'Select')}
        permissions={['your_shipments.filters.shipment_info.all']}
        data-testid="shipments-load-types"
      />
      <Filters.MultipleAutocomplete
        label={t('shipments.filters.modalities', 'Modalities')}
        name="modalities"
        filterOptionKey="modalities"
        placeholder={t('common.select', 'Select')}
        permissions={['your_shipments.filters.shipment_info.all']}
        data-testid="shipments-modalities"
      />
      <Filters.MultipleAutocomplete
        label={t('shipments.filters.pols', 'POLs')}
        name="pols"
        filterOptionKey="pols"
        placeholder={t('common.select', 'Select')}
        permissions={['your_shipments.filters.shipment_info.all']}
        data-testid="shipments-pols"
      />
      <Filters.MultipleAutocomplete
        label={t('shipments.filters.pods', 'PODs')}
        name="pods"
        filterOptionKey="pods"
        placeholder={t('common.select', 'Select')}
        permissions={['your_shipments.filters.shipment_info.all']}
        data-testid="shipments-pods"
      />
      <Filters.MultipleAutocomplete
        label={t('shipments.filters.suppliers', 'Suppliers')}
        name="suppliers"
        filterOptionKey="suppliers"
        placeholder={t('common.select', 'Select')}
        permissions={['your_shipments.filters.supplier.all']}
        data-testid="shipments-suppliers"
      />
      <Filters.MultipleAutocomplete
        label={t('shipments.filters.freight_forwarders', 'Freight forwarders')}
        name="agents"
        filterOptionKey="agents"
        placeholder={t('common.select', 'Select')}
        permissions={['your_shipments.filters.shipment_info.all']}
        data-testid="shipments-freight-forwarders"
      />
      <Filters.MultipleAutocomplete
        label={t('shipments.filters.statuses', 'Statuses')}
        name="statuses"
        filterOptionKey="statuses"
        placeholder={t('common.select', 'Select')}
        data-testid="shipments-statuses"
      />
      <Filters.MultipleSelect
        name="visibility_only"
        label={t('shipments.filters.visibility_only', 'OneView shipments')}
        placeholder={t('common.select', 'Select')}
        permissions={['your_shipments.filters.agent.all']}
        options={yesNoOptions}
        data-testid="shipments-visibility-only"
      />
      <Filters.MultipleAutocomplete
        name="users"
        label={t('shipments.filters.watched_by', 'Watched by')}
        filterOptionKey="users"
        placeholder={t('common.select', 'Select')}
        data-testid="shipments-watched-by"
      />
    </Filters>
  )
}

export default ShipmentsFilters
