import { Table, TableBody } from '@mui/material'
import ShipmentDocument from 'src/components/ShipmentDocument'
import { permissionTo } from 'src/utils'
import ShipmentDocumentsTableHeader from './ShipmentDocumentsTableHeader'

const ShipmentDocumentsLcl = ({
  showPublicLink,
  sortedDocuments,
  shipmentDocumentsData,
  onEditDocument,
  onDeleteDocument,
}) => {
  return (
    <Table>
      <ShipmentDocumentsTableHeader
        groupByContainer={false}
        showContainer={false}
      />
      <TableBody>
        {sortedDocuments.map((document) => (
          <ShipmentDocument
            key={document.id}
            document={document}
            isModalityAir={true}
            isFcl={false}
            shipmentId={shipmentDocumentsData.id}
            onEdit={onEditDocument}
            onDelete={onDeleteDocument}
            downloadable={permissionTo('shipments.documents.download')}
            containersCount={0}
            disabled={
              !document.document_manageable ||
              !permissionTo('shipments.documents.upload')
            }
            withBooking={true}
            showPublicLink={showPublicLink}
          />
        ))}
      </TableBody>
    </Table>
  )
}

export default ShipmentDocumentsLcl
