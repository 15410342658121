import { FunctionComponent, useState, useEffect, useCallback } from 'react'
import { useDispatch, useStore } from 'react-redux'
import { find } from 'lodash'
import { DateTime } from 'luxon-business-days'
import { ModalityEnum } from 'src/config/constants'
import { useTranslation } from 'react-i18next'
import Autocomplete from 'src/stories/SearchBar'
import { getPortLabel } from 'src/utils/ports'
import Delete from '@mui/icons-material/Delete'
import CustomInput from 'src/components/Common/Input/MuiInput'
import DatePicker from 'src/stories/DatePicker'
import { TextField } from '@mui/material'
import { getFlightNumberIsValid } from '../validations'
import { promisifyAction } from '../../../utils'
import getClassName from '../../../utils/styling/index'

import { addressSuggestions, getVessels } from '../../../stores/actionCreators'

export interface IProps {
  leg: IShipmentLeg
  legId: number
  onChangeLeg: (
    id: number,
    name: string,
    value: string | number | boolean | null
  ) => void
  legsLength: number
  removeShipmentLeg: (id: number) => void
  shipmentType: string
  showErrors: boolean
  isVisibility: boolean
}

const ShipmentLegLine: FunctionComponent<IProps> = ({
  leg,
  onChangeLeg,
  legId,
  legsLength,
  removeShipmentLeg,
  shipmentType,
  showErrors,
  isVisibility,
}) => {
  const { t } = useTranslation()
  const { voyage_flight_no: flightNum } = leg
  const [pol, setPol] = useState<any>(null)
  const [pod, setPod] = useState<any>(null)
  const [vessel, setVessel] = useState<any>(null)
  const [flightNumber, setFlightNumber] = useState<string>('')
  const dispatch = useDispatch()
  const store = useStore()

  const getPortsAsync = promisifyAction(dispatch, addressSuggestions)
  const getVesselsAsync = promisifyAction(dispatch, getVessels)

  useEffect(() => {
    if (flightNum) {
      setFlightNumber(flightNum)
      onChangeLeg(legId, 'voyage_flight_no', flightNum)
    }
    if (leg.loading_port) {
      const getDefaultPOL = async () => {
        await getPortsAsync({
          input: leg.loading_port.code || '',
          only_ports: true,
          shipment_type: shipmentType,
        })
        const ports = store.getState().searchBooking.suggestions
        if (ports && !!ports.length) {
          setPol(ports[0])
          onChangeLeg(legId, 'loading_port_id', ports[0].port_id)
        }
      }

      getDefaultPOL()
    } else {
      setPol(null)
    }

    if (leg.discharge_port) {
      const getDefaultPOD = async () => {
        await getPortsAsync({
          input: leg.discharge_port.code || '',
          only_ports: true,
          shipment_type: shipmentType,
        })
        const ports = store.getState().searchBooking.suggestions
        if (ports && !!ports.length) {
          setPod(ports[0])
          onChangeLeg(legId, 'discharge_port_id', ports[0].port_id)
        }
      }

      getDefaultPOD()
    } else {
      setPod(null)
    }
  }, [leg.loading_port, leg.discharge_port])

  useEffect(() => {
    if (leg.vessel) {
      const getDefaultVessel = async () => {
        await getVesselsAsync({ search: leg.vessel })
        const vesselsResult: any[] = store.getState().vessels.list
        const selectedVessel = find(vesselsResult, {
          name: leg.vessel,
        })
        if (selectedVessel) {
          setVessel(selectedVessel)
          onChangeLeg(legId, 'vessel_id', selectedVessel.id)
        }
      }

      getDefaultVessel()
    } else {
      setVessel(null)
    }
  }, [leg.vessel])

  const fetchSuggestions = useCallback(
    async (searchInput: string) => {
      if (!!searchInput) {
        await getPortsAsync({
          input: searchInput,
          only_ports: true,
          shipment_type: shipmentType,
        })
        const ports = store.getState().searchBooking.suggestions
        return ports
      } else {
        return []
      }
    },
    [getPortsAsync, store]
  )

  const fetchVessels = useCallback(
    async (searchInput: string) => {
      if (!!searchInput) {
        await getVesselsAsync({ search: searchInput.toLowerCase() })
        const vessels: any[] = store.getState().vessels.list
        return vessels
      } else {
        return []
      }
    },
    [getVesselsAsync, store]
  )

  const onChangeSuggestion = (type: string, option: any | null): void => {
    switch (type) {
      case 'pol':
        setPol(option)
        onChangeLeg(legId, 'loading_port_id', option ? option.port_id : null)
        break
      case 'pod':
        setPod(option)
        onChangeLeg(legId, 'discharge_port_id', option ? option.port_id : null)
        break
      case 'vessel':
        setVessel(option)
        onChangeLeg(legId, 'vessel_id', option ? option.id : null)
        break
    }
  }

  const removeLeg = (): void => {
    if (legsLength > 1) {
      removeShipmentLeg(legId)
    }
  }

  return (
    <div className="shipment-edit-window--shipment-leg">
      <div className="field-block" data-testid="pol">
        <Autocomplete
          placeholder={t(
            'common.forms.fields.port_of_loading.placeholder',
            'POL'
          )}
          value={pol || null}
          optionLabel={getPortLabel}
          onChange={onChangeSuggestion.bind(null, 'pol')}
          getData={fetchSuggestions}
          error={showErrors && !pol}
          filterResults={false}
          popperWidth={400}
        />
        {showErrors && (
          <div
            className={getClassName('error-message', {
              hidden: !!pol,
            })}
          >
            {t('common.forms.validations.required', {
              field: t('common.forms.fields.port_of_loading.label', 'POL'),
              defaultValue: "{{field}} can't be blank",
            })}
          </div>
        )}
      </div>
      <div className="field-block">
        <DatePicker
          closeOnSelect={false}
          placeholder="DD-MM-YYYY HH:MM"
          inputFormat="dd-MM-yyyy HH:mm"
          clearable={false}
          value={leg.estimated_departure}
          onChange={(date: DateTime | null) =>
            onChangeLeg(legId, 'estimated_departure', date?.toISO() || null)
          }
          data-testid="shipment-legs-etd"
        />
        {showErrors && !isVisibility && (
          <div
            className={getClassName('error-message', {
              hidden: !!leg.estimated_departure,
            })}
          >
            {t('common.forms.validations.required', {
              field: t('common.forms.fields.etd.label'),
              defaultValue: "{{field}} can't be blank",
            })}
          </div>
        )}
      </div>
      <div className="field-block" data-testid="pod">
        <Autocomplete
          placeholder={t(
            'common.forms.fields.port_of_discharge.placeholder',
            'POD'
          )}
          value={pod || null}
          optionLabel={getPortLabel}
          onChange={onChangeSuggestion.bind(null, 'pod')}
          getData={fetchSuggestions}
          error={
            (showErrors && pol?.port_id === pod?.port_id) ||
            (showErrors && !pod)
          }
          filterResults={false}
          popperWidth={400}
        />
        {showErrors && (
          <div
            className={getClassName('error-message', {
              hidden: !!pol && !!pod && pol.port_id !== pod.port_id,
            })}
          >
            {!pod
              ? t('common.forms.validations.required', {
                  field: t('common.forms.fields.port_of_discharge.label'),
                  defaultValue: "{{field}} can't be blank",
                })
              : t('common.forms.validations.should_not_be_the_same', {
                  firstField: t('common.forms.fields.port_of_discharge.label'),
                  secondField: t('common.forms.fields.port_of_loading.label'),
                  defaultValue:
                    '{{firstField}} should not be same as {{secondField, lowercase}}',
                })}
          </div>
        )}
      </div>
      <div className="field-block">
        <DatePicker
          closeOnSelect={false}
          placeholder="DD-MM-YYYY HH:MM"
          inputFormat="dd-MM-yyyy HH:mm"
          data-testid="shipment-legs-eta"
          clearable={false}
          value={leg.estimated_arrival}
          onChange={(date: DateTime | null) =>
            onChangeLeg(legId, 'estimated_arrival', date?.toISO() || null)
          }
          error={
            showErrors &&
            !isVisibility &&
            leg.estimated_arrival <= leg.estimated_departure
          }
        />
        {showErrors && !isVisibility && (
          <div
            className={getClassName('error-message', {
              hidden: leg.estimated_arrival > leg.estimated_departure,
            })}
          >
            {t('common.forms.validations.should_be_greater_than', {
              firstField: t('common.forms.fields.eta.label'),
              secondField: t('common.forms.fields.etd.label'),
              defaultValue:
                '{{firstField}} should be greater than {{secondField, lowercase}}',
            })}
          </div>
        )}
      </div>
      <div className="vessel-field field-block" data-testid="vessel">
        {shipmentType === ModalityEnum.Air && (
          <>
            <CustomInput
              name="voyage_flight_no"
              onChange={(value) => {
                setFlightNumber(value)
                onChangeLeg(legId, 'voyage_flight_no', value)
              }}
              value={flightNumber}
              placeholder={t(
                'common.forms.fields.flight_number.placeholder',
                'Flight number'
              )}
            />
            {showErrors && (
              <div
                className={getClassName('error-message', {
                  hidden: getFlightNumberIsValid(leg.voyage_flight_no),
                })}
              >
                {t('common.forms.validations.invalid', {
                  field: t('common.forms.fields.flight_number.label'),
                  defaultValue: '{{field}} should be valid',
                })}
              </div>
            )}
          </>
        )}
        {shipmentType !== ModalityEnum.Air && (
          <Autocomplete
            placeholder={t('common.forms.fields.vessel.placeholder', 'Vessel')}
            value={vessel || null}
            onChange={onChangeSuggestion.bind(null, 'vessel')}
            getData={fetchVessels}
          />
        )}
      </div>
      {shipmentType === ModalityEnum.Sea && (
        <div
          className="vessel-field field-block"
          data-testid="voyage_flight_no"
        >
          <TextField
            fullWidth
            value={leg.voyage_flight_no}
            placeholder={t('common.voyage_number', 'Voyage')}
            onChange={(event) => {
              onChangeLeg(legId, 'voyage_flight_no', event.target.value)
            }}
          />
        </div>
      )}
      <div className={`remove-block ${legsLength <= 1 ? 'disabled' : ''}`}>
        <Delete
          color={legsLength <= 1 ? 'disabled' : 'action'}
          onClick={removeLeg}
        />
      </div>
    </div>
  )
}

export default ShipmentLegLine
