import { FunctionComponent, useCallback } from 'react'
import { toNumber } from 'lodash'
import CustomInput, {
  NumberFormatCustom,
} from 'src/components/Common/Input/MuiInput'
import './styles.scss'
import Delete from '@mui/icons-material/Delete'
import { useTranslation } from 'react-i18next'

interface IProps {
  product: any
  productIndex: number
  productsLength: number
  changeProductItem: (
    itemIndex: number,
    name: string,
    value: string | number | boolean
  ) => void
  removeProduct: (index: number) => void
  showErrors: boolean
}

const ProductsItem: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const handleChangeProduct = useCallback(
    (value, name): void => {
      const commonExp: RegExp = /^\d+$/
      let fieldValue: string | number = ''

      if (name === 'units_ordered' && value && !commonExp.test(value)) {
        return
      }

      fieldValue = name !== 'units_ordered' ? value : toNumber(value || 0)

      props.changeProductItem(props.productIndex, name, fieldValue)
    },
    [props]
  )

  const handleChangeVolume = (values): void => {
    props.changeProductItem(props.productIndex, 'volume_cbm', values || 0)
  }

  const handleChangeWeight = (values): void => {
    props.changeProductItem(props.productIndex, 'weight_kg', values || 0)
  }

  const removeProductItem = (): void => {
    if (props.productsLength > 1) {
      props.removeProduct(props.productIndex)
    }
  }

  const { booking_lines } = props.product

  return (
    <div className="products-item--table-row">
      <div className="block quantity medium">
        <CustomInput
          label={
            props.productIndex === 0
              ? t(
                  'purchase_orders.modals.purchase_modal.quantity.label',
                  'Quantity'
                )
              : undefined
          }
          required
          className="input-field"
          name="units_ordered"
          placeholder={t(
            'purchase_orders.modals.purchase_modal.quantity.placeholder',
            'Enter quantity'
          )}
          value={props.product.units_ordered}
          onChange={(data) => handleChangeProduct(data, 'units_ordered')}
          error={props.showErrors}
          helperText={
            props.showErrors
              ? t(
                  'purchase_orders.modals.purchase_modal.quantity.error',
                  'Must be greater than 0'
                )
              : ''
          }
        />
      </div>
      <div className="block x-long">
        <CustomInput
          required
          label={
            props.productIndex === 0
              ? t(
                  'purchase_orders.modals.purchase_modal.product_name.label',
                  'Product Name'
                )
              : undefined
          }
          className="input-field"
          placeholder={t(
            'purchase_orders.modals.purchase_modal.product_name.placeholder',
            'Enter Product Name'
          )}
          name="product_name"
          value={props.product.product_name || ''}
          onChange={(data) => handleChangeProduct(data, 'product_name')}
          error={props.showErrors}
          helperText={
            props.showErrors
              ? t(
                  'purchase_orders.modals.purchase_modal.product_name.error',
                  'Can’t be blank'
                )
              : ''
          }
        />
      </div>
      <div className="block x-long">
        <CustomInput
          label={
            props.productIndex === 0
              ? t(
                  'purchase_orders.modals.purchase_modal.product_code.label',
                  'Product Code'
                )
              : undefined
          }
          name="product_code"
          placeholder={t(
            'purchase_orders.modals.purchase_modal.product_code.placeholder',
            'Enter Product Code'
          )}
          value={props.product.product_code || ''}
          onChange={(data) => handleChangeProduct(data, 'product_code')}
        />
      </div>
      <div className="block medium">
        <CustomInput
          label={
            props.productIndex === 0
              ? t(
                  'purchase_orders.modals.purchase_modal.volume.label',
                  'Volume'
                )
              : undefined
          }
          placeholder={t(
            'purchase_orders.modals.purchase_modal.volume.placeholder',
            '0.00'
          )}
          className="input-field"
          name="volume_cbm"
          id="volume_cbm"
          value={!!booking_lines.length ? booking_lines[0].volume_cbm : ''}
          onChange={(event) => handleChangeVolume(event)}
          inputComponent={NumberFormatCustom as any}
          endAdornment={
            <div>
              m<sup>3</sup>
            </div>
          }
        />
      </div>
      <div className="block medium">
        <CustomInput
          label={
            props.productIndex === 0
              ? t(
                  'purchase_orders.modals.purchase_modal.weight.label',
                  'Weight'
                )
              : undefined
          }
          placeholder={t(
            'purchase_orders.modals.purchase_modal.weight.placeholder',
            '1,000'
          )}
          className="input-field"
          name="weight_kg"
          id="weight_kg"
          value={!!booking_lines.length ? booking_lines[0].weight_kg : ''}
          inputComponent={NumberFormatCustom as any}
          endAdornment={<div>kg</div>}
          onChange={(event) => handleChangeWeight(event)}
        />
      </div>

      <div
        style={{ paddingTop: props.productIndex === 0 ? '25px' : 0 }}
        className={`remove-block ${
          props.productsLength <= 1 ? 'disabled' : ''
        }`}
        data-testid="remove-product"
      >
        <Delete
          data-testid="remove-product-button"
          color={props.productsLength <= 1 ? 'disabled' : 'action'}
          onClick={removeProductItem}
        />
      </div>
    </div>
  )
}

export default ProductsItem
