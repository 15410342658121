import { Divider } from '@mui/material'
import Box from '@mui/material/Box'
import { SyntheticEvent } from 'react'
import { MenuItemWithCheckbox } from 'src/stories/Lab/Menu/components/MenuItemWithCheckbox'

const AutocompleteBaseSelectAll = ({ selectAll, onSelectAll }) => {
  const onMouseDown = (e: SyntheticEvent) => e.preventDefault()

  return (
    <>
      <Box onMouseDown={onMouseDown}>
        <MenuItemWithCheckbox
          onClick={onSelectAll}
          selected={selectAll}
          text="Select all"
        />
      </Box>
      <Divider />
    </>
  )
}

export default AutocompleteBaseSelectAll
