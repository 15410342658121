import { useState } from 'react'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import CancelShipmentModal from 'src/components/CancelShipmentModal'
import { useTranslation } from 'react-i18next'
import { ShipmentCostsAirQuote } from 'src/components/ShipmentCosts/ShipmentCostsAirQuote'
import { ShipmentCostsAirQuotations } from 'src/components/ShipmentCosts/ShipmentCostsAirQuotations'
import { ShipmentCostsTransactionItems } from 'src/components/ShipmentCosts/ShipmentCostsTransactionItems'
import { ShipmentStatusSequence } from 'src/config/statusSequence'
import { ShipmentQuotationsContainerProps } from './ShipmentCosts.props'

interface ShipmentCostsContentProps extends ShipmentQuotationsContainerProps {
  afterCancelHandler: () => void
}

export const ShipmentCostsContent: React.FC<ShipmentCostsContentProps> = ({
  shipment,
  quotations,
  afterCancelHandler,
}) => {
  const { t } = useTranslation()
  const [cancelDialogOpen, setCancelDialogOpen] = useState<boolean>(false)

  const shipmentStatus = shipment.status.toLocaleLowerCase()

  const shipmentStatusSequence = shipment.status_sequence

  const statusIsQuote = shipmentStatus === 'quo'

  const statusIsBookingRequestedAndBeyond =
    shipmentStatusSequence >= ShipmentStatusSequence.BookingRequested

  return (
    <Box px={1.5} py={statusIsBookingRequestedAndBeyond ? 0 : 1.5}>
      {!statusIsBookingRequestedAndBeyond && (
        <>
          {statusIsQuote && quotations.length === 0 && (
            <div data-testid="shipment-costs-quote-empty">
              <ShipmentCostsAirQuote {...{ shipment, quotations }} />
            </div>
          )}
          {(!statusIsQuote || quotations.length > 0) && (
            <div
              data-testid="shipment-costs-quotations"
              className="shipment-costs-quotations"
            >
              <ShipmentCostsAirQuotations {...{ shipment, quotations }} />
            </div>
          )}
          {shipment.shipment_phase === 'quote' && (
            <div data-testid="shipment-costs-footer">
              <Stack
                mt={2.5}
                direction="row"
                sx={{
                  alignItems: 'center',
                  justifyContent: statusIsQuote ? 'end' : 'space-between',
                }}
              >
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    setCancelDialogOpen(true)
                  }}
                >
                  {t(
                    'common.buttons.cancel_quote_request',
                    'Cancel quote request'
                  )}
                </Link>
              </Stack>
              <CancelShipmentModal
                open={cancelDialogOpen}
                close={() => {
                  setCancelDialogOpen(false)
                }}
                afterCancel={afterCancelHandler}
                shipmentId={shipment.id}
              />
            </div>
          )}
        </>
      )}
      {statusIsBookingRequestedAndBeyond && (
        <div data-testid="shipment-costs-transaction-items">
          <ShipmentCostsTransactionItems
            shipmentId={shipment.id}
            loadType={shipment.load_type}
          />
        </div>
      )}
    </Box>
  )
}
