import { queryClient } from 'src/services/http-common'
import { queryKeys } from 'src/services/endpoints'
import { OverviewAllowedColumnsAndFiltersResponse } from 'src/services/Api/overview/types'
import { getAllowedColumnsObject } from '../../utils'

export const getCellIsEditable = (cell) => {
  const allowedFiltersAndColumnsResponse:
    | OverviewAllowedColumnsAndFiltersResponse
    | undefined = queryClient.getQueryData([
    queryKeys.overviewAllowedFiltersAndColumns,
  ])

  const allowedFiltersAndColumns = getAllowedColumnsObject(
    allowedFiltersAndColumnsResponse
  )

  const accessorKey = cell.column.columnDef.accessorKey ?? ''
  return allowedFiltersAndColumns[accessorKey]?.includes('edit') ?? false
}
