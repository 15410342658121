import {
  Popper,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
} from '@mui/material'
import { orderBy } from 'lodash'
import { AvatarGroup } from 'src/stories/AvatarGroup'
import { Avatar } from 'src/stories/Avatar'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

const ShipmentDocumentRowViewableBy = ({
  uploadedBy,
  viewableBy,
  documentId,
}) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)
  const documentOwner = uploadedBy
  const onMouseEnter = (event): void => {
    setAnchorEl(event.currentTarget)
  }

  const onMouseLeave = (): void => setAnchorEl(null)
  const avatarsGroup = orderBy(viewableBy || [], ['name']).map(
    (org: IOrganization) => {
      return {
        id: org.id.toString(),
        alt: org.name,
        className: org.role_code,
        src: org.logo || '',
      }
    }
  )

  return (
    <>
      <TableCell>
        <AvatarGroup
          data-testid="shipment-documents-list-item-viewable-by"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          size="medium"
          disableTooltips={true}
          avatars={avatarsGroup}
          max={3}
        />
      </TableCell>

      <Popper
        open={!!anchorEl}
        id={`${documentId}`}
        anchorEl={anchorEl}
        placement="left-start"
        className="shipment-collaborators__popper mui-override"
        style={{ background: 'white' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="short"></TableCell>
              <TableCell className="medium">
                {t('shipment_documents.table.organization', 'Organization')}
              </TableCell>
              <TableCell className="medium">
                {t('shipment_documents.table.uploaded_by', 'Uploaded by')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {viewableBy.map((organization) => (
              <TableRow key={organization.id}>
                <TableCell className="short">
                  <Avatar
                    size="small"
                    alt={organization.name}
                    src={organization.logo || 'no-logo'}
                    className={`organization-logo ${organization.role_code}`}
                  />
                </TableCell>
                <TableCell className="medium">{organization.name}</TableCell>
                <TableCell className="medium">
                  {organization.name === documentOwner?.organization_name
                    ? `${documentOwner.full_name}`
                    : ''}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Popper>
    </>
  )
}

export default ShipmentDocumentRowViewableBy
