import {
  Table,
  TableCell,
  TableBody,
  TableRow,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import ShipmentDocument from 'src/components/ShipmentDocument'
import { permissionTo } from 'src/utils'
import { Fragment } from 'react'
import ShipmentDocumentsTableHeader from './ShipmentDocumentsTableHeader'

type ShipmentDocumentsFclGroupedByContainerProps = {
  name: string
  id: number | string
  key: number | string
  documents: IShipmentDocument[]
}

const ShipmentDocumentsFclGroupedByContainer = ({
  showPublicLink,
  sortedDocuments,
  shipmentDocumentsData,
  onEditDocument,
  onDeleteDocument,
}) => {
  const { t } = useTranslation()
  const documents = sortedDocuments.map((document) => {
    return {
      ...document,
      containers:
        document.containers.length > 0
          ? document.containers
          : [{ id: null, number: null }],
    }
  })

  const groups: ShipmentDocumentsFclGroupedByContainerProps[] = Object.values(
    documents.reduce((acc, document: IShipmentDocument) => {
      document.containers.forEach((container) => {
        const containerId = container.id
        if (!acc[containerId]) {
          const name =
            container?.number ??
            container?.container_index ??
            t(
              'shipment_documents.not_specified_to_a_container',
              'Not specific to a container'
            )

          acc[containerId] = {
            name,
            documents: [],
            id: containerId,
            key: containerId ?? 'unspecified',
          }
        }
        acc[containerId].documents.push({
          ...document,
          containers: [container],
        })
      })
      return acc
    }, {})
  )

  return (
    <Table>
      <ShipmentDocumentsTableHeader
        groupByContainer={true}
        showContainer={true}
      />
      <TableBody>
        {groups.map((group) => (
          <Fragment key={group.key}>
            <TableRow>
              <TableCell
                colSpan={7}
                sx={(theme) => ({
                  backgroundColor: theme.palette.grey[50],
                })}
              >
                <Typography variant="body1Strong">{group.name}</Typography>
              </TableCell>
            </TableRow>
            {group.documents.map((document) => (
              <ShipmentDocument
                key={document.id}
                document={document}
                isModalityAir={false}
                shipmentId={shipmentDocumentsData.id}
                onEdit={onEditDocument}
                onDelete={onDeleteDocument}
                isFcl={true}
                downloadable={permissionTo('shipments.documents.download')}
                containersCount={shipmentDocumentsData?.containers?.length ?? 0}
                disabled={
                  !document.document_manageable ||
                  !permissionTo('shipments.documents.upload')
                }
                withBooking={true}
                showPublicLink={showPublicLink}
                isGroupedByContainer={true}
              />
            ))}
          </Fragment>
        ))}
      </TableBody>
    </Table>
  )
}

export default ShipmentDocumentsFclGroupedByContainer
