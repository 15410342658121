import { useTranslation } from 'react-i18next'
import OverviewTableShipmentColoredCell from './OverviewTableShipmentColoredCell'
import OverviewTableText from './OverviewTableText'

const OverviewTableContainerInspectionStatus = ({ value }) => {
  const { t } = useTranslation()

  if (!value) return <OverviewTableText text="-" />

  const statuses = {
    notified: {
      color: 'error',
      text: t(
        'vessels_page.pod_customs_inspection_status.notified',
        'Selected for scan'
      ),
    },
    released: {
      color: 'success',
      text: t(
        'vessels_page.pod_customs_inspection_status.released',
        'Released from scan'
      ),
    },
    physical_inspection_at_terminal: {
      color: 'default',
      text: t(
        'vessels_page.pod_customs_inspection_status.physical_inspection_at_terminal',
        'Physical inspection'
      ),
    },
    physical_inspection_at_customs: {
      color: 'default',
      text: t(
        'vessels_page.pod_customs_inspection_status.physical_inspection_at_customs',
        'Physical inspection'
      ),
    },
    block: {
      color: 'default',
      text: t('vessels_page.pod_customs_inspection_status.block', 'Blockage'),
    },
    scan: {
      color: 'default',
      text: t('vessels_page.pod_customs_inspection_status.scan', 'Scan'),
    },
  }

  return (
    <OverviewTableShipmentColoredCell
      color={statuses[value].color}
      text={statuses[value].text}
    />
  )
}

export default OverviewTableContainerInspectionStatus
