import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Menu, MenuItem, Box, IconButton, TableCell } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { permissionTo } from 'src/utils'
import usePreAlert from 'src/hooks/usePreAlert'
import { toggleShipmentShareModal } from 'src/stores/actionCreators'

const ShipmentDocumentRowActions = ({
  edit,
  remove,
  documentId,
  showPublicLink,
  documentManageable,
}) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const dispatch = useDispatch()
  const isPreAlert = usePreAlert()

  const moreActionsOpen = Boolean(anchorEl)

  const handleMoreActionsClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMoreActionsClose = () => {
    setAnchorEl(null)
  }

  const editDocument = () => {
    edit()
    handleMoreActionsClose()
  }

  const removeDocument = () => {
    remove()
    handleMoreActionsClose()
  }

  const shareDocument = () => {
    dispatch(toggleShipmentShareModal(true, documentId))
    handleMoreActionsClose()
  }
  return (
    <TableCell>
      <Box display="flex" justifyItems="center">
        <IconButton
          id="more-actions-toggler"
          data-testid="shipment-documents-list-item-actions"
          aria-haspopup="true"
          aria-expanded={moreActionsOpen ? 'true' : undefined}
          aria-controls={moreActionsOpen ? 'more-actions-menu' : undefined}
          onClick={handleMoreActionsClick}
          disabled={!documentManageable}
          size="small"
        >
          <MoreVertIcon color="action" />
        </IconButton>
        <Menu
          id="more-actions-menu"
          anchorEl={anchorEl}
          open={moreActionsOpen}
          MenuListProps={{
            'aria-labelledby': 'more-actions-toggler',
          }}
          onClose={handleMoreActionsClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem
            key="edit"
            onClick={editDocument}
            disabled={!permissionTo('shipments.bookings.documents.manage')}
          >
            {t('common.buttons.edit', 'Edit')}
          </MenuItem>
          <MenuItem
            key="remove"
            onClick={removeDocument}
            disabled={!permissionTo('shipments.bookings.documents.manage')}
          >
            {t('common.buttons.remove', 'Remove')}
          </MenuItem>
          {isPreAlert && showPublicLink && (
            <MenuItem
              key="share"
              onClick={shareDocument}
              disabled={!permissionTo('shipments.bookings.documents.manage')}
            >
              {t('common.buttons.share', 'Share')}
            </MenuItem>
          )}
        </Menu>
      </Box>
    </TableCell>
  )
}

export default ShipmentDocumentRowActions
