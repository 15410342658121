import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'

import { permissionTo } from 'src/utils'
import Input from './Elements/Input'
import TextArea from './Elements/TextArea'
import CountryAutocomplete from './Elements/CountryAutocomplete'
import AddressAutocomplete from './Elements/AddressAutocomplete'

const BookingPartiesModalContentAddressForm = () => {
  const { t } = useTranslation()

  const canCreateOrManageAddress = permissionTo('address_book.manage')

  return (
    <Grid container rowSpacing={2} columnSpacing={5} maxWidth={670}>
      <Grid item xs={6}>
        <AddressAutocomplete freeSolo={canCreateOrManageAddress} />
      </Grid>
      <Grid item xs={6}>
        <TextArea
          label={t('shipment_assign_booking_parties.address', 'Address')}
          name="shipmentAddress.address"
          placeholder={t(
            'shipment_assign_booking_parties.address.placeholder',
            'Add address'
          )}
          disabled={!canCreateOrManageAddress}
          testId="booking-parties-modal-company-address"
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          label={t('shipment_assign_booking_parties.city', 'City')}
          name="shipmentAddress.city"
          placeholder={t(
            'shipment_assign_booking_parties.city.placeholder',
            'Add city'
          )}
          disabled={!canCreateOrManageAddress}
          testId="booking-parties-modal-company-city"
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          label={t(
            'shipment_assign_booking_parties.postal_code',
            'Postal code'
          )}
          name="shipmentAddress.postalCode"
          required={false}
          placeholder={t(
            'shipment_assign_booking_parties.postal_code.placeholder',
            'Add postal code'
          )}
          disabled={!canCreateOrManageAddress}
          testId="booking-parties-modal-company-postal-code"
        />
      </Grid>
      <Grid item xs={6}>
        <CountryAutocomplete
          label={t('shipment_assign_booking_parties.country', 'Country')}
          disabled={!canCreateOrManageAddress}
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          label={t('shipment_assign_booking_parties.vat_number', 'VAT number')}
          name="shipmentAddress.vatNumber"
          required={false}
          placeholder={t(
            'shipment_assign_booking_parties.vat_number.placeholder',
            'Add VAT number'
          )}
          disabled={!canCreateOrManageAddress}
          testId="booking-parties-modal-company-vat-number"
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          label={t(
            'shipment_assign_booking_parties.eory_number',
            'EORI number'
          )}
          name="shipmentAddress.eoriNumber"
          required={false}
          placeholder={t(
            'shipment_assign_booking_parties.eory_number.placeholder',
            'Add EORI number'
          )}
          disabled={!canCreateOrManageAddress}
          testId="booking-parties-modal-company-eory-number"
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          label={t(
            'shipment_assign_booking_parties.more_information',
            'More information'
          )}
          name="shipmentAddress.comment"
          required={false}
          placeholder={t(
            'shipment_assign_booking_parties.more_information.placeholder',
            'Add more information'
          )}
          disabled={!canCreateOrManageAddress}
          testId="booking-parties-modal-company-comment"
        />
      </Grid>
    </Grid>
  )
}

export default BookingPartiesModalContentAddressForm
