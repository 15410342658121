import { Link as MuiLink, TableCell, Typography } from '@mui/material'

const ShipmentDocumentRowActionsDocumentName = ({ downloadable, document }) => {
  return (
    <TableCell>
      <Typography
        noWrap
        variant="body1"
        component="div"
        className="shipment-document--file-name"
      >
        {downloadable && (
          <MuiLink
            variant="body1"
            href={document.download_url}
            download={document.original_filename}
            target="_blank"
            rel="noopener noreferrer"
          >
            {document.original_filename}
          </MuiLink>
        )}
        {!downloadable && document.original_filename}
      </Typography>
    </TableCell>
  )
}

export default ShipmentDocumentRowActionsDocumentName
