import { useHistory, Link, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { parse } from 'query-string'
import { useCreatePasswordChange } from 'src/services/Api/password'
import { AxiosResponseHeaders } from 'axios'
import { Link as MuiLink, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
import CenteredLayout from 'src/pages/Layout/Centered'
import ResetPasswordRequestLoading from './ResetPasswordRequestLoading'
import ResetPasswordRequestCounter from './ResetPasswordRequestCounter'

const ResetPasswordRequest = () => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const params = parse(location.search)

  const type = (params?.type as string) ?? 'password_expired'

  const [retryAfter, setRetryAfter] = useState<number | null>(null)
  const [message, setMessage] = useState<string>('')
  const [isError, setIsError] = useState(false)

  const {
    mutateAsync: requestPasswordChange,
    isLoading,
  } = useCreatePasswordChange()

  const email = localStorage.getItem('resetPasswordEmail')

  const handleResetPasswordRequestError = (error: {
    response: {
      data: { data: null; message: string }
      headers: AxiosResponseHeaders
    }
  }) => {
    const { response } = error
    const retryAfter =
      response?.headers?.['retry-after'] ?? null
        ? Number(response?.headers['retry-after'])
        : null
    setIsError(true)
    setRetryAfter(retryAfter)
    setMessage(response?.data?.message ?? null)
  }

  const sendResetPasswordRequest = (userEmail: string) => {
    requestPasswordChange({
      type,
      email: userEmail,
      redirect_url: `${window.location.origin}/change-password`,
    })
      .then((res) => {
        setIsError(false)
        setMessage(res?.message ?? null)
      })
      .catch(handleResetPasswordRequestError)
      .finally(() => {
        localStorage.removeItem('resetPasswordEmail')
      })
  }

  useEffect(() => {
    if (!email) {
      history.push('/login')
      return
    }
    sendResetPasswordRequest(email)
  }, [])

  if (isLoading) {
    return <ResetPasswordRequestLoading />
  }

  return (
    <CenteredLayout
      icon={isError ? UnsubscribeIcon : MarkEmailReadIcon}
      title=""
      description={message}
      variant={isError ? 'error' : 'success'}
    >
      {retryAfter !== null && (
        <ResetPasswordRequestCounter retryAfter={retryAfter} />
      )}
      {isError && (
        <Typography variant="body1" mb={2} data-testid="error-helper-block">
          {t('reset_password_link_request_helper_text')}
        </Typography>
      )}
      <MuiLink component={Link} to="/login" variant="body1">
        {t('common.actions.back_to_login', 'Back to Login')}
      </MuiLink>
    </CenteredLayout>
  )
}

export default ResetPasswordRequest
