import {} from 'react-i18next'
import { permissionTo } from 'src/utils'
import { LoadTypeEnum } from 'src/config/constants'
import Stack from '@mui/material/Stack'
import { useGetShipmentTransactionItems } from 'src/services/Api/shipments'
import { NoTransactionItems } from 'src/pages/EmptyStates'
import { ShipmentCostsTransactionItemsFcl } from './ShipmentCostsTransactionItemsFcl'
import { ShipmentCostsTransactionItemsLcl } from './ShipmentCostsTransactionItemsLcl'
import { ShipmentCostsTransactionItemsSkeleton } from './ShipmentCostsTransactionItemsSkeleton'
import ShipmentCostsCollaborators from './ShipmentCostsCollaborators'

interface ShipmentCostsTransactionItemsProps {
  shipmentId: string | number
  loadType: string
  invoicePerContainer?: boolean
  defaultGrouping?: 'service' | 'container'
  shipmentCollaborators?: IShipmentParty[]
  userOrganizationId?: string | number
  userOrganizationName?: string
  onAddOrEditCostsClick?: (
    buyer: IShipmentParty,
    items: ITransactionItem[]
  ) => void
}

export const ShipmentCostsTransactionItems: React.FC<ShipmentCostsTransactionItemsProps> = ({
  userOrganizationId,
  loadType,
  shipmentId,
  shipmentCollaborators = [],
  defaultGrouping = 'service',
  invoicePerContainer = false,
  onAddOrEditCostsClick = () => {},
}) => {
  const { data, isLoading } = useGetShipmentTransactionItems({
    shipmentId,
  })

  const transactionItems = data?.data ?? []
  const transactions = data?.meta?.transactions ?? []

  const canManageCosts: boolean = permissionTo('shipments.costs.manage')

  return (
    <Stack spacing={2}>
      {isLoading && <ShipmentCostsTransactionItemsSkeleton />}
      {!isLoading && transactionItems.length === 0 && !canManageCosts && (
        <NoTransactionItems shipmentId={shipmentId} />
      )}
      {!isLoading && transactionItems.length > 0 && (
        <>
          {loadType === LoadTypeEnum.fcl && (
            <ShipmentCostsTransactionItemsFcl
              defaultGrouping={defaultGrouping}
              invoicePerContainer={invoicePerContainer}
              transactionItems={transactionItems}
              canManageCosts={canManageCosts}
              loading={isLoading}
              shipmentCollaborators={shipmentCollaborators}
              onAddOrEditCostsClick={onAddOrEditCostsClick}
            />
          )}
          {loadType === LoadTypeEnum.lcl && (
            <ShipmentCostsTransactionItemsLcl
              transactionItems={transactionItems}
              canManageCosts={canManageCosts}
              loading={isLoading}
              onAddOrEditCostsClick={onAddOrEditCostsClick}
            />
          )}
        </>
      )}
      {!isLoading && canManageCosts && userOrganizationId && (
        <ShipmentCostsCollaborators
          transactionItems={transactionItems}
          transactions={transactions}
          shipmentCollaborators={shipmentCollaborators}
          userOrganizationId={userOrganizationId}
          onAddOrEditCostsClick={onAddOrEditCostsClick}
        />
      )}
    </Stack>
  )
}
