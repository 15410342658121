import FormControl from '@mui/material/FormControl'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { isArray } from 'lodash'
import { useTranslation } from 'react-i18next'
import FormLabel from 'src/stories/Lab/FormLabel'
import { MenuItemWithCheckbox } from '../Menu/components/MenuItemWithCheckbox'
import { SelectProps } from './Select.props'
import { StyledSelect, getRenderValueForMultipleSelect } from './Select.utils'
import { DefaultMenuProps } from './Select.constants'
import { SelectClearButton } from './SelectClearButton'
import { MultiSelectSelectAll } from './MultiSelectSelectAll'

export const MultiSelect: React.FC<SelectProps> = (props) => {
  const {
    label,
    options,
    onReset,
    value = [],
    onSelectAll,
    MenuProps,
    allSelectable = false,
    ...muiSelectProps
  } = props

  const { t } = useTranslation()

  const placeholder = props?.placeholder ?? t('common.select', 'Select')

  const activeOptions = options.filter((option) => !option.disabled)
  const valueIsArray = isArray(value)

  const onResetClick = () => {
    if (onReset) {
      onReset()
    }
  }

  const onSelectAllClick = () => {
    if (!onSelectAll) {
      return
    }
    if (valueIsArray && value.length > 0) {
      return onSelectAll([])
    }
    onSelectAll(activeOptions.map((option) => option.id))
  }

  const resetButtonVisible = valueIsArray && value.length > 0 && !props.disabled

  return (
    <FormControl fullWidth>
      {label && (
        <FormLabel label={label} count={valueIsArray ? value.length : 0} />
      )}
      <StyledSelect
        multiple
        displayEmpty
        value={value}
        autoWidth={false}
        endAdornment={
          <SelectClearButton
            visible={resetButtonVisible}
            onClick={onResetClick}
          />
        }
        className={resetButtonVisible ? 'clearable' : ''}
        IconComponent={KeyboardArrowDownIcon}
        SelectDisplayProps={{
          role: 'button',
          className: 'multi-select-menu-trigger',
        }}
        renderValue={(selected: any) =>
          getRenderValueForMultipleSelect(selected, options, placeholder)
        }
        MenuProps={{
          ...DefaultMenuProps,
          ...MenuProps,
          disableAutoFocusItem: true,
        }}
        {...muiSelectProps}
      >
        {allSelectable && (
          <MultiSelectSelectAll
            selected={value}
            onClick={onSelectAllClick}
            options={activeOptions}
          />
        )}
        {options.map((option) => (
          <MenuItemWithCheckbox
            key={option.id}
            value={option.id}
            text={option.label}
            disableRipple={true}
            disabled={option?.disabled ?? false}
            selected={valueIsArray && value.includes(option.id)}
          />
        ))}
      </StyledSelect>
    </FormControl>
  )
}
