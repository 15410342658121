import {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
  useMemo,
  ChangeEvent,
} from 'react'
import { useDispatch, useStore, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { DateTime } from 'luxon-business-days'
import { find, orderBy, map, filter, maxBy } from 'lodash'
import uuidv4 from 'uuid/v4'
import Typography from '@mui/material/Typography'
import MuiTable from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import {
  Link,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  SelectChangeEvent,
} from '@mui/material'

import { ModalityEnum } from 'src/config/constants'
import {
  getTranslationForModality,
  convertDateForComparison,
} from 'src/utils/helpers'
import { useTranslation } from 'react-i18next'
import CustomInput from 'src/components/Common/Input/MuiInput'
import { AutoCompleteSelect } from 'src/stories'
import { promisifyAction } from 'src/utils'
import Modal from 'src/components/Common/Modal'
import { useGetProductGroups } from 'src/services/Api/common'
import { useGetIsUniqueTrackingKeyAsync } from 'src/services/Api/shipments'
import DatePicker from 'src/stories/DatePicker'
import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'

import {
  getCarriers,
  updateShipmentData,
  shipmentContainersGetData,
} from '../../stores/actionCreators'
import ShipmentLegLine from './ShipmentLegLine'
import { shipmentInfoValidation, cargoClosingValidation } from './validations'

import './styles.scss'

interface IProps {
  shipment: IOverviewShipment | ISingleInlandTransportShipmentData
  open: boolean
  onClose: () => void
  fetchData: () => void
}

const cargoButtons = ['estimated', 'confirmed']

const freightPayableOptions = ['unspecified', 'prepaid', 'collect']

interface IShipmentFormData {
  id: number | null
  bl_number: string
  closing_datetime: string
  closing_datetime_status: string
  mbl_freight_payable: string
  shipment_legs: IShipmentLeg[]
  booking_number: string
  carrier_id: null | number
  delivery_available_from: string
  incoterm: IIncoterm | null
  carrier_name: string
  shipment_type: string
  container_number: string
  destination_demurrage_starting_from: string
  modality: ModalityEnum
  sailing_to_be_announced: boolean
}

const initialShipmentInfo: IShipmentFormData = {
  id: null,
  bl_number: '',
  booking_number: '',
  carrier_id: null,
  closing_datetime: '',
  closing_datetime_status: '',
  delivery_available_from: '',
  mbl_freight_payable: 'unspecified',
  shipment_legs: [],
  incoterm: null,
  carrier_name: '',
  shipment_type: '',
  container_number: '',
  destination_demurrage_starting_from: '',
  modality: ModalityEnum.Sea,
  sailing_to_be_announced: false,
}

const EditShipmentWindow: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const modality = props.shipment.modality as ModalityEnum

  const carrierType: 'carrier' | 'airline' =
    modality === ModalityEnum.Sea ? 'carrier' : 'airline'

  const [shipmentInfo, setShipmentInfo] = useState<IShipmentFormData>(
    initialShipmentInfo
  )
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)
  const [carrier, setCarrier] = useState<any>(null)
  const [incoterm, setIncoterm] = useState<number | null>(null)
  const [productGroup, setProductGroup] = useState<number | string>('')
  const [cargoError, setCargoError] = useState<string>('')
  const [trackingKeyErrors, setTrackingKeyErrors] = useState<any>({
    booking_number: [],
    bl_number: [],
  })
  const [deliveryAvailableError, setDeliveryAvailableError] = useState<string>(
    ''
  )
  const [showErrors, setShowErrors] = useState<boolean>(false)
  const dispatch: Dispatch = useDispatch()
  const store = useStore()

  const getCarriersAsync = promisifyAction(dispatch, getCarriers)
  const submitShipmentDataAsync = promisifyAction(dispatch, updateShipmentData)
  const shipmentContainersGetDataAsync = promisifyAction(
    dispatch,
    shipmentContainersGetData
  )
  const { fetchAsync: getUniqueTrackingKey } = useGetIsUniqueTrackingKeyAsync()

  const { incoterms } = useSelector((state: IGlobalState) => ({
    incoterms:
      state.bookings.incoterms?.map((incotermItem) => ({
        id: incotermItem?.id,
        label: incotermItem?.code,
      })) ?? [],
  }))

  const { data: productGroups } = useGetProductGroups({
    refetchOnMount: false,
  })

  const productGroupsOptions = useMemo(
    () =>
      productGroups?.map((item) => ({
        id: item.id,
        label: item.name,
      })) ?? [],
    [productGroups]
  )

  useEffect(() => {
    if (props.shipment) {
      setShipmentInfo({
        ...props.shipment,
        container_number:
          props.shipment.containers &&
          props.shipment.containers[0]?.container_number,
      } as IShipmentFormData)
      setIncoterm(props.shipment.incoterm?.id ?? null)
      setProductGroup(props.shipment.product_group?.id ?? '')
    }
    if (props.open && props.shipment.carrier_name) {
      const getDefaultCarrier = async () => {
        await getCarriersAsync({
          search: props.shipment.carrier_name,
          carrier_type: carrierType,
        })
        const carriersResult: any[] = store.getState().carriers.list
        const selectedCarrier = find(carriersResult, {
          name: props.shipment.carrier_name,
        })
        if (selectedCarrier) {
          setCarrier({
            id: selectedCarrier.id,
            name: `${selectedCarrier.name} - ${selectedCarrier.scac}`,
          })
        }
      }

      getDefaultCarrier()
    }
  }, [props.shipment, props.open])

  useEffect(() => {
    const validationAsync = async () => {
      const resp = await getUniqueTrackingKey({
        bl_number: shipmentInfo.bl_number,
        booking_number: shipmentInfo.booking_number,
        carrier_id: carrier?.id,
        shipment_id: props.shipment.id,
      })
      setTrackingKeyErrors(resp)
    }

    if (
      (shipmentInfo.booking_number || shipmentInfo.bl_number) &&
      carrier?.id
    ) {
      validationAsync()
    }
  }, [shipmentInfo.booking_number, shipmentInfo.bl_number, carrier])

  const onClose = useCallback(() => {
    setShipmentInfo({ ...initialShipmentInfo })
    setButtonLoading(false)
    setShowErrors(false)
    setCarrier(null)
    props.onClose()
  }, [props.onClose])

  const onSave = async (): Promise<any> => {
    if (props.shipment.estimated_departure && !shipmentInfo.closing_datetime) {
      setCargoError(
        t('common.forms.validations.required', {
          field: t(
            'common.forms.fields.cargo_closing_datetime.label',
            'Cargo closing date'
          ),
        })
      )
      return
    }
    const deliveryAvailableFrom = shipmentInfo.delivery_available_from
    const ETA = maxBy<any>(
      shipmentInfo.shipment_legs,
      (x) => x?.estimated_arrival
    )?.estimated_arrival

    if (
      deliveryAvailableFrom &&
      deliveryAvailableFrom < convertDateForComparison(ETA)
    ) {
      setDeliveryAvailableError(
        t('common.forms.validations.should_be_greater_than', {
          firstField: t('common.forms.fields.delivery_available_from.label'),
          secondField: t('common.forms.fields.eta.label'),
          defaultValue:
            '{{firstField}} should be greater than {{secondField, lowercase}}',
        })
      )
      return
    }
    if (
      props.shipment.estimated_departure &&
      !cargoClosingValidation(shipmentInfo)
    ) {
      setCargoError(
        t('common.forms.validations.should_be_lest_than', {
          firstField: t('common.forms.fields.cargo_closing_datetime.label'),
          secondField: t('common.forms.fields.etd.label'),
          defaultValue:
            '{{firstField}} should be less than {{secondField, lowercase}}',
        })
      )
      return
    }
    if (!shipmentInfoValidation(shipmentInfo, props.shipment.visibility_only)) {
      setShowErrors(true)
      return
    }
    try {
      setButtonLoading(true)
      const shipmentData = {
        ...shipmentInfo,
        carrier_id: !!carrier ? carrier.id : null,
        incoterm_id: incoterm,
        product_group_id: productGroup,
        shipment_legs_attributes: shipmentInfo.shipment_legs,
      }

      const {
        bl_number,
        booking_number,
        carrier_id,
        incoterm_id,
        product_group_id,
        closing_datetime,
        closing_datetime_status,
        delivery_available_from,
        destination_demurrage_starting_from,
        shipment_legs_attributes,
        mbl_freight_payable,
        container_number,
      } = shipmentData
      const shipmentParams = {
        bl_number,
        booking_number,
        carrier_id,
        incoterm_id,
        product_group_id,
        closing_datetime,
        closing_datetime_status,
        delivery_available_from,
        destination_demurrage_starting_from,
        shipment_legs_attributes,
        mbl_freight_payable,
        container_number,
      }

      await submitShipmentDataAsync(props.shipment.id, shipmentParams)
      await shipmentContainersGetDataAsync(props.shipment.id)
      onClose()
      props.fetchData()
    } catch {
      setButtonLoading(false)
    } finally {
      setButtonLoading(false)
    }
  }

  const fetchCarriers = useCallback((searchInput: string) => {
    const fetchCarriersAsync = async (input: string): Promise<any[]> => {
      if (!!input) {
        await getCarriersAsync({
          search: input.toLowerCase(),
          carrier_type: carrierType,
        })
        return store.getState().carriers.list.map((carrier) => {
          return { id: carrier.id, name: `${carrier.name} - ${carrier.scac}` }
        })
      } else {
        return []
      }
    }
    return fetchCarriersAsync(searchInput)
  }, [])

  const onChangeCarrier = useCallback((option) => {
    setCarrier(option)
  }, [])

  const onChange = (value: string | number, fieldName: string | undefined) => {
    if (!fieldName) return
    setShipmentInfo({
      ...shipmentInfo,
      ...{ [fieldName]: value },
    })
  }

  const onChangeDate = (value: any, fieldName: string) => {
    setCargoError('')
    setShipmentInfo((currentShipmentInfo) => {
      return {
        ...currentShipmentInfo,
        [fieldName]: value,
      }
    })
  }

  const onChangeLeg = useCallback(
    (
      id: number,
      name: string,
      value: string | number | boolean | null
    ): void => {
      setShowErrors(false)
      setCargoError('')
      setDeliveryAvailableError('')
      setShipmentInfo((currentShipmentInfo) => {
        const updatedLegsArr = orderBy(currentShipmentInfo.shipment_legs).map(
          (product) => {
            if ((product.element_id || product.id) === id) {
              product[name] = value
            }
            return product
          }
        )
        return {
          ...currentShipmentInfo,
          ...{ shipment_legs: [...updatedLegsArr] },
        }
      })
    },
    [shipmentInfo]
  )

  const onChangeDateStatus = (event: ChangeEvent<HTMLInputElement>) => {
    setShipmentInfo({
      ...shipmentInfo,
      closing_datetime_status: event.target.value,
    })
  }

  const onChangeIncoterm = (event: SelectChangeEvent<unknown>) => {
    setIncoterm(event.target.value as number)
  }

  const onChangeProductGroup = (event: SelectChangeEvent<unknown>) => {
    setProductGroup(event.target.value as number)
  }

  const onFreightPayableChange = (event: SelectChangeEvent<unknown>) => {
    setShipmentInfo({
      ...shipmentInfo,
      ...{ mbl_freight_payable: event.target.value as string },
    })
  }

  const shipmentInfoTable = (): React.ReactNode => {
    return (
      <MuiTable>
        <TableHead>
          <TableRow>
            <TableCell>
              {t('common.forms.fields.port_of_loading.label', 'POL')}*
            </TableCell>
            <TableCell>
              {t('common.forms.fields.etd.label', 'ETD')}
              {`${props.shipment.visibility_only ? '' : '*'}`}
            </TableCell>
            <TableCell>
              {t('common.forms.fields.port_of_discharge.label', 'POD')}*
            </TableCell>
            <TableCell>
              {t('common.forms.fields.eta.label', 'ETA')}
              {`${props.shipment.visibility_only ? '' : '*'}`}
            </TableCell>
            <TableCell>
              {getTranslationForModality(modality, 'vessel_name')}
            </TableCell>
            {shipmentInfo.modality === ModalityEnum.Sea && (
              <TableCell>{t('common.voyage_number', 'Voyage')}</TableCell>
            )}
            <TableCell className="remove-block" />
          </TableRow>
        </TableHead>
      </MuiTable>
    )
  }

  const shipmentAdditionalInfo = (): React.ReactNode => {
    const carrierLabel = getTranslationForModality(modality, 'carrier')
    const blNumberLabel = getTranslationForModality(modality, 'bl_number')
    return (
      <>
        <div className="shipment-edit-window--title">
          <Typography
            variant="h5"
            children={t(
              'edit_shipment.headings.shipment_information',
              'Shipment information'
            )}
          />
        </div>
        <div className="shipment-edit-window--additional-info shipment-edit-window--block">
          <div className="field-block" data-testid="shipment-freight-payable">
            <InputLabel htmlFor="mbl_freight_payable">
              {t(
                'common.forms.fields.freight_payable.label',
                'Freight payable'
              )}
            </InputLabel>
            <SingleSelect
              id="mbl_freight_payable"
              data-testid="mbl-freight-payable"
              placeholder={t('common.placeholders.select', 'Select')}
              onChange={onFreightPayableChange}
              options={freightPayableOptions.map((option) => ({
                id: option,
                label: t(
                  `common.forms.fields.freight_payable.options.${option}`,
                  option
                ),
              }))}
              MenuProps={{ disablePortal: true }}
              value={freightPayableOptions.find(
                (v) => v === shipmentInfo.mbl_freight_payable
              )}
            />
          </div>

          <div className="field-block" data-testid="shipment-incoterm">
            <InputLabel htmlFor="incoterm">
              {t('common.forms.fields.incoterm.label', 'Incoterm')}
            </InputLabel>
            <SingleSelect
              id="incoterm"
              data-testid="incoterm"
              placeholder={t('common.placeholders.select', 'Select')}
              displayEmpty
              MenuProps={{ disablePortal: true }}
              onChange={onChangeIncoterm}
              options={incoterms}
              value={incoterm}
            />
          </div>
          {props.shipment.has_reefer_containers && (
            <div className="field-block" data-testid="shipment-product-group">
              <InputLabel htmlFor="productGroup">
                {t('common.forms.fields.product_group.label', 'Product group')}
              </InputLabel>
              <SingleSelect
                id="product-group"
                data-testid="product-group"
                placeholder={t('common.placeholders.select', 'Select')}
                displayEmpty
                MenuProps={{ disablePortal: true }}
                onChange={onChangeProductGroup}
                options={productGroupsOptions}
                value={productGroup}
              />
            </div>
          )}
          <div
            className="field-block"
            data-testid="shipment-delivery-available-from"
          >
            <InputLabel htmlFor="incoterm">
              {t(
                'common.forms.fields.delivery_available_from.label',
                'Delivery available from '
              )}
            </InputLabel>
            <DatePicker
              data-testid="shipment-delivery-available-from-input"
              value={shipmentInfo.delivery_available_from}
              error={!!deliveryAvailableError}
              onChange={(date: DateTime | null) => {
                setDeliveryAvailableError('')
                setShipmentInfo((currentShipmentInfo) => {
                  return {
                    ...currentShipmentInfo,
                    ...{
                      delivery_available_from: date?.toISO() || null,
                    },
                  }
                })
              }}
            />
            {!!deliveryAvailableError && (
              <div className="error-message">{deliveryAvailableError}</div>
            )}
          </div>
          <div
            className="field-block"
            data-testid="shipment-cargo-closing-datetime"
          >
            <InputLabel htmlFor="cargo_closing_date_time">
              {t(
                'common.forms.fields.cargo_closing_date_time.label',
                'Cargo closing date'
              )}
            </InputLabel>
            <DatePicker
              placeholder="DD-MM-YYYY HH:MM"
              inputFormat="dd-MM-yyyy HH:mm"
              closeOnSelect={false}
              value={shipmentInfo.closing_datetime}
              error={!!cargoError}
              clearable={false}
              onChange={(date: DateTime | null) => {
                onChangeDate(date?.toISO() || null, 'closing_datetime')
              }}
              data-testid="shipment-cargo-closing-datetime-picker"
            />
            {!!cargoError && <div className="error-message">{cargoError}</div>}
          </div>
          <div className="field-block" data-testid="shipment-status">
            <InputLabel htmlFor="status">
              {t(
                'common.forms.fields.cargo_closing_status.label',
                'Cargo closing status'
              )}
            </InputLabel>
            <RadioGroup
              className="shipment-edit-window--radio-group"
              aria-label="cargo_closing_status"
              name="cargo_closing_status"
              defaultValue={shipmentInfo.closing_datetime_status}
              onChange={onChangeDateStatus}
            >
              {cargoButtons.map((value) => (
                <FormControlLabel
                  key={value}
                  value={value}
                  control={<Radio />}
                  label={t(
                    `common.forms.fields.cargo_closing_status.options.${value}`,
                    value
                  )}
                />
              ))}
            </RadioGroup>
          </div>
        </div>
        <div className="shipment-edit-window--title">
          <Typography
            variant="h6"
            className="ml-10"
            children={t(
              'edit_shipment.headings.tracking_information',
              'Tracking information'
            )}
          />
          <Typography variant="subtitle1" className="ml-10 mt-8">
            {t(
              'edit_shipment.subtitles.tracking_information',
              'Please provide at least 1 tracking key (Booking, MBL or Container number)'
            )}
          </Typography>
        </div>

        <div className="shipment-edit-window--additional-info">
          <div data-testid="shipment-carrier">
            <AutoCompleteSelect
              id="carrier"
              label={carrierLabel}
              getData={fetchCarriers}
              onChange={onChangeCarrier}
              value={carrier}
              placeholder={carrierLabel}
            />
          </div>
          <div data-testid="shipment-booking-number">
            <CustomInput
              name="booking_number"
              label={t(
                'common.forms.fields.booking_number.label',
                'Booking number'
              )}
              onChange={onChange}
              value={shipmentInfo.booking_number || ''}
              placeholder={t(
                'common.forms.fields.booking_number.placeholder',
                'Booking number'
              )}
              data-testid="shipment-booking-number-input"
              error={trackingKeyErrors.booking_number.length}
              helperText={trackingKeyErrors.booking_number.join(', ')}
            />
          </div>
          <div data-testid="shipment-bl-number">
            <CustomInput
              name="bl_number"
              label={blNumberLabel}
              onChange={onChange}
              value={shipmentInfo.bl_number || ''}
              placeholder={blNumberLabel}
              data-testid="shipment-bl-number-input"
              error={trackingKeyErrors.bl_number.length}
              helperText={trackingKeyErrors.bl_number.join(', ')}
            />
          </div>
          <div data-testid="shipment-container-number">
            {props.shipment.load_type === 'lcl' ? (
              <CustomInput
                name="container_number"
                label={t(
                  'common.forms.fields.container_number.label',
                  'Container number'
                )}
                onChange={onChange}
                value={shipmentInfo.container_number || ''}
                placeholder={t(
                  'common.forms.fields.container_number.placeholder',
                  'Container number'
                )}
                data-testid="shipment-container-number-input"
              />
            ) : (
              <>
                <InputLabel sx={{ mb: 0.5 }}>
                  {t(
                    'common.forms.fields.container_number.label',
                    'Container number'
                  )}
                </InputLabel>
                {t(
                  'edit_shipment.add_container_number_not_available',
                  'Please enter the container number in the “Containers” section of this page'
                )}
              </>
            )}
          </div>
        </div>
      </>
    )
  }

  const addNewShipmentLeg = useCallback((): void => {
    setShipmentInfo((currentShipmentInfo) => {
      const updatedLegsArr = [
        ...orderBy(shipmentInfo.shipment_legs),
        {
          element_id: uuidv4(),
          loading_port_id: null,
          loading_port: null,
          discharge_port_id: null,
          discharge_port: null,
          vessel_id: null,
          vessel: null,
          voyage_flight_no: null,
          estimated_departure: new Date().toISOString(),
          estimated_arrival: new Date().toISOString(),
        },
      ]
      return {
        ...currentShipmentInfo,
        shipment_legs: [...updatedLegsArr],
      } as IShipmentFormData
    })
  }, [shipmentInfo])

  const removeShipmentLeg = useCallback(
    (id: number): void => {
      setShipmentInfo((currentShipmentInfo) => {
        const updatedLegsArr = map(
          currentShipmentInfo.shipment_legs,
          (product) => {
            if ((product.element_id || product.id) === id) {
              product._destroy = true
            }
            return product
          }
        )

        return {
          ...currentShipmentInfo,
          ...{ shipment_legs: [...updatedLegsArr] },
        }
      })
    },
    [shipmentInfo]
  )

  const filteredLegs = (legs) => {
    return filter(legs, (leg) => !leg._destroy)
  }

  return (
    <Modal.Window open={props.open} onClose={onClose}>
      <Modal.Title
        children={t('common.buttons.edit_shipment', 'Edit shipment')}
        onClose={onClose}
      />
      <Modal.Content>
        <div
          className="shipment-edit-window--block"
          data-testid="shipment-edit-window-content"
        >
          <Typography
            variant="h5"
            children={getTranslationForModality(modality, 'legs')}
          />
          <div className="shipment-edit-window--table-header">
            {shipmentInfoTable()}
          </div>
          {filteredLegs(shipmentInfo.shipment_legs).map((product, index) => {
            return (
              <ShipmentLegLine
                key={index}
                leg={product}
                legId={product.element_id || product.id}
                onChangeLeg={onChangeLeg}
                legsLength={filteredLegs(shipmentInfo.shipment_legs).length}
                removeShipmentLeg={removeShipmentLeg}
                shipmentType={shipmentInfo.modality}
                showErrors={showErrors}
                isVisibility={props.shipment.visibility_only}
              />
            )
          })}
          <div className="shipment-edit-window--add-line">
            <Link
              variant="body1"
              component="button"
              onClick={addNewShipmentLeg}
            >
              <AddRoundedIcon />
              {t('common.buttons.add_shipment_leg', 'Add shipment leg')}
            </Link>
          </div>
        </div>
        {shipmentAdditionalInfo()}
      </Modal.Content>
      <Modal.Actions>
        <div data-testid="shipment-edit-window-actions">
          <Button
            variant="text"
            color="primary"
            onClick={onClose}
            size="medium"
            children={t('common.buttons.cancel', 'Cancel')}
          />
          <LoadingButton
            loading={buttonLoading}
            variant="contained"
            onClick={onSave}
            size="medium"
            children={t('common.buttons.save', 'Save')}
            disabled={buttonLoading}
            id="shipment-edit-window-save-button"
            data-testid="save-button"
          />
        </div>
      </Modal.Actions>
    </Modal.Window>
  )
}

export default EditShipmentWindow
