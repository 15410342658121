import { useState, useEffect, SyntheticEvent, useMemo } from 'react'
import { find } from 'lodash'
import { useDispatch } from 'react-redux'
import { history } from 'src/shyppleStore'
import { useTranslation } from 'react-i18next'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import { EmptyState } from 'src/stories'
import LoadableContainer from 'src/components/LoadableContainer'
import { getYourOpenTasks, getAllOpenTasks } from '../../stores/actionCreators'
import { promisifyAction } from '../../utils'
import { TaskTab } from './interfaces'
import TaskRow from './TaskRow'
import { taskTab, tabs, PathNames } from './constants'
import { ITab } from './Tasks.props'

const TasksContent = ({
  isOpen,
  onClickTask,
  contentWidth,
  contentHeight = 'auto',
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [tasks, setTasks] = useState<ITask[] | null>(null)

  const translatedTabs = useMemo(() => {
    return tabs(t)
  }, [t])

  const defaultTabValue = translatedTabs[0].value

  const getYourOpenTasksAsync = promisifyAction(dispatch, getYourOpenTasks)
  const getAllOpenTasksAsync = promisifyAction(dispatch, getAllOpenTasks)

  const [tab, setTab] = useState<TaskTab>(defaultTabValue as TaskTab)

  useEffect(() => {
    if (!isOpen) return
    const controller = new AbortController()
    const fetchDataAsync = async () => {
      try {
        const response =
          tab === defaultTabValue
            ? await getYourOpenTasksAsync(controller.signal)
            : await getAllOpenTasksAsync(controller.signal)
        setTasks(response)
      } catch (error) {
        setTasks(null)
      }
    }
    fetchDataAsync()
    return () => {
      controller.abort()
    }
  }, [isOpen, tab])

  const onTaskSettingsClick = () => {
    onClickTask()
    history.push(`${PathNames.Account}${PathNames.Notifications}`)
  }

  const onTabChange = (
    _event: SyntheticEvent<Element, Event>,
    value: TaskTab
  ) => {
    setTab(value)
    setTasks(null)
  }

  const currentTab: ITab = find(translatedTabs, { value: tab }) ?? tabs[0]

  return (
    <>
      <Tabs value={tab} variant="fullWidth" onChange={onTabChange}>
        {translatedTabs.map((tabItem) => (
          <Tab
            classes={taskTab}
            key={tabItem.value}
            label={tabItem.label}
            value={tabItem.value}
          />
        ))}
      </Tabs>
      <Divider />
      <Stack
        sx={{ width: `${contentWidth}px`, overflow: 'hidden', flexGrow: 1 }}
        divider={<Divider orientation="horizontal" flexItem />}
      >
        <Box overflow="auto" height={contentHeight}>
          {tasks && (
            <>
              {tasks.length > 0 &&
                tasks.map((task: ITask) => (
                  <TaskRow task={task} key={task.id} onClick={onClickTask} />
                ))}

              {tasks.length === 0 && (
                <Box
                  sx={{ width: `${contentWidth}px` }}
                  data-testid="navigation-tasks-empty-state"
                >
                  <EmptyState {...currentTab.emptyState} />
                </Box>
              )}
            </>
          )}
          {!tasks && (
            <Box p={2} data-testid="navigation-tasks-loader">
              <LoadableContainer loading={!tasks} />
            </Box>
          )}
        </Box>
      </Stack>
      <Divider />
      <Link
        sx={{ p: 2, width: '100%' }}
        component="button"
        variant="body1"
        onClick={onTaskSettingsClick}
      >
        {t(
          'top_navigation.tasks.settings_button',
          'Task and notification settings'
        )}
      </Link>
    </>
  )
}

export default TasksContent
