import LoginHeader from 'src/components/LoginHeader'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { promisifyAction } from 'src/utils'
import { userSignIn } from 'src/stores/actionCreators'
import { Link, useHistory } from 'react-router-dom'
import {
  Paper,
  Typography,
  Box,
  Link as MuiLink,
  Button,
  Alert,
  IconButton,
} from '@mui/material'
import CustomInput from 'src/components/Common/Input/MuiInput'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ExpiredPasswordAlert from './ExpiredPasswordAlert'

const Login = () => {
  const [errorMessage, setErrorMessage] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [passwordExpired, setPasswordExpired] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const signInRequest = promisifyAction(dispatch, userSignIn)

  const handleUnauthorizedError = (error) => {
    setErrorMessage('')
    const devError = error.response?.data?.dev_error ?? ''
    if (devError === 'EXPIRED_PASSWORD') {
      return setPasswordExpired(true)
    }
    if (devError === 'INACTIVE_ACCOUNT') {
      return setErrorMessage(error.response?.data?.error)
    }
    setErrorMessage(t('login.error.invalid_email_or_password'))
  }

  const onSubmit = (event) => {
    event.preventDefault()
    const formElements = event.target.elements
    const email = formElements.namedItem('email').value.trim()
    const password = formElements.namedItem('password').value
    setUserEmail(email)
    setPasswordExpired(false)
    signInRequest(email, password).catch((error) => {
      if (error.response?.status === 401) {
        handleUnauthorizedError(error)
      } else {
        history.push('/500')
      }
    })
  }

  const endAdornment = (
    <IconButton
      onClick={() => setShowPassword(!showPassword)}
      size="large"
      data-testid="button-show-password"
    >
      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
    </IconButton>
  )

  return (
    <>
      <LoginHeader />
      <Box
        sx={{
          width: '100vw',
          height: 'calc(100vh - 56px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Paper
          sx={{
            width: '360px',
            p: 4,
          }}
        >
          <form onSubmit={onSubmit}>
            <Typography sx={{ mb: 3 }} variant="h5">
              {t('login.title', 'Login')}
            </Typography>
            <CustomInput
              data-testid="emailInput"
              sx={{ mb: 2 }}
              required
              name="email"
              label={t('login.form.email.title', 'Email')}
              placeholder={t('login.form.email.placeholder', 'Email')}
            />
            <CustomInput
              data-testid="passwordInput"
              sx={{ mb: 2 }}
              required
              name="password"
              type={showPassword ? 'text' : 'password'}
              label={t('login.form.password.title', 'Password')}
              placeholder={t('login.form.password.placeholder', 'Password')}
              endAdornment={endAdornment}
            />
            {passwordExpired && <ExpiredPasswordAlert email={userEmail} />}
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}
            >
              <MuiLink component={Link} variant="body1" to="/forgot-password">
                {t('login.forgot_password_link', 'Forgot password')}
              </MuiLink>
              <Button
                type="submit"
                variant="contained"
                data-testid="login-button"
              >
                {t('login.login_button', 'Login')}
              </Button>
            </Box>
          </form>
        </Paper>
        <Box sx={{ width: 300, height: 54, mt: 2 }}>
          {errorMessage && (
            <Alert
              className="login-page--shaking-animation"
              data-testid="login-error"
              variant="filled"
              color="error"
              severity="error"
            >
              {errorMessage}
            </Alert>
          )}
        </Box>
      </Box>
    </>
  )
}

export default Login
