import { FunctionComponent, useState, useEffect, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import usePreAlert from 'src/hooks/usePreAlert'
import Dropzone from 'react-dropzone'
import { find, toNumber, each, orderBy, flatten, uniqBy } from 'lodash'
import { Trans, useTranslation } from 'react-i18next'
import { Button, Typography, Link, Box } from '@mui/material'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { CreateBookingOverviewButton } from 'src/components/Booking/ShipmentBookingBody/CreateBookingOverviewButton'
import { ShipmentCostsTransactionItemsSkeleton } from 'src/components/ShipmentCosts/ShipmentCostsTransactionItemsSkeleton'
import { LoadTypeEnum } from 'src/config/constants'
import ConfirmDialog from 'src/components/ConfirmDialog'
import DataLoad from 'src/components/Common/DataLoad'
import { promisifyAction, permissionTo } from '../../utils'
import './styles.scss'

import {
  shipmentDocumentsGetData,
  shipmentDocumentsClean,
  shipmentDocumentSubmitData,
  deleteShipmentDocument,
  createShipmentDocument,
  shipmentOverviewGetData,
  documentTypesGetData,
  bookingsGetData,
  shipmentsGetParties,
  shipmentDocumentsOpenItem,
  toggleShipmentShareModal,
} from '../../stores/actionCreators'
import ShipmentDocumentsFcl from './ShipmentDocumentsFcl'
import ShipmentDocumentsLcl from './ShipmentDocumentsLcl'

const allowedFileTypes = [
  '.jpeg',
  '.jpg',
  '.png',
  '.bmp',
  '.pdf',
  '.xls',
  '.xlsx',
  '.doc',
  '.docx',
  '.tiff',
  '.tif',
  '.gif',
  '.txt',
  '.csv',
  '.eml',
  '.msg',
  '.rtf',
  '.xlsm',
  '.xlsb',
]

const areFilesValid = (files: File[]) => {
  return files.every((file) =>
    allowedFileTypes.some((type) =>
      file.name.toLocaleLowerCase().includes(type)
    )
  )
}

interface IProps {
  match?: IMatch | null
}

const ShipmentDocuments: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()

  const fileValidationErrorMessage = t(
    'shipment_documents.notifications.invalid_file_format',
    {
      defaultValue: 'The file must be a file of type: {{formats}}',
      formats: allowedFileTypes.join(', '),
    }
  )

  const match = props.match || { params: { id: 0 } }
  const dispatch = useDispatch()
  const uploadButtonRef = useRef<any>(null)
  const [busy, setBusy] = useState<boolean>(true)

  const [filesArr, setFilesArr] = useState<File[]>([])
  const [sortValue] = useState<string>('')
  const [sortDirection] = useState<'asc' | 'desc'>('desc')
  const [editableDocument, setEditableDocument] = useState<IFileData[]>([])
  const [
    currentDocument,
    setCurrentDocument,
  ] = useState<IShipmentDocument | null>(null)
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false)
  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false)
  const [isDropzoneActive, setIsDropzoneActive] = useState<boolean>(false)
  const isPreAlert = usePreAlert()

  const shipmentDocumentsGetDataAsync = promisifyAction(
    dispatch,
    shipmentDocumentsGetData
  )

  const shipmentPartiesGetDataAsync = promisifyAction(
    dispatch,
    shipmentsGetParties
  )
  const createShipmentDocumentAsync = promisifyAction(
    dispatch,
    createShipmentDocument
  )
  const shipmentOverviewGetDataAsync = promisifyAction(
    dispatch,
    shipmentOverviewGetData
  )
  const updateShipmentDocumentAsync = promisifyAction(
    dispatch,
    shipmentDocumentSubmitData
  )
  const shipmentDocumentsCleanAsync = promisifyAction(
    dispatch,
    shipmentDocumentsClean
  )
  const bookingsGetDataAsync = promisifyAction(dispatch, bookingsGetData)
  const documentTypesGetDataAsync = promisifyAction(
    dispatch,
    documentTypesGetData
  )
  const deleteShipmentDocumentAsync = promisifyAction(
    dispatch,
    deleteShipmentDocument
  )

  const showSuccess = (message: string, duration?: number) => {
    dispatch(showNotification({ message, severity: 'success' }))
  }
  const showError = (message: string, duration?: number) => {
    dispatch(showNotification({ message, severity: 'error' }))
  }

  const {
    shipmentDocumentsData,
    documentTypes,
    bookings,
    containers,
    parties,
    organizationId,
    shipment,
    isFCL,
  } = useSelector((state: IGlobalState) => ({
    organizationId: state.user.organizationId,
    shipmentDocumentsData: state.shipmentDocs.shipmentDocumentsData,
    documentTypes: state.shipmentDocs.documentTypes,
    bookings: state.bookings.bookings,
    containers: state.bookings.containers,
    parties: state.shipmentOverview.collaborators || [],
    shipment: state.shipmentOverview,
    isFCL: state.shipmentOverview.load_type === LoadTypeEnum.fcl,
  }))

  const showPublicLink: boolean =
    permissionTo('shipments.public_link.all') &&
    !!shipment &&
    !!shipment.token_enabled &&
    !!shipment.token

  const dataRequests = () => {
    let actionsToPerform = [
      shipmentDocumentsGetDataAsync(match.params.id),
      shipmentPartiesGetDataAsync(match.params.id),
      documentTypesGetDataAsync(shipment.modality),
    ]

    if (permissionTo('shipments.bookings.view')) {
      actionsToPerform.push(bookingsGetDataAsync(match.params.id))
    }

    return actionsToPerform
  }

  useEffect(() => {
    Promise.all(dataRequests()).then(() => {
      setBusy(false)
    })
  }, [])

  useEffect(() => {
    return () => {
      shipmentDocumentsCleanAsync()
    }
  }, [])

  useEffect(() => {
    return () => {
      dispatch(shipmentDocumentsOpenItem(null))
    }
  }, [])

  const leadForwarder = useMemo(() => {
    return parties.find((x) => x.roles.some((x) => x.id === 17))
  }, [parties])

  const sortedDocuments = useMemo(() => {
    const sortValuesArr: string[] = [sortValue]
    const sortDirectionArr: Array<'asc' | 'desc'> = [sortDirection]
    if (sortValue && sortValue !== 'created_at') {
      sortValuesArr.push('created_at')
      sortDirectionArr.push('desc')
    }
    const values = (shipmentDocumentsData.shipment_documents || []).map((x) => {
      const newTypes = x.types.map((x) => x.name).join(', ')
      x.type_names = newTypes
      return x
    })
    return orderBy(values, sortValuesArr, sortDirectionArr)
  }, [sortValue, shipmentDocumentsData, sortDirection])

  const modalClose = () => {
    setEditableDocument([])
    setCurrentDocument(null)
    setFilesArr([])
    setIsConfirmOpen(false)
    setIsUploadModalOpen(false)
  }

  const getFileData = (): IFileData[] => {
    const viewable_by = [`${organizationId}`]
    if (leadForwarder) {
      viewable_by.push(`${leadForwarder.organization_id}`)
    }

    return (filesArr || []).map((sub: File) => {
      return {
        file: sub,
        name: sub.name,
        types: [],
        containers: [],
        booking: '',
        viewable_by,
      }
    })
  }

  const confirmDeleteDocument = async (): Promise<any> => {
    try {
      await deleteShipmentDocumentAsync(
        match.params.id,
        currentDocument && currentDocument.id
      )
      await shipmentDocumentsGetDataAsync(match.params.id)
      await shipmentOverviewGetDataAsync(match.params.id)
      modalClose()
    } catch (error) {
      dispatch(
        showNotification({
          message: t(
            'shipment_documents.notifications.document_can_not_be_deleted',
            "Document can't be deleted."
          ),
          severity: 'error',
        })
      )
    }
  }

  const onDrop = (files: File[]): void => {
    if (areFilesValid(files)) {
      setFilesArr(files)
      setIsUploadModalOpen(true)
    } else {
      dispatch(
        showNotification({
          message: fileValidationErrorMessage,
          severity: 'error',
        })
      )
    }
    setIsDropzoneActive(false)
  }

  const documentTypesSet = useMemo((): IDocType[] => {
    if (!shipmentDocumentsData.shipment_documents?.length) {
      return documentTypes
    }

    const appliedDocumentTypes: IDocType[] = flatten(
      shipmentDocumentsData.shipment_documents.map((x) => x.types)
    )

    return uniqBy([...documentTypes, ...appliedDocumentTypes], 'id')
  }, [documentTypes, shipmentDocumentsData.shipment_documents])

  const onEditDocument = (id: number): void => {
    const doc: any =
      find(shipmentDocumentsData.shipment_documents, { id: id }) || null
    if (doc) {
      setEditableDocument([
        {
          name: doc.original_filename,
          types: doc.types.map((x) => `${x.id}`),
          containers: doc.containers.map((x) => `${x.id}`),
          booking: doc.booking ? `${doc.booking.id}` : '',
          viewable_by: doc.viewable_by.map((x) => `${x.id}`),
        },
      ])
      setCurrentDocument(doc)
      setIsUploadModalOpen(true)
    }
  }

  const onDeleteDocument = (id: number): void => {
    const doc: any =
      find(shipmentDocumentsData.shipment_documents, { id: id }) || null
    setIsConfirmOpen(true)
    setCurrentDocument(doc)
  }

  const addNewDocument = () => {
    uploadButtonRef.current.click()
  }

  const onChangeUploadFile = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (event.target.files) {
      const files = Array.from(event.target.files)

      if (areFilesValid(files)) {
        setFilesArr(files)
        setIsUploadModalOpen(true)
      } else {
        dispatch(
          showNotification({
            message: fileValidationErrorMessage,
            severity: 'error',
          })
        )
      }
    }

    uploadButtonRef.current.value = null
  }

  const fetchAfterSave = async (openNew) => {
    modalClose()
    await shipmentDocumentsGetDataAsync(match.params.id)
    await shipmentOverviewGetDataAsync(match.params.id)
    if (openNew) {
      addNewDocument()
    }
  }

  const saveDocuments = async (
    documents: IFileData[],
    isEditDocument: boolean,
    openNew: boolean
  ): Promise<any> => {
    if (isEditDocument) {
      const editedFile = {
        document_type_ids: documents[0].types,
        booking_id: documents[0].booking,
        container_ids: documents[0].containers,
        organization_ids: documents[0].viewable_by,
      }
      await updateShipmentDocumentAsync(
        toNumber(match.params.id),
        currentDocument?.id,
        editedFile
      )
    } else {
      each(documents, (document: IFileData) => {
        if (!document.file) return
        const reader = new FileReader()
        reader.readAsDataURL(document.file)
        reader.onload = async () => {
          try {
            await createShipmentDocumentAsync(match.params.id, {
              file: document.file,
              document_type_ids: document.types,
              booking_id: document.booking,
              container_ids: document.containers,
              organization_ids: document.viewable_by,
            })
            showSuccess(
              t(
                'shipment_documents.notifications.new_document_uploaded',
                'New document uploaded'
              )
            )
          } catch (error) {
            const unknownError: any = error
            if (unknownError?.response?.data) {
              showError(unknownError.response.data.message)
            }
          }
        }
      })
    }
    fetchAfterSave(openNew)
  }

  const onDragEnter = (): void => {
    setIsDropzoneActive(true)
  }

  const onDragLeave = (): void => {
    setIsDropzoneActive(false)
  }

  const openShareModal = () => dispatch(toggleShipmentShareModal(true, null))

  const addDocumentsBlock = () => {
    return (
      <div className="shipment-docs-page--add-docs-block">
        <div>
          <Typography variant="h3">
            {t('shipment_documents.title', 'Your shipment documents')}
          </Typography>
          <Typography variant="body1">
            <Trans
              i18nKey="shipment_documents.description"
              defaults="Drag and drop anywhere in this tab or <0>browse</0> your files."
              components={[<Link onClick={addNewDocument} />]}
            />
          </Typography>
        </div>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap-reverse',
            justifyContent: 'end',
          }}
        >
          {isPreAlert && showPublicLink && (
            <Button
              variant="outlined"
              onClick={openShareModal}
              className="mr-8 mb-8"
            >
              {t('common.buttons.send_pre_alert', 'Send pre-alert')}
            </Button>
          )}
          <CreateBookingOverviewButton shipmentId={shipmentDocumentsData.id} />
          <Button
            variant="contained"
            className="ml-8 mb-8"
            onClick={addNewDocument}
          >
            {t('common.buttons.add_documents', 'Add documents')}
          </Button>
        </Box>
      </div>
    )
  }

  const getContainerOrBookingId = (type: string): number | null => {
    switch (type) {
      case 'container':
        if (containers && containers.length === 1) {
          return containers[0] ? containers[0].id : null
        }
        return null
      case 'booking':
        if (bookings && bookings.length === 1) {
          return bookings[0] ? bookings[0].id : null
        }
        return null
      default:
        return null
    }
  }

  return (
    <div className="shipment-docs-page" data-testid="shipment-documents">
      <style
        dangerouslySetInnerHTML={{
          __html: `.shipment-layout__content {
                      padding: 0;
                     }`,
        }}
      />
      <Dropzone
        onDrop={onDrop}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        multiple={true}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()} className="relative">
              {isDropzoneActive && (
                <div className="shipment-docs-page__documents-overlay">
                  <i className="icon folder" />
                  <div className="shipment-docs-page__documents-overlay--title">
                    {t(
                      'shipment_documents.dropzone.release_your_documents',
                      'Release your documents'
                    )}
                  </div>
                  <div className="shipment-docs-page__documents-overlay--text">
                    {t(
                      'shipment_documents.dropzone.add_files',
                      'Add files by dropping them in this window'
                    )}
                  </div>
                </div>
              )}
              {permissionTo('shipments.documents.upload') &&
                addDocumentsBlock()}
              <Box px={2}>
                {!busy && !isFCL && (
                  <ShipmentDocumentsLcl
                    onDeleteDocument={onDeleteDocument}
                    onEditDocument={onEditDocument}
                    sortedDocuments={sortedDocuments}
                    shipmentDocumentsData={shipmentDocumentsData}
                    showPublicLink={showPublicLink}
                  />
                )}
                {!busy && isFCL && (
                  <ShipmentDocumentsFcl
                    onDeleteDocument={onDeleteDocument}
                    onEditDocument={onEditDocument}
                    sortedDocuments={sortedDocuments}
                    shipmentDocumentsData={shipmentDocumentsData}
                    showPublicLink={showPublicLink}
                  />
                )}
                {busy && <ShipmentCostsTransactionItemsSkeleton />}
              </Box>
              <input
                {...getInputProps()}
                ref={uploadButtonRef}
                type="file"
                id="selectedFile"
                style={{
                  display: 'none',
                }}
                accept={allowedFileTypes.toString()}
                onChange={onChangeUploadFile}
                multiple={true}
              />
            </div>
          </section>
        )}
      </Dropzone>
      <ConfirmDialog
        title={t('shipment_documents.delete_document.title', 'Delete document')}
        message={t(
          'shipment_documents.delete_document.message',
          'Do you really want to delete this document?'
        )}
        confirmButtonText={t('common.buttons.yes', 'Yes')}
        rejectButtonText={t('common.buttons.no', 'No')}
        isOpen={isConfirmOpen}
        confirm={confirmDeleteDocument}
        reject={modalClose}
        onClose={modalClose}
      />
      <DataLoad.UploadFiles
        shipment={true}
        title="documents"
        isEditDocument={!!editableDocument.length}
        fileData={editableDocument.length ? editableDocument : getFileData()}
        isOpen={isUploadModalOpen}
        documentTypes={documentTypesSet}
        containers={shipmentDocumentsData.containers}
        bookings={bookings}
        onClose={modalClose}
        submitUpload={saveDocuments}
        parties={parties}
        bookingId={getContainerOrBookingId('booking')}
        containerId={getContainerOrBookingId('container')}
      />
    </div>
  )
}

export default ShipmentDocuments
