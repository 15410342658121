import { Fragment, useMemo } from 'react'
import { flatten } from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useTranslation } from 'react-i18next'
import ShipmentCostsTransactionItemsAlert from './ShipmentCostsTransactionItemsAlert'
import { ShipmentCostsItemsTableProps } from './props'

export const ShipmentCostsItemsTable: React.FC<ShipmentCostsItemsTableProps> = ({
  rows,
  totals,
  grouping = 'service',
  showContainer = false,
}) => {
  const { t } = useTranslation()
  const allRows = useMemo(() => flatten(rows.map((row) => row.innerRows)), [
    rows,
  ])
  const showAlert = allRows.every(
    (transactionItem) => transactionItem.currency === 'EUR'
  )
  const isGroupedByService = grouping === 'service'

  const serviceItemHeader = [
    {
      width: 'auto',
      text: t('shipment_costs.table_header.service_item', 'Service Item'),
      key: 'name',
    },
  ]
  const containerHeader = showContainer
    ? [
        {
          width: 'auto',
          text: t('shipment_costs.table_header.container', 'Container'),
          key: 'container',
        },
      ]
    : []

  const groupBasedHeaders = isGroupedByService
    ? [...serviceItemHeader, ...containerHeader]
    : [...containerHeader, ...serviceItemHeader]

  const headers = [
    ...groupBasedHeaders,
    {
      width: '160px',
      text: t('shipment_costs.table_header.quantity', 'Quantity'),
      key: 'quantity',
    },
    {
      width: '160px',
      text: t('shipment_costs.table_header.unit_price', 'Unit Price'),
      key: 'unitPrice',
    },
    {
      width: '160px',
      text: t('shipment_costs.table_header.total_usd', 'Total USD'),
      key: 'totalUsd',
    },
    {
      width: '160px',
      text: t('shipment_costs.table_header.total_eur', 'Total EUR'),
      key: 'totalEur',
    },
  ]
  return (
    <>
      <Table size="medium" aria-label="Service items">
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell key={header.key} sx={{ width: header.width }}>
                {header.text}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className="shipment-costs-item-inner-table-body">
          {rows.map((row, index) => (
            <Fragment key={`row_group_${index}`}>
              <TableRow data-testid={`shipment-costs-item-row-${index + 1}`}>
                <TableCell
                  colSpan={showContainer ? 6 : 5}
                  sx={(theme) => ({
                    backgroundColor: theme.palette.grey[50],
                  })}
                >
                  <Typography variant="body1Strong" children={row.group} />
                </TableCell>
              </TableRow>
              {row.innerRows.map((item, innerIndex) => (
                <TableRow
                  key={item.id}
                  data-testid={`shipment-costs-item-row-inner-${index + 1}-${
                    innerIndex + 1
                  }`}
                >
                  {isGroupedByService && (
                    <>
                      <TableCell>{item.name}</TableCell>
                      {showContainer && (
                        <TableCell>
                          <Box>{item.container}</Box>
                        </TableCell>
                      )}
                    </>
                  )}
                  {!isGroupedByService && (
                    <>
                      {showContainer && <TableCell>{item.container}</TableCell>}
                      <TableCell>
                        <Box>{item.name}</Box>
                      </TableCell>
                    </>
                  )}
                  <TableCell>
                    <Box
                      display="flex"
                      sx={{ justifyContent: 'space-between' }}
                    >
                      <Box>{item.quantity}</Box>
                      <Box>x</Box>
                    </Box>
                  </TableCell>
                  <TableCell>{`${item.unitPrice} ${item.currency}`}</TableCell>
                  <TableCell>
                    {`${item.totalUsd} ${t('common.currencies.usd', 'USD')}`}
                  </TableCell>
                  <TableCell>
                    {`${item.totalEur} ${t('common.currencies.eur', 'EUR')}`}
                  </TableCell>
                </TableRow>
              ))}
            </Fragment>
          ))}
          <TableRow>
            <TableCell colSpan={showContainer ? 3 : 2}>&nbsp;</TableCell>
            <TableCell>
              <Typography
                variant="body1Strong"
                children={t('shipment_costs.table.total', 'Total')}
                component="span"
              />
              {t('shipment_costs.table.excl_vat', '(excl. VAT)')}
            </TableCell>
            <TableCell>
              <Typography
                variant="body1Strong"
                children={`${totals.usd} ${t('common.currencies.usd', 'USD')}`}
              />
            </TableCell>
            <TableCell>
              <Typography
                variant="body1Strong"
                children={`${totals.eur} ${t('common.currencies.eur', 'EUR')}`}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {!showAlert && <ShipmentCostsTransactionItemsAlert />}
    </>
  )
}

export default ShipmentCostsItemsTable
