import { Link as MuiLink } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff'
import CenteredLayout from 'src/pages/Layout/Centered'

const TokenExpired = () => {
  const { t } = useTranslation()
  return (
    <CenteredLayout
      icon={HistoryToggleOffIcon}
      title={t('reset_password_link_expired.title')}
      description={t('reset_password_link_expired.description')}
    >
      <MuiLink component={Link} to="/login" variant="body1" data-testid="link">
        {t('common.actions.back_to_login', 'Back to Login')}
      </MuiLink>
    </CenteredLayout>
  )
}

export default TokenExpired
