import { FunctionComponent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { ModalityEnum } from 'src/config/constants'
import { convertDatePickerToUniversalFormat } from '../../../utils/helpers'
import { permissionTo } from '../../../utils'
import { Counter } from '../../../stories/Counter'
import './styles.scss'

interface IProps {
  booking: IBooking
  collapsed?: boolean
  shipmentModality: ModalityEnum
  openCargoDetails?: (id: number) => void
  openBookingModal: (booking: IBooking | null) => void
  openPartiesModal: (booking: IBooking | null, type: string) => void
}

const ShipmentBookingHeader: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const { cargo_ready_date, shipper } = props.booking

  const onModalOpen = (): void => {
    props.openBookingModal(props.booking)
  }

  const openPartiesModal = (): void => {
    props.openPartiesModal(props.booking, 'shipper')
  }

  const addCargoBlock: React.ReactNode = (
    <>
      {permissionTo('shipments.bookings.manage') ? (
        <Link onClick={onModalOpen}>
          {t('shipments.bookings.add_cargo', 'Add')}
        </Link>
      ) : (
        '-'
      )}
    </>
  )

  const addShipperBlock: React.ReactNode = (
    <Link onClick={openPartiesModal}>
      {t('shipments.bookings.add_shipper', 'Add')}
    </Link>
  )

  const addBLBlock: React.ReactNode = (
    <>
      {permissionTo('shipments.bookings.manage') &&
      permissionTo('shipments.manage') ? (
        <Link onClick={onModalOpen}>
          {t('shipments.bookings.add_house_bl', 'Add')}
        </Link>
      ) : (
        '-'
      )}
    </>
  )

  const actionRequredCount = useMemo(() => {
    return props.booking.milestones.filter(
      (x) => !x.reached && x.status !== 'upcoming'
    ).length
  }, [props.booking.milestones])

  return (
    <div className="container-overview-header--collapsed">
      <div className="container-info cargo">
        <div className="container-info--title">
          <Typography
            variant="body1Strong"
            children={t('shipments.bookings.parties.shipper', 'Shipper')}
          />
        </div>
        <div className="container-info--name">
          {shipper.shipment_address ? (
            <Typography
              variant="body1"
              children={shipper.shipment_address.name}
            />
          ) : (
            addShipperBlock
          )}
        </div>
      </div>
      <div className="container-info ">
        <div className="container-info--title">
          <Typography
            variant="body1Strong"
            children={t(
              'shipments.bookings.booking_reference_label',
              'Booking reference'
            )}
          />
        </div>
        <div
          className="container-info--name"
          data-testid="booking-reference-value"
        >
          {props.booking.booking_reference ? (
            <Typography children={props.booking.booking_reference} />
          ) : (
            addCargoBlock
          )}
        </div>
      </div>
      <div className="container-info ">
        <div className="container-info--title">
          <Typography
            variant="body1Strong"
            children={t('shipments.bookings.cargo_ready', 'Cargo ready')}
          />
        </div>
        <div>
          {cargo_ready_date ? (
            <Typography
              data-testid="booking-cargo-ready-date-value"
              children={convertDatePickerToUniversalFormat(cargo_ready_date)}
            />
          ) : (
            addCargoBlock
          )}
        </div>
      </div>
      <div className="container-info number">
        <div className="container-info--title">
          <Typography
            variant="body1Strong"
            children={t('shipments.bookings.tasks', 'Tasks')}
          />
        </div>
        <Counter
          value={actionRequredCount}
          className={actionRequredCount ? 'red' : 'green'}
        />
      </div>
      <div className="container-info house">
        <div className="container-info--title">
          <Typography
            data-testid="booking-house-bl-value"
            variant="body1Strong"
            children={
              props.shipmentModality === 'air'
                ? t('common.modality_air.house_bl', 'House AWB')
                : t('common.modality_sea.house_bl', 'House BL')
            }
          />
        </div>
        <div
          className="paragraph__medium black"
          data-testid="booking-house-bl-value"
        >
          {props.booking.house_bl ? (
            <Typography children={props.booking.house_bl} />
          ) : (
            addBLBlock
          )}
        </div>
      </div>
      <div className="block-arrow">
        <i className={`icon chevron ${props.collapsed ? 'collapsed' : ''}`} />
      </div>
    </div>
  )
}

export default ShipmentBookingHeader
