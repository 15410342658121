import { TableCell, Tooltip } from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const ShipmentDocumentRowActionsDocumentTypes = ({ documentTypes }) => {
  const { t } = useTranslation()
  const documentTypesText = useMemo(
    () =>
      documentTypes
        .map((x) => t(`document_types.types.${x.code}`, x.name))
        .join(', '),
    [documentTypes, t]
  )
  return (
    <TableCell data-testid="shipment-documents-list-item-types">
      <Tooltip title={documentTypesText} placement="top">
        <span>{documentTypesText}</span>
      </Tooltip>
    </TableCell>
  )
}

export default ShipmentDocumentRowActionsDocumentTypes
