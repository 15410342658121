import {
  ShipmentQuotationStatusEnum,
  ShipmentQuotationStatus,
} from 'src/config/constants'

import {
  maxBy,
  minBy,
  find,
  isUndefined,
  lowerCase,
  groupBy,
  map,
  sortBy,
} from 'lodash'

import { Rows } from './props'

export const getTransactionItemsSum = (
  items: ITransactionItem[],
  key: string
) => {
  return items
    .reduce((sum: number, item) => {
      sum += Number(item[key])
      return sum
    }, 0)
    .toFixed(2)
}

export const getDateDisplayProps = (
  quoteExpired: boolean,
  status: ShipmentQuotationStatusEnum
) => {
  if (status === ShipmentQuotationStatusEnum.Open && quoteExpired) {
    return ShipmentQuotationStatus.expired.date
  }
  return ShipmentQuotationStatus[status].date
}

export const getExtremes = (quotations: IShipmentQuotation[]) => {
  const fastest =
    minBy(quotations, (o) => {
      return o.transit_time
    })?.id ?? ''

  const cheapest =
    minBy(quotations, (o) => {
      return parseFloat(o.costs_total_eur)
    })?.id ?? ''

  const longest =
    maxBy(quotations, (o) => {
      return o.transit_time
    })?.id ?? ''

  return { fastest, cheapest, longest }
}

export const getTransactionItemGroups = (
  transactionItems: ITransactionItem[],
  group: 'service' | 'container' = 'service'
) => {
  const isGroupedByService = group === 'service'
  const groupedTransactions = groupBy(
    transactionItems,
    (item) => `${item.seller_organization.id}_${item.buyer_organization.id}`
  )
  const innerItemSorterForContainerGroup = (item: ITransactionItem) => [
    item?.container?.container_index,
    item.service_item.service_priority,
  ]
  const innerItemSorterForServiceGroup = (item: ITransactionItem) =>
    item?.service_item.priority

  const innerItemSorter = isGroupedByService
    ? innerItemSorterForServiceGroup
    : innerItemSorterForContainerGroup

  const mapParties = map(groupedTransactions, (items) => {
    const internallyGrouped = isGroupedByService
      ? groupBy(items, (innerItem) => innerItem.service_item.service_name)
      : groupBy(
          items,
          (innerItem) =>
            innerItem?.container?.number ??
            innerItem?.container?.container_index ??
            '-'
        )

    const rows = map(internallyGrouped, (innerItems, key) => {
      return {
        group: key,
        innerRows: sortBy(innerItems, innerItemSorter).map((item) => {
          return {
            id: item.id,
            currency: item.currency,
            quantity: item.quantity,
            totalUsd: item.total_usd,
            totalEur: item.total_eur,
            unitPrice: item.unit_price,
            name: item.service_item.name,
            container:
              item?.container?.number ??
              item?.container?.container_index ??
              null,
          }
        }),
      }
    })

    return {
      parties: [
        {
          label: 'Seller',
          ...items[0].seller_organization,
        },
        {
          label: 'Buyer',
          ...items[0].buyer_organization,
        },
      ],
      totals: {
        usd: getTransactionItemsSum(items, 'total_usd'),
        eur: getTransactionItemsSum(items, 'total_eur'),
      },
      rows,
    }
  })
  return mapParties
}

export const getRevenueItemRows = (revenueItems: IRevenue[]) => {
  return revenueItems.map((revenue: IRevenue) => {
    return {
      group: revenue.name,
      innerRows: revenue.items.map((item, index) => {
        return {
          quantity: item.quantity,
          currency: item.currency,
          unitPrice: item.unit_price,
          name: item.service_item_name,
          totalUsd: item.total_value_usd,
          totalEur: item.total_value_eur,
          id: `${index}_${revenue.name}_${item.service_item_name}`,
        }
      }),
    }
  }) as Rows[]
}

export const getCollaboratorWithLeadForwarderRole = (
  collaborators: IShipmentParty[]
): undefined | IShipmentParty => {
  return find(
    collaborators,
    (collaborator) =>
      !isUndefined(
        find(
          collaborator.roles,
          (item) => lowerCase(item.role) === 'lead forwarder'
        )
      )
  )
}
