import { useFormContext } from 'react-hook-form'
import AsyncPorts from 'src/components/Common/AutocompleteWrappers/AsyncPorts'
import { RenderedAutocompleteProps } from 'src/components/Common/AutocompleteWrappers/types'
import { searchParams } from '../../constants'
import AutocompleteBase from '../AutocompleteBase'

const AutocompleteAsync = ({ token, onDelete }) => {
  const name = token.value === searchParams.pol ? 'pol' : 'pod'

  const { getValues } = useFormContext()
  const defaultValue = getValues(token.value) ?? []

  const handleDelete = () => {
    onDelete(token.value)
  }

  return (
    <AsyncPorts
      name={name}
      defaultValue={defaultValue}
      WrappedAutocompleteComponent={(props: RenderedAutocompleteProps) => (
        <AutocompleteBase
          token={token}
          asynchronous={true}
          popperMaxWidth={450}
          onDelete={handleDelete}
          options={props.options}
          loading={props.isFetching}
          defaultValue={defaultValue}
          onSelectChange={props.onChange}
          noOptionsText={props.noOptionsText}
          onInputChange={props.onInputChange}
        />
      )}
    />
  )
}

export default AutocompleteAsync
