import { useEffect, useState, useMemo } from 'react'
import {
  Checkbox,
  Typography,
  CircularProgress,
  Box,
  ClickAwayListener,
  Popper,
} from '@mui/material'
import Autocomplete, {
  AutocompleteCloseReason,
  autocompleteClasses,
} from '@mui/material/Autocomplete'
import { toLower } from 'lodash'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import SearchIcon from '@mui/icons-material/Search'
import Input from 'src/components/Common/Input/MuiInput'
import { StyledInput } from '../../Lab/Autocomplete/AutocompleteCommon.styles'

import AutocompleteCommonToggleEndAdornment from '../../Lab/Autocomplete/AutocompleteCommonToggleEndAdornment'

const icon = <CheckBoxOutlineBlankIcon />
const checkedIcon = <CheckBoxIcon />

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    borderRadius: '0 0px 5px 5px',
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: 'white',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      height: '40px',
      alignItems: 'center',
      padding: '0px 12px',
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}))

function PopperComponent(props) {
  const { disablePortal, anchorEl, open, ...other } = props
  return <StyledAutocompletePopper {...other} />
}

const CustomerPopper = (props) => {
  const modifiers = [
    {
      name: 'flip',
      options: {
        fallbackPlacements: [],
      },
    },
  ]

  return (
    <Popper
      {...props}
      modifiers={modifiers}
      popperOptions={{
        placement: 'bottom',
      }}
      sx={{ width: props?.anchorEl?.offsetWidth }}
    />
  )
}

const StyledPopper = styled(CustomerPopper)(({ theme }) => ({
  boxShadow: `0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)`,
  borderRadius: 5,
  zIndex: theme.zIndex.modal,
  backgroundColor: 'white',
}))

export const AutoComplete = (props) => {
  const {
    name,
    options,
    label,
    value,
    optionsKey,
    multiple,
    onSearch,
    onChange,
    placeholder = null,
  } = props

  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    let done = false

    if (!done && onSearch) {
      setLoading(true)
      const search = async () => {
        await onSearch(inputValue)
        setLoading(false)
      }
      search()
    }

    return () => {
      done = true
    }
  }, [inputValue])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
  }

  const onClearClicked = (event: { stopPropagation: () => void }) => {
    event.stopPropagation()
    onChange([])
  }

  const open = Boolean(anchorEl)
  const id = open ? 'github-label' : undefined
  const selectInputValue =
    value.length === 0
      ? placeholder ?? `Select ${toLower(name)}`
      : value.map((selected) => selected.label).join(', ')

  const matchingResults = () => (
    <Box
      mt={2}
      mb={2}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <CircularProgress variant="indeterminate" />
      <Typography variant="body1" children="Matching results" />
    </Box>
  )

  const noOptions = (
    <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Typography variant="body1" children="We couldn’t find any results" />
    </Box>
  )

  const countLabel = useMemo(() => {
    const counter = value.length > 0 ? `(${value.length})` : ''
    return `${label} ${counter}`
  }, [value])

  const checkOptionValue = (option, value): boolean => {
    return !!(
      option &&
      value &&
      option[optionsKey || 'value'] === value[optionsKey || 'value']
    )
  }

  return (
    <Box>
      <Input
        data-testid="watch-shipment-rule-name"
        label={label ? countLabel : ''}
        className="input-field"
        value={selectInputValue}
        onClick={handleClick}
        readOnly
        endAdornment={
          <AutocompleteCommonToggleEndAdornment
            open={open}
            onClick={onClearClicked}
            hasValue={value && value.length > 0}
          />
        }
      />
      <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom">
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Autocomplete
              open
              multiple={multiple}
              onClose={(
                event: React.ChangeEvent<{}>,
                reason: AutocompleteCloseReason
              ) => {
                if (reason === 'escape') {
                  handleClose()
                }
              }}
              value={value}
              onChange={(event, newValue, reason) => {
                if (
                  event.type === 'keydown' &&
                  (event as React.KeyboardEvent).key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return
                }
                onChange(newValue)
              }}
              disableCloseOnSelect
              PopperComponent={PopperComponent}
              renderTags={() => null}
              noOptionsText={!loading && noOptions}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    sx={{ marginRight: 1, ml: -1 }}
                    checked={selected}
                  />

                  <Box>
                    <Typography variant="body1" children={option.label} />
                  </Box>
                </li>
              )}
              options={loading ? [] : options}
              getOptionLabel={(option) => option.label}
              inputValue={inputValue}
              isOptionEqualToValue={checkOptionValue}
              onInputChange={(_event, newValue: string, reason) => {
                if (reason === 'input') {
                  setInputValue(newValue)
                }
              }}
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder={t('common.search_placeholder', 'Search')}
                  data-testid="autocomplete-search-input"
                  startAdornment={<SearchIcon sx={{ color: 'grey.500' }} />}
                />
              )}
            />
            {loading && matchingResults()}
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </Box>
  )
}
