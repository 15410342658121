import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { t } from 'i18next'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { userUpdateUserData } from 'src/stores/actionCreators'
import { promisifyAction } from 'src/utils'

const ShipmentCostsTransactionItemsFclGroupsSelect = ({
  onClick,
  defaultGrouping,
}) => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [grouping, setGrouping] = useState<string>(defaultGrouping)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const updateCurrentUser = promisifyAction(dispatch, userUpdateUserData)
  const handleItemClick = (group: string) => {
    setGrouping(group)
    handleClose()
    onClick(group)
    updateCurrentUser({ costs_tab_grouping: group })
  }

  const groups = [
    {
      label: t('shipment_costs.groups.service', 'Service'),
      value: 'service',
    },
    {
      label: t('shipment_costs.groups.container', 'Container'),
      value: 'container',
    },
  ]

  return (
    <div>
      <Button
        aria-haspopup="true"
        onClick={handleClick}
        id="costs-grouping-button"
        data-testid="costs-grouping-button"
        aria-expanded={open ? 'true' : undefined}
        aria-controls={open ? 'costs-grouping-menu' : undefined}
        endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      >
        {grouping === 'service'
          ? t('shipment_costs.groups.group_by_service', 'Group by service')
          : t('shipment_costs.groups.group_by_container', 'Group by container')}
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        id="costs-grouping-menu"
        PaperProps={{
          elevation: 8,
          sx: {
            minWidth: '165px',
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'costs-grouping-button',
        }}
      >
        {groups.map((group) => (
          <MenuItem
            key={group.value}
            selected={grouping === group.value}
            onClick={() => handleItemClick(group.value)}
          >
            {group.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default ShipmentCostsTransactionItemsFclGroupsSelect
