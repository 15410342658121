import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Avatar from '@mui/material/Avatar'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import VisibilityIcon from '@mui/icons-material/Visibility'
import FlagIcon from '@mui/icons-material/Flag'
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined'
import Typography from '@mui/material/Typography'
import { Link as MuiLink } from '@mui/material'
import { Link } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import { groupBy } from 'lodash'
import { FunctionComponent, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import Switch from '@mui/material/Switch'
import { showNotification } from 'src/stores/actionCreators/notifications'
import {
  toggleFlag,
  toggleWatch,
  getYourOpenTasks,
} from '../../stores/actionCreators'
import { promisifyAction, permissionTo } from '../../utils'
import './styles.scss'

type Props = {
  shipmentId?: number
  watchersCount?: number
  watched?: boolean
  parties: Array<ICollaborator | IShipmentParty>
  groupByValue: string
  children: React.ReactNode
  withFlag?: boolean
}

type GroupedParties = Array<Array<ICollaborator | IShipmentParty>>

const ShipmentCollaboratorsPopper: FunctionComponent<Props> = ({
  shipmentId,
  watchersCount,
  watched,
  parties,
  groupByValue,
  children,
  withFlag,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [open, setOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | undefined>()

  const toggleShipmentFlagAsync = promisifyAction(dispatch, toggleFlag)
  const toggleWatchAsync = promisifyAction(dispatch, toggleWatch)

  const showPopper = useCallback((event) => {
    anchorEl !== event.currentTarget && setAnchorEl(event.currentTarget)
    setOpen(true)
  }, [])

  const hidePopper = useCallback(() => {
    setOpen(false)
  }, [])

  const toggleWatchCallback = useCallback(async () => {
    try {
      const successMessage = watched
        ? t(
            'shipment_details.collaborators.notifications.toggle_watched.watched.success',
            "You stopped watching this shipment. We won't notify you about activity on it."
          )
        : t(
            'shipment_details.collaborators.notifications.toggle_watched.unwatched.success',
            "You're watching this shipment. We'll notify you when there is activity on it."
          )
      await toggleWatchAsync(shipmentId)
      dispatch(getYourOpenTasks())
      dispatch(
        showNotification({ message: successMessage, severity: 'success' })
      )
    } catch (error) {
      dispatch(
        showNotification({
          message: t(
            'shipment_details.collaborators.notifications.toggle_watched.error',
            "Couldn't manage shipment watching."
          ),
          severity: 'error',
        })
      )
    }
  }, [watched, shipmentId])

  const groupedParties: GroupedParties = Object.values(
    groupBy(parties, (party) => party[groupByValue])
  )

  const getPartyRoles = (groupedParty) => {
    return (groupedParty || [])
      .map((party: ICollaborator | IShipmentParty) => {
        return shipmentId
          ? ((party as IShipmentParty).roles || [])
              .map((x) => x.role)
              .join(', ')
          : party.role || party.organization_role_name
      })
      .join(', ')
  }

  return (
    <div
      onMouseOver={showPopper}
      onMouseLeave={hidePopper}
      className="shipment-collaborators"
      data-testid="shipment-details-collaborators"
    >
      {children}
      <Popper
        id="shipment-share-link-popper"
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        className="shipment-collaborators__popper mui-override"
      >
        <Paper>
          <div className="shipment-collaborators__title">
            <Typography
              variant="h3"
              children={t(
                'shipment_details.collaborators.title',
                'Watcher & collaborator settings'
              )}
            />
            {shipmentId ? (
              <>
                <div className="shipment-collaborators__title-watchers mb-10">
                  <div className="shipment-collaborators__title-wrapper">
                    <Typography
                      variant="h5"
                      children={t(
                        'shipment_details.collaborators.shipment_watchers',
                        'Shipment watchers'
                      )}
                    />
                    <span className="ml-15 watchers-counter">
                      <VisibilityIcon />
                      <Trans
                        i18nKey="shipment_details.collaborators.watchers"
                        defaults="<0>{{count}}</0>watchers"
                        values={{ count: watchersCount }}
                        components={[<span className="ml-5 mr-5" />]}
                      />
                    </span>
                  </div>
                  <div className="shipment-collaborators__desc-wrapper">
                    <Switch checked={watched} onChange={toggleWatchCallback} />
                    <Trans
                      i18nKey="shipment_details.collaborators.watch_to_receive_notifications"
                      defaults="<0>Watch shipment to receive</0><1>email notifications</1>"
                      components={[
                        <Typography variant="body1" />,
                        <MuiLink
                          variant="body1"
                          component={Link}
                          to="/account"
                        />,
                      ]}
                    />
                  </div>
                </div>
                {permissionTo('shipments.shipment_collaborators.view') && (
                  <div className="shipment-collaborators__title-collabs">
                    <Typography
                      variant="h5"
                      children={t(
                        'shipment_details.collaborators.collaborators.title',
                        'Shipment collaborators'
                      )}
                    />
                    <Typography
                      variant="body1"
                      children={t(
                        'shipment_details.collaborators.collaborators.description',
                        'Flag the shipment for you or your collaborators to indicate that it is important.'
                      )}
                    />
                  </div>
                )}
              </>
            ) : (
              ''
            )}
          </div>
          {(permissionTo('shipments.shipment_collaborators.view') ||
            !shipmentId) && (
            <Table className="shipment-collaborators__table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t(
                      'shipment_details.collaborators.collaborators.table.name',
                      'name'
                    )}
                  </TableCell>
                  <TableCell>
                    {t(
                      'shipment_details.collaborators.collaborators.table.role',
                      'role'
                    )}
                  </TableCell>
                  {withFlag && (
                    <TableCell className="short right-aligned">
                      {t(
                        'shipment_details.collaborators.collaborators.table.flag_for',
                        'flag for'
                      )}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {(groupedParties || []).map((groupedParty: any, index) => {
                  const party = groupedParty[0] as IShipmentParty
                  return (
                    <TableRow
                      key={index}
                      data-testid="shipment-details-collaborator-row"
                    >
                      <TableCell>
                        <div className="shipment-collaborators__table--cell">
                          <Avatar
                            alt={party.name}
                            src={party.logo || 'no-logo'}
                            className={
                              party.organization_role_code || party.role_code
                            }
                          />
                          <div className="ml-10 bold party-name">
                            {' '}
                            {party.name}{' '}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>{getPartyRoles(groupedParty)}</TableCell>
                      {withFlag ? (
                        <TableCell
                          className="short"
                          sx={{ textAlign: 'right' }}
                        >
                          <div
                            className="flag-container"
                            onClick={toggleShipmentFlagAsync.bind(
                              null,
                              shipmentId,
                              {
                                organization_id:
                                  groupedParty[0].organization_id,
                              }
                            )}
                          >
                            <div className="hover-text paragraph__small grey">
                              {(groupedParty[0] as IShipmentParty).flagged_at
                                ? 'Unflag'
                                : 'Flag'}
                            </div>

                            {(groupedParty[0] as IShipmentParty).flagged_at ? (
                              <FlagIcon color="primary" />
                            ) : (
                              <FlagOutlinedIcon />
                            )}
                          </div>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          )}
        </Paper>
      </Popper>
    </div>
  )
}

export default ShipmentCollaboratorsPopper
