import { FunctionComponent, useCallback } from 'react'
import { isEmpty } from 'lodash'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import { useTranslation } from 'react-i18next'
import './styles.scss'

interface IProps {
  title: string
  type: string
  class: string
  company: IShipmentAddress
  contact: IContactItem
  openModal: (type: string) => void
  updateNotifyWithConsignee?: () => void
  consigneeAsNotifyParty?: boolean
  permission: boolean
  theme?: string
  'data-testid'?: string
}

const BookingPartiesBlock: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const openModal = useCallback((event: any): void => {
    if (event.target.type === 'checkbox' || !props.permission) {
      return
    }
    props.openModal(props.type)
  }, [])

  const handleCheckNotify = useCallback((): void => {
    if (props.updateNotifyWithConsignee) {
      props.updateNotifyWithConsignee()
    }
  }, [props])

  const renderCompanyInfo = (): React.ReactNode => {
    return (
      <div className="booking__parties__block__content__company">
        <div className="booking__parties__block__content__company--name">
          <Typography
            variant="body1Strong"
            noWrap
            children={props.company.name}
          />
        </div>
        <div className="booking__parties__block__content__company--details">
          <Typography variant="body1" children={props.company.address} />
        </div>
        <div className="booking__parties__block__content__company--details">
          <Typography variant="body1" className="text-wrapper">
            {props.company.postal_code} {props.company.city}
          </Typography>
        </div>
        <div className="booking__parties__block__content__company--details">
          <Typography variant="body1" children={props.company.country.name} />
        </div>
      </div>
    )
  }

  const renderContactInfo = (): React.ReactNode => {
    return (
      <div className="booking__parties__block__content__contact">
        <div className="booking__parties__block__content__company--name">
          <Typography
            variant="body1Strong"
            noWrap
            children={props.contact.name}
          />
        </div>
        <div className="booking__parties__block__content__company--details">
          <Typography variant="body1" noWrap children={props.contact.email} />
        </div>
        <div className="booking__parties__block__content__company--details">
          <Typography variant="body1" children={props.contact.phone} />
        </div>
      </div>
    )
  }

  const renderEmptyBlock = (): React.ReactNode => {
    return (
      <div className="booking__parties__block__content__emptyBlock">
        <div className="booking__parties__block__content__emptyBlock--icon">
          <i className="icon close" />
        </div>
      </div>
    )
  }

  return (
    <div
      className={`booking__parties__block ${props.class} ${props.theme} ${
        !props.permission ? 'blocked' : ''
      }`}
      onClick={openModal}
      data-testid={props['data-testid']}
    >
      <div className="booking__parties__block__title">
        <Typography variant="body1Strong" children={props.title} />
        <div>
          {props.type === 'consignee' && (
            <label className="booking__parties__block__checkbox-group">
              <Checkbox
                checked={props.consigneeAsNotifyParty}
                onChange={handleCheckNotify}
                disabled={!props.permission}
                data-testid="notify-party-checkbox"
              />
              <Typography
                variant="body1"
                children={t(
                  'shipments.bookings.parties.also_notify_party',
                  'Also notify party'
                )}
              />
            </label>
          )}
        </div>
      </div>
      <div className="booking__parties__block__content">
        {!isEmpty(props.company) && renderCompanyInfo()}
        {!isEmpty(props.contact) && renderContactInfo()}
        {isEmpty(props.company) && isEmpty(props.contact) && renderEmptyBlock()}
      </div>
    </div>
  )
}

export default BookingPartiesBlock
