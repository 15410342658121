import { CHAT_TYPES } from 'src/config/constants'
import { convertDateToRelative } from 'src/utils/helpers'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Link as MuiLink,
  Typography,
  TypographyProps,
} from '@mui/material'
import './NotificationsSavedMessage.scss'
import { useState } from 'react'
import NotificationsContentListItemAttachments from './NotificationsContentListItemAttachments'

export const LinkToChat = ({
  comment,
  variant = 'body1',
  children = null,
}: {
  comment: IComment | INavigationNotification
  variant?: TypographyProps['variant']
  children?: React.ReactNode
}) => (
  <MuiLink
    sx={children ? { textDecoration: 'none' } : {}}
    variant={variant}
    href={`/shipments/${comment.shipment_id}/chats/${comment.chat_type}#${comment.id}`}
  >
    {children ?? `${CHAT_TYPES[comment.chat_type]} ${comment.shipment_title}`}
  </MuiLink>
)

const NotificationSavedMessage = ({
  comment,
  onCommentRemove,
}: {
  comment: IComment
  onCommentRemove: (comment: IComment) => void
}) => {
  const [className, setClassName] = useState<string>('')
  const { t } = useTranslation()
  return (
    <>
      <Box
        m={2}
        className={`notifications__saved-message ${className}`}
        data-testid="saved-message"
        onAnimationEnd={() => onCommentRemove(comment)}
      >
        <Typography variant="body1Strong">
          {comment.author.full_name} | {comment.author.organization.name}
        </Typography>
        <br />
        <LinkToChat comment={comment} />
        <LinkToChat comment={comment}>
          <Box
            className="message-center-item-message"
            mt="12px"
            mb="12px"
            sx={{ backgroundColor: 'var(--grey-A100) !important' }}
          >
            {comment.parent_comment && (
              <div className="message-center-item-reply">
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${comment.parent_comment.author}: ${comment.parent_comment.content}`,
                  }}
                />
              </div>
            )}
            <span dangerouslySetInnerHTML={{ __html: comment.content }} />
            <NotificationsContentListItemAttachments
              attachments={comment.comment_attachments}
            />
          </Box>
        </LinkToChat>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1">
            {convertDateToRelative(comment.created_at)}
          </Typography>
          <MuiLink
            variant="body1"
            component="button"
            data-testid="remove-from-saved"
            onClick={() => setClassName('notifications__saved-message--remove')}
          >
            {t(
              'top_navigation.notifications.saved_comments.remove_link',
              'Remove from saved'
            )}
          </MuiLink>
        </Box>
      </Box>
    </>
  )
}

export default NotificationSavedMessage
