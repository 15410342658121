import { TFunction } from 'i18next'
import SelectableChip from 'src/components/SelectableChip'
import { ChipProps, Box, Zoom } from '@mui/material'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import DoneIcon from '@mui/icons-material/Done'
import primary from 'src/theme/colors/light/primary'
import { permissionTo } from 'src/utils'
import {
  containerTypes,
  customsInspectionTypes,
  podInspectionStatusColors,
} from './constants'

export const getContainerStatusChipColor = (
  status: string | null,
  podCustomsInspectionStatus: string | null
) => {
  if (podCustomsInspectionStatus) {
    return podInspectionStatusColors[podCustomsInspectionStatus] ?? 'default'
  }
  if (status) {
    return containerTypes[status]?.color ?? 'default'
  }
  return 'default'
}

export const getContainerStatusChipIcon = (
  isServiceRequested: boolean,
  status: string | null,
  podCustomsInspectionStatus: string | null
) => {
  if (
    podCustomsInspectionStatus === 'notified' ||
    podCustomsInspectionStatus === 'released'
  ) {
    return <QrCodeScannerIcon color="inherit" />
  }

  if (isServiceRequested) {
    return <LocalShippingIcon sx={{ color: `${primary[900]} !important` }} />
  }

  return <ArrowForwardIcon />
}

export const getContainerStatusText = (
  isServiceRequested: boolean,
  state: { status: string | null; type: string | null },
  service: string,
  t: TFunction<'translation', undefined>
) => {
  const isDelivery = service === 'delivery'

  if (state.status === 'released') {
    return t(
      'transports.table.container_status.released',
      'Released from customs inspection'
    )
  }

  if (state.status === 'notified') {
    return `${t(
      'transports.table.container_status.notified',
      'Customs inspection:'
    )} ${
      customsInspectionTypes(t).find((type) => type.id === state.type)?.label
    }`
  }

  if (isServiceRequested) {
    return isDelivery
      ? t(
          'transports.table.container_status.delivery_in_progress',
          'We are taking care of your delivery'
        )
      : t(
          'transports.table.container_status.pickup_in_progress',
          'We are taking care of your pick-up'
        )
  }

  return isDelivery
    ? t(
        'transports.table.container_status.delivery_not_requested',
        'Delivery not requested with us'
      )
    : t(
        'transports.table.container_status.pickup_not_requested',
        'Pick-up not requested with us'
      )
}

export const containerStatusChip = (
  statusText: string | null,
  status: string | null,
  isServiceRequested: boolean,
  podCustomsInspectionStatus: string | null,
  isCopied: boolean,
  clickOnCopy: () => void
) => {
  const icon = getContainerStatusChipIcon(
    isServiceRequested,
    status,
    podCustomsInspectionStatus
  )
  const color = getContainerStatusChipColor(
    status,
    podCustomsInspectionStatus
  ) as ChipProps['color']

  return (
    <Box
      display="inline"
      className="container-block"
      data-testid="container-block-with-chip"
      onClick={(e) => {
        e.stopPropagation()
        navigator.clipboard?.writeText(statusText || '')
        clickOnCopy()
      }}
    >
      <SelectableChip
        variant="filled"
        disabled={true}
        fixedWidth={true}
        value={{
          icon,
          color,
          name: statusText ? `${statusText}` : `-`,
        }}
      />

      <Zoom in={!isCopied}>
        <FileCopyOutlinedIcon
          className="copy-container-icon"
          color={color as any}
        />
      </Zoom>
      <Zoom in={isCopied}>
        <DoneIcon className="copy-container-icon" color={color as any} />
      </Zoom>
    </Box>
  )
}

export const canBulkEdit = () => {
  return permissionTo([
    'pickups_deliveries.shypple_admin',
    'pickups_deliveries.full_manage',
    'pickups_deliveries.limited_manage',
  ])
}

export const canUpdateTransport = () => {
  return permissionTo([
    'pickups_deliveries.shypple_admin',
    'pickups_deliveries.full_manage',
  ])
}

export const canUpdatePickupAndDeliveryTime = (row) => {
  return (
    permissionTo([
      'pickups_deliveries.full_manage',
      'pickups_deliveries.shypple_admin',
    ]) ||
    (permissionTo('pickups_deliveries.limited_manage') &&
      row.pickup_delivery_time_editable)
  )
}
