import { FunctionComponent, useMemo, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import CostManagementDialog from 'src/pages/ShipmentPrice/ShipmentCostManagementDialog'
import CancelShipmentModal from 'src/components/CancelShipmentModal'
import ShipmentCostsQuote from 'src/components/ShipmentCostsQuote'
import { ShipmentCostsTransactionItems } from 'src/components/ShipmentCosts/ShipmentCostsTransactionItems'
import { promisifyAction, permissionTo } from '../../utils'
import useGoBack from '../../hooks/useGoBack'

import { shipmentTransactionItemsGetData } from '../../stores/actionCreators'

import './styles.scss'

export interface IBuyer {
  id: number
  logo: string | null
  name: string
  role_code: string
}

const ShipmentsPrice: FunctionComponent<{}> = () => {
  const { t } = useTranslation()
  const [cancelDialogOpen, setCancelDialogOpen] = useState<boolean>(false)
  const [isCostManagementDialogOpen, setCostManagementDialogOpen] = useState<
    boolean
  >(false)
  const [transactionsGroup, editTransactionsGroup] = useState<{
    buyer: IBuyer
    items: ITransactionItem[]
  } | null>(null)
  const dispatch = useDispatch()
  const goBack = useGoBack()
  const params = useParams<{ id: string }>()

  const showShipmentCostManagementDialog = (
    buyer: IShipmentOrganization | IShipmentParty,
    items: ITransactionItem[]
  ) => {
    const { name, logo } = buyer
    if ('organization_id' in buyer) {
      editTransactionsGroup({
        buyer: {
          id: buyer.organization_id,
          role_code: buyer.organization_role_code,
          name,
          logo,
        },
        items,
      })
    } else {
      editTransactionsGroup({
        buyer,
        items,
      })
    }
    setCostManagementDialogOpen(true)
  }

  const shipmentTransactionItemsGetDataAsync = promisifyAction(
    dispatch,
    shipmentTransactionItemsGetData
  )

  const { status, currentUser, loadType, shipmentCollaborators } = useSelector(
    (state: IGlobalState) => ({
      transactionItems: state.shipmentPrice.transactionItems,
      transactions: state.shipmentPrice.transactions,
      showOnlyOriginalCurrency: state.shipmentPrice.showOnlyOriginalCurrency,
      status: state.shipmentOverview.status,
      loadType: state.shipmentOverview.load_type,
      currentUser: state.user,
      shipmentCollaborators: state.shipmentOverview.collaborators,
    })
  )

  const isQuote = useMemo(() => status === 'QUO', [status])
  const isProposedQuote = useMemo(() => status === 'PQUO', [status])
  const isSIB = useMemo(() => status === 'SIB', [status])
  const canManageCosts: boolean = permissionTo('shipments.costs.manage')

  const closeCancelModal = useCallback(() => {
    setCancelDialogOpen(false)
  }, [setCancelDialogOpen])

  const closeShipmentCostManagementDialog = () =>
    setCostManagementDialogOpen(false)

  const loadNewTransactionItems = () =>
    shipmentTransactionItemsGetDataAsync(params.id)

  return (
    <>
      <div className="costs">
        {(isQuote || isProposedQuote || isSIB) && (
          <div className="costs__header">
            <div className="costs__header-title">
              {t('shipment_costs.costs', 'Costs')}
            </div>
            <div className="costs__header-actions">
              <ShipmentCostsQuote />
            </div>
          </div>
        )}
        <ShipmentCostsTransactionItems
          shipmentId={params.id}
          loadType={loadType}
          defaultGrouping={currentUser.costsTabGrouping}
          invoicePerContainer={currentUser.invoicePerContainer}
          shipmentCollaborators={shipmentCollaborators}
          userOrganizationId={currentUser.organizationId}
          onAddOrEditCostsClick={showShipmentCostManagementDialog}
        />
      </div>
      <CancelShipmentModal
        open={cancelDialogOpen}
        close={closeCancelModal}
        afterCancel={goBack}
        shipmentId={parseInt(params.id)}
      />
      {canManageCosts && isCostManagementDialogOpen && (
        <CostManagementDialog
          items={transactionsGroup?.items || []}
          buyer={transactionsGroup?.buyer || null}
          onClose={closeShipmentCostManagementDialog}
          onSave={loadNewTransactionItems}
        />
      )}
    </>
  )
}

export default ShipmentsPrice
