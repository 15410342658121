// @ts-nocheck
// FIXME

import {
  FunctionComponent,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'
import isEmail from 'validator/lib/isEmail'
import Typography from '@mui/material/Typography'
import MuiTable from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { toNumber, includes, find, uniq, uniqBy } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Tooltip, Box } from '@mui/material'
import LinkRoundedIcon from '@mui/icons-material/LinkRounded'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { LoadingButton } from '@mui/lab'
import Tabs from 'src/stories/Tabs'
import { showNotification } from 'src/stores/actionCreators/notifications'
import InlineNotification from 'src/components/Common/InlineNotification'
import { OrganizationWithAvatar } from '../../stories/OrganizationWithAvatar'
import { Avatar } from '../../stories/Avatar'
import DefaultAutocomplete from '../../stories/SearchBar'
import { Multiselect } from '../../stories/Multiselect'
import { permissionTo, promisifyAction } from '../../utils'
import useCopyLink from '../../hooks/useCopyLink'
import { Dialog, DialogContent } from '../../stories'
import {
  getAllConnections,
  shipmentsGetOrganizationRoles,
  shareShipmentViaEmail,
  toggleShipmentShareModal,
} from '../../stores/actionCreators'
import ShareEmailBlock from './ShareEmailBlock'

import './styles.scss'

export interface IShareData {
  to_email_addresses: string[]
  document_ids: number[]
  message: string
  subject: string
  reply_to: string
  cc: string[]
  options: { title: string; id: string }[]
}

interface IProps {
  shipmentId?: sting | undefined
  collaborators: IShipmentParty[]
  showPublicLink: boolean
  onSaveAndFetch?: (selectedConnections) => void
  open: boolean
  onClose?: () => void
  buttonLoading: boolean
  token: string
  allowManageable: boolean
  headerText: string
  isScheduleExist?: boolean
  sellerOrganization?: null | IOrganizationShort
  inlineComponent?: boolean
  forShipmentTemplates?: boolean
}

const REPLY_TO_KEY = 'shipment_share_modal_reply_to'
const EMAILS_KEY = 'shipment_share_modal_to_email_addresses'

const getLocalStorageArray = (key: string, defaultJsonValue: string = '[]') => {
  try {
    return JSON.parse(window.localStorage.getItem(key) || defaultJsonValue)
  } catch {
    window.localStorage.setItem(key, defaultJsonValue)
    return []
  }
}

const ShipmentShareModal: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const DETAILS_TABS = [
    {
      label: t('shipment_share.tabs.collaborators', 'Collaborators on Shypple'),
      value: 'collaborators',
    },
    {
      label: t('shipment_share.tabs.email', 'Share via email'),
      value: 'email',
    },
  ]
  const [parties, setParties] = useState<IShipmentParty[]>([])
  const [scope, setScope] = useState('collaborators')
  const [ccError, setCcError] = useState<string | null>(null)
  const [isChangedRole, setIsChangedRole] = useState<boolean>(false)
  const siteUrl: string = window.location.origin
  const shareLink: string = `${siteUrl}/public-shipments/${props.token}`
  const dispatch = useDispatch()
  const copyLink = useCopyLink(shareLink)

  const showSuccess = (message: string): void => {
    dispatch(showNotification({ message, severity: 'success' }))
  }

  const getConnectionsAsync = promisifyAction(dispatch, getAllConnections)
  const shipmentsGetOrganizationRolesAsync = promisifyAction(
    dispatch,
    shipmentsGetOrganizationRoles
  )
  const shareShipmentViaEmailAsync = promisifyAction(
    dispatch,
    shareShipmentViaEmail
  )

  const {
    organizationRoles,
    organizationId,
    organizationName,
    userEmail,
    displayShipmentShareModal,
    selectedDocument,
    shipmentReference,
  } = useSelector((state: IGlobalState) => ({
    shipmentReference: state.shipmentOverview.shared_reference,
    organizationRoles: state.shipments.organizationRoles,
    organizationId: state.user.organizationId,
    organizationName: state.user.organizationName,
    userEmail: state.user.email,
    displayShipmentShareModal: state.flags.displayShipmentShareModal,
    selectedDocument: state.flags.selectedDocument,
  }))

  const [shareDetails, setShareDetails] = useState<IShareData>({
    to_email_addresses: [],
    options: getLocalStorageArray(EMAILS_KEY),
    document_ids: [],
    message: '',
    subject: t('shipment_share.email_subject', {
      defaultValue:
        '{{organizationName}} has shared a shipment with you via Shypple. Ref: {{shipmentReference}}`',
      organizationName,
      shipmentReference,
    }),
    reply_to: window.localStorage.getItem(REPLY_TO_KEY) || userEmail,
    cc: [],
  })

  useEffect(() => {
    shipmentsGetOrganizationRolesAsync()
    if (displayShipmentShareModal) {
      setScope('email')
    }
  }, [])

  useEffect(() => {
    if (!shareDetails.cc.length) return setCcError(null)
    const ccs = shareDetails.cc.map((item) => isEmail(item))
    setCcError(
      uniq(ccs).length === 1 && uniq(ccs)[0]
        ? null
        : t(
            'shipment_share.validations.email_invalid',
            'One or more email addresses are invalid'
          )
    )
  }, [shareDetails.cc])

  useEffect(() => {
    setParties(props.collaborators)
  }, [props.collaborators])

  useEffect(() => {
    if (props.forShipmentTemplates) {
      props.onSaveAndFetch(parties)
    }
  }, [parties, props.forShipmentTemplates])

  useEffect(() => {
    if (selectedDocument) {
      setShareDetails({
        ...shareDetails,
        document_ids: [selectedDocument],
      })
    }
  }, [selectedDocument])

  const onClose = () => {
    setParties(props.collaborators)
    setIsChangedRole(false)
    props.onClose()
    dispatch(toggleShipmentShareModal(false, null))
  }

  const organizationRolesOptions = (name) => {
    return find(organizationRoles, (role) => role.name === name)?.shipment_roles
  }

  const selectCollaborator = useCallback(
    (data: IShipmentParty): void => {
      if (!data) return
      setIsChangedRole(true)
      setParties((currentParties) => {
        const isOrganizationInCollaborators: boolean = includes(
          (currentParties || []).map((item) => toNumber(item.organization_id)),
          data.organization_id
        )
        if (data.preferred_shipment_role_id) {
          const shipmentRoles = organizationRolesOptions(data.role)
          const roleName = find(
            shipmentRoles,
            (role) => role.id === data.preferred_shipment_role_id
          )?.name
          data.roles = [
            {
              id: data.preferred_shipment_role_id,
              role: roleName || '',
              name: roleName || '',
            },
          ]
        }
        return isOrganizationInCollaborators
          ? [...currentParties]
          : [...currentParties, data]
      })
    },
    [parties, organizationRoles]
  )

  const removeCollaborator = useCallback(
    (id) => {
      setIsChangedRole(true)
      setParties((currentParties) => {
        return currentParties.filter((value) => value.organization_id !== id)
      })
    },
    [parties]
  )

  const onSave = async () => {
    if (isEmailTab) {
      await shareShipmentViaEmailAsync(props.shipmentId, shareDetails)
      showSuccess(t('shipment_share.notifications.email_sent', 'Email sent'))
      onClose()
    } else {
      props.onSaveAndFetch(parties)
    }
  }

  const goToShareEmail = () => {
    setScope('email')
  }

  const onEmailsChange = (value: Record<string, any>) => {
    window.localStorage.setItem(EMAILS_KEY, JSON.stringify(value.options))
    setShareDetails({
      ...shareDetails,
      ...value,
    })
  }

  const onChangeData = (type, value) => {
    if ((type === 'reply_to' && isEmail(value)) || value === '') {
      window.localStorage.setItem(REPLY_TO_KEY, value)
    }
    setShareDetails({
      ...shareDetails,
      [type]: value,
    })
  }

  const onChangeRole = useCallback(
    (roleIds, id, rolesOptions) => {
      setIsChangedRole(true)
      setParties((currentParties) => {
        const updatedCollaboratorsArr = (currentParties || []).map((party) => {
          if (party.organization_id === id) {
            party.roles = (roleIds || []).map((id) => {
              return find(rolesOptions, (role) => role.id === id)
            })
          }
          return party
        })
        return [...updatedCollaboratorsArr]
      })
    },
    [parties]
  )

  const includeCondition = (parties: IShipmentParty[], id: number): boolean => {
    return includes(
      (parties || []).map((item) => toNumber(item.organization_id)),
      id
    )
  }

  const collaboratorLine = useCallback(
    (party: IShipmentParty): React.ReactNode => {
      const isCurrentOrganization: boolean =
        party.organization_id === organizationId
      const isCustomer: boolean =
        (party.organization_role_code || party.role_code) === 'customer'
      const partyRoles: number[] = (party.roles || []).map((role) =>
        toNumber(role.id)
      )
      const isMainOrganization: boolean =
        !!props.isScheduleExist &&
        !!props.sellerOrganization &&
        party.organization_id === props.sellerOrganization.id
      const rolesOptions = organizationRolesOptions(
        party.organization_role_name || party.role
      )

      const isUnManageable: boolean =
        !props.allowManageable &&
        !party.manageable &&
        includeCondition(props.collaborators, party.organization_id)

      const isDisabled =
        isCustomer ||
        isMainOrganization ||
        isCurrentOrganization ||
        isUnManageable

      return (
        <div
          key={party.organization_id}
          className="shipment-collaborators-window--line"
          data-testid={`shipment-collaborators-row-${party.organization_id}`}
        >
          <div className="collaborator-name">
            <OrganizationWithAvatar organization={party} bold={true} />
          </div>
          <div className="shipment-role">
            <div className="shipment-role--wrapper">
              <Multiselect
                name=""
                onChange={(options) => {
                  onChangeRole(options, party.organization_id, rolesOptions)
                }}
                selectedOptionKey="id"
                optionLabel="name"
                values={partyRoles || []}
                options={rolesOptions}
                placeholder={`${
                  isUnManageable || !!partyRoles.length
                    ? ''
                    : t(
                        'shipment_share.form.field.shipment_role.placeholder',
                        'Select shipment role'
                      )
                }`}
                disabled={isDisabled}
              />
            </div>
          </div>
          {isDisabled ? (
            <div className="collaborator-action">
              <Tooltip
                title={t(
                  'shipment_share.tooltips.delete_collaborator_locked',
                  "You can't remove this collaborator from the shipment because you don't have the right permissions"
                )}
                placement="top-end"
                className="shipment-collaborators-window--tooltip"
              >
                <div className="collaborator-action--title-icon">
                  <i className="icon lock" />
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    children={t('shipment_share.locked', 'Locked')}
                  />
                </div>
              </Tooltip>
            </div>
          ) : (
            <div
              className="collaborator-action remove"
              onClick={() => {
                removeCollaborator(party.organization_id)
              }}
            >
              <i className="icon trash" />
              <Typography
                className="medium light"
                children={t('common.buttons.remove', 'Remove')}
              />
            </div>
          )}
        </div>
      )
    },
    [parties, organizationRoles]
  )

  const connectionLine = (OptionText: JSX.Element, option) => {
    return (
      <div className="shipment-collaborators-window--option-line">
        <div className="avatar-block">
          <div className="avatar-block--wrapper">
            <Avatar
              alt={option.name}
              src={option.logo || 'no-logo'}
              className={option.role_code}
            />
          </div>
          <div className="name-block">{option.name}</div>
        </div>
        <div className="description">{option.role}</div>
        <div className="note-message">
          {includeCondition(parties, option.organization_id) && (
            <div className="status-message">
              {t(
                'shipment_share.collaborator_already_added',
                'Already added to shipment'
              )}
            </div>
          )}
        </div>
      </div>
    )
  }

  const collaboratorsTableHeader = (): React.ReactNode => {
    return (
      <MuiTable>
        <TableHead>
          <TableRow>
            <TableCell className="name-block">
              {t('shipment_share.organization_name', 'name of organization')}
            </TableCell>
            <TableCell className="shipment-block">
              {t('shipment_share.shipment_role', 'shipment role')}
            </TableCell>
          </TableRow>
        </TableHead>
      </MuiTable>
    )
  }

  const isDisabledSave = useMemo(() => {
    const partiesRoles: boolean[] = uniq(
      (parties || []).map((party) => {
        return party.roles && !!party.roles.length
      })
    )
    return partiesRoles.length === 1 && !!partiesRoles[0]
  }, [parties])

  const isRolesUniq = useMemo(() => {
    const partiesRoles: boolean[] = (parties || [])
      .map(
        (party) => party.roles.filter((x) => [15, 16, 17].includes(x.id)) // forwarder IDs
      )
      .flat()
    return uniqBy(partiesRoles, 'id').length === partiesRoles.length
  }, [parties])

  const getCollaborators = async (q) => {
    const result = await getConnectionsAsync({ search: q, page: 1 })
    return result.list
  }

  const isEmailTab = useMemo(() => {
    return scope === 'email'
  }, [scope])

  const collaboratorsTable = () => (
    <div
      className="shipment-collaborators-window--block"
      data-testid="shipment-collaborators-component"
    >
      {permissionTo('shipments.shipment_collaborators.manage') && (
        <div className="shipment-collaborators-window--block-search">
          <div className="shipment-collaborators-window--block-name">
            <Typography
              variant="body1Strong"
              children={
                props.forShipmentTemplates
                  ? t('shipment_share.add_collaborator', 'Add collaborator')
                  : t(
                      'shipment_share.add_collaborator_to_shipment',
                      'Add new collaborators to the shipment'
                    )
              }
            />
          </div>
          <DefaultAutocomplete
            onChange={selectCollaborator}
            value={null}
            children={connectionLine}
            placeholder={
              props.forShipmentTemplates
                ? t('shipment_share.select_organization', 'Select organization')
                : t('shipment_share.search_connections', 'Search connections')
            }
            getData={getCollaborators}
          />
        </div>
      )}
      <InlineNotification
        color="warning"
        show={!isRolesUniq}
        message={t(
          'shipment_share.notifications.role_assigned',
          'One of the roles was already assigned to another organization in this shipment.'
        )}
      />
      {collaboratorsTableHeader()}
      <div
        className="shipment-collaborators-window--lines"
        data-testid="shipment-collaborators-window-organization"
      >
        {(parties || []).map((party) => collaboratorLine(party))}
      </div>

      {!props.forShipmentTemplates && props.showPublicLink && (
        <Box
          mt={4}
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
        >
          <Typography
            variant="body1Strong"
            children={t(
              'shipment_share.headings.share_via_email',
              'Share tracking link and documents with people without a Shypple account via email'
            )}
            mb={1}
          />
          <Button
            variant="outlined"
            onClick={goToShareEmail}
            endIcon={<ArrowForwardIcon />}
            size="large"
          >
            {t('common.buttons.share_via_email', 'Share via email')}
          </Button>
        </Box>
      )}
    </div>
  )

  if (props.inlineComponent) {
    return collaboratorsTable()
  }

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      title={props.headerText}
      actions={
        <>
          {props.showPublicLink && (
            <Button
              variant="text"
              onClick={copyLink}
              startIcon={<LinkRoundedIcon />}
              size="large"
            >
              {t('common.buttons.copy_public_link', 'Copy public link')}
            </Button>
          )}
          <Button variant="outlined" onClick={onClose} size="large">
            {t('common.buttons.cancel', 'Cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            size="large"
            onClick={onSave}
            disabled={
              isEmailTab
                ? !shareDetails.to_email_addresses.length ||
                  !shareDetails.subject
                : !isChangedRole || !isDisabledSave || !isRolesUniq
            }
            loading={props.buttonLoading}
          >
            {isEmailTab
              ? t('common.buttons.share', 'Share')
              : t('common.buttons.invite', 'Invite')}
          </LoadingButton>
        </>
      }
    >
      <DialogContent>
        {props.showPublicLink ? (
          <Box>
            <Tabs
              onChange={(scope) => {
                setScope(scope)
              }}
              orientation="horizontal"
              scrollButtons="auto"
              value={scope}
              tabs={DETAILS_TABS}
              variant="standard"
            />
            {scope === 'collaborators' && collaboratorsTable()}
            {scope === 'email' && (
              <ShareEmailBlock
                shipmentId={props.shipmentId}
                onEmailsChange={onEmailsChange}
                onChangeData={onChangeData}
                data={shareDetails}
                shareLink={shareLink}
                ccError={ccError}
                userEmailError={
                  isEmail(shareDetails.reply_to)
                    ? null
                    : t(
                        'shipment_share.validations.email_invalid',
                        'One or more email addresses are invalid'
                      )
                }
              />
            )}
          </Box>
        ) : (
          collaboratorsTable()
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ShipmentShareModal
