import { DateTime } from 'luxon'
import { useMemo, useState } from 'react'
import { useUpdateInlandTransport } from 'src/services/Api/overview'
import { customFields, tableCellMutationKey } from '../../constants'
import OverviewTableText from '../OverviewTableText'
import { getDisplayDate, getDateObjectFromISOString } from '../../utils'
import DateTimeCellDatePicker from './DateTimeCellDatePicker'
import { getCellIsEditable } from './utils'

const inputFormat = 'dd-MM-yyyy HH:mm'

const DateTimeCell = ({
  cell,
  testId = '',
  minDate = undefined,
  maxDate = undefined,
  requestParam = 'pickup_delivery_time',
  additionalParams = {
    service: 'delivery',
  },
  containerId,
  missingValueText = 'Add',
}: {
  cell: {
    getValue: () => any
  }
  testId?: string
  minDate?: string
  maxDate?: string
  requestParam?: string
  additionalParams?: {
    [key: string]: string
  }
  containerId: number
  missingValueText?: string
}) => {
  const { mutateAsync: updateInlandTransport } = useUpdateInlandTransport()

  const value = cell.getValue() ? cell.getValue() : null

  const displayDate = getDisplayDate(value)

  const [open, setOpen] = useState<boolean>(false)

  const onClose = (selectedValue: DateTime | null) => {
    const formattedSelectedValue: string | null =
      selectedValue?.toFormat(inputFormat) ?? null

    const formattedValue: string | null = value
      ? getDateObjectFromISOString(value).toFormat(inputFormat)
      : null

    setOpen(false)

    if (formattedSelectedValue === formattedValue) {
      return
    }

    updateInlandTransport({
      mutationKey: [tableCellMutationKey],
      params: {
        custom_fields: customFields,
        inland_transports: [
          {
            container_id: containerId,
            [requestParam]: formattedSelectedValue,
            ...additionalParams,
          },
        ],
      },
    })
  }

  const cellIsEditable = useMemo(() => {
    return getCellIsEditable(cell)
  }, [cell])

  if (cellIsEditable) {
    return (
      <DateTimeCellDatePicker
        open={open}
        value={value}
        testId={testId}
        maxDate={maxDate}
        minDate={minDate}
        onClose={onClose}
        inputFormat={inputFormat}
        onClick={() => setOpen(true)}
        displayDate={displayDate}
        addButtonText={missingValueText}
      />
    )
  }
  return <OverviewTableText data-testid={testId} text={displayDate} />
}

export default DateTimeCell
