import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import ToolbarInternalButtons from 'src/components/Common/Table/DataTable/Toolbar/ToolbarInternalButtons'
import Views from 'src/components/Overview/Views'
import Search from 'src/components/Overview/Search'
import SearchGroups from '../../SearchGroups'

const CustomToolbar = ({ table }) => {
  return (
    <>
      <Stack spacing={1} px={2} pt={2} pb={2} justifyContent="center">
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Views />
          <Stack direction="row" spacing={2} alignItems="center">
            <SearchGroups />
            <ToolbarInternalButtons table={table} />
          </Stack>
        </Stack>
        <Search />
      </Stack>
      <Divider sx={{ mb: 1 }} />
    </>
  )
}

export default CustomToolbar
