import { FunctionComponent, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { flatMap, uniqBy } from 'lodash'
import ShipmentDocumentRow from './ShipmentDocumentRow'
import { ShipmentDocumentRowProps } from './props'

const ShipmentDocument: FunctionComponent<ShipmentDocumentRowProps> = (
  props
) => {
  const onEdit = useCallback((): void => {
    if (!props.disabled) {
      props.onEdit(props.document.id)
    }
  }, [props.document, props.disabled])

  const onDelete = useCallback((): void => {
    if (!props.disabled) {
      props.onDelete(props.document.id)
    }
  }, [props.document, props.disabled])

  const { organizationRoles } = useSelector((state: IGlobalState) => ({
    organizationRoles: state.shipments.organizationRoles,
  }))

  const shipmentRoles: IRoleShort[] = useMemo(() => {
    return uniqBy(flatMap(organizationRoles, 'shipment_roles'), 'id')
  }, [organizationRoles])

  return (
    <ShipmentDocumentRow
      isModalityAir={props.isModalityAir}
      document={props.document}
      shipmentId={props.shipmentId}
      downloadable={props.downloadable}
      withBooking={props.withBooking}
      containersCount={props.containersCount}
      opened={true}
      onDelete={onDelete}
      onEdit={onEdit}
      disabled={props.disabled}
      isFcl={props.isFcl}
      shipmentRoles={shipmentRoles}
      showPublicLink={props.showPublicLink}
      isGroupedByContainer={props.isGroupedByContainer}
    />
  )
}

export default ShipmentDocument

ShipmentDocument.defaultProps = {
  disabled: false,
  withBooking: false,
  downloadable: true,
  containersCount: 0,
}
