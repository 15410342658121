import Box from '@mui/material/Box'
import { TextField } from '@mui/material'
import { useFormContext, Controller } from 'react-hook-form'
import { useState, Fragment, useRef, useEffect } from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import RecentFiltersManager from '../RecentSearches'
import { StyledBaseAutocompletePopper } from '../../styled'
import DropdownTrigger from './DropdownTrigger'

const Input = ({ token, onDelete }) => {
  const recentSearches = new RecentFiltersManager()
  const { getValues, setValue: setFilterValue, control } = useFormContext()
  const search: string = getValues('search')
  const anchorRef = useRef<HTMLDivElement>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [localInputValue, setLocalInputValue] = useState<string>('')

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const setRecentSearch = (value: string) => {
    recentSearches.addRecentSearch(value)
  }

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'input' : undefined

  useEffect(() => {
    if (anchorRef?.current) {
      setAnchorEl(anchorRef.current)
    }
  }, [anchorRef])

  useEffect(() => {
    if (search && search.length > 0) {
      handleClose()
    }
  }, [search])

  const handleDelete = () => {
    onDelete(token.value)
    setFilterValue(token.value, '')
  }

  return (
    <Fragment>
      <DropdownTrigger
        open={open}
        anchorRef={anchorRef}
        partOne="Search"
        onClick={handleClick}
        onDelete={handleDelete}
        partTwo={search}
      />
      <StyledBaseAutocompletePopper
        id={id}
        open={open}
        sx={{ p: 2, width: 350 }}
        anchorEl={anchorEl}
        placement="bottom-start"
        data-testid="input-popover"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Box>
            <Controller
              control={control}
              name={token.value}
              render={({ field: { onChange, value } }) => (
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    onChange(localInputValue)
                    handleClose()
                    setRecentSearch(localInputValue)
                  }}
                >
                  <TextField
                    fullWidth
                    autoFocus
                    defaultValue={value}
                    onChange={(event) => {
                      setLocalInputValue(event.target.value)
                    }}
                    helperText="Hit enter when you're done!"
                    inputProps={{
                      'data-testid': 'overview-search-dropdown-input',
                    }}
                  />
                </form>
              )}
            />
          </Box>
        </ClickAwayListener>
      </StyledBaseAutocompletePopper>
    </Fragment>
  )
}

export default Input
