import FormControl from '@mui/material/FormControl'
import FormLabel from 'src/stories/Lab/FormLabel'
import { useTranslation } from 'react-i18next'
import { MenuItem } from '../Menu/components/MenuItem'
import { SelectProps } from './Select.props'
import { DefaultMenuProps } from './Select.constants'
import {
  StyledSelect,
  IconComponent,
  getRenderValueForSingleSelect,
} from './Select.utils'

import { SelectClearButton } from './SelectClearButton'

export const SingleSelect: React.FC<SelectProps> = (props) => {
  const {
    label,
    value,
    options,
    onReset,
    mandatory = true,
    MenuProps,
    ...muiSelectProps
  } = props

  const { t } = useTranslation()

  const placeholder = props?.placeholder ?? t('common.select', 'Select')

  const onResetClick = () => {
    if (onReset) {
      onReset()
    }
  }

  const resetButtonVisible = !mandatory && !!value && !props.disabled

  return (
    <>
      <FormControl fullWidth>
        {label && <FormLabel label={label} />}
        <StyledSelect
          value={value}
          displayEmpty
          autoWidth={false}
          MenuProps={{
            ...DefaultMenuProps,
            ...MenuProps,
          }}
          endAdornment={
            <SelectClearButton
              visible={resetButtonVisible}
              onClick={onResetClick}
            />
          }
          className={resetButtonVisible ? 'clearable' : ''}
          IconComponent={IconComponent}
          renderValue={(selected: any) =>
            getRenderValueForSingleSelect(selected, options, placeholder)
          }
          {...muiSelectProps}
        >
          {options.map((option) => {
            return (
              <MenuItem
                key={option.id}
                value={option.id}
                text={option.label}
                disabled={option?.disabled ?? false}
                data-testid={`select-option-${
                  (props['data-testid'] || '') + '-' + option.id
                }`}
              />
            )
          })}
        </StyledSelect>
      </FormControl>
    </>
  )
}
