import { useCallback, useState } from 'react'
import { debounce, omitBy, isEmpty, isBoolean } from 'lodash'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Typography,
} from '@mui/material'
import Input from 'src/components/Common/Input/MuiInput'
import EmailAddressesComponent from 'src/components/ShipmentShareModal/EmailAddressesComponent'
import { useTranslation } from 'react-i18next'
import { TextArea } from 'src/stories/TextArea'
import { Dialog, DialogContent } from 'src/stories'
import { useMutation } from '@tanstack/react-query'
import { apiClient } from 'src/services/http-common'
import isEmail from 'validator/lib/isEmail'
import { useDispatch } from 'react-redux'
import { showNotification } from 'src/stores/actionCreators/notifications'

type Scope = 'pickup' | 'delivery'

interface EmailsFormContainerProps {
  selectedRowsIds: number[]
  onClose: () => void
  scope: Scope
}

interface EmailsFormDialogProps {
  selectedRowsIds: number[]
  onClose: () => void
  isLoading: boolean
  isValid: boolean
  onSave: (selectedRowsIds: number[]) => Promise<BulkEmailResponse>
  children: React.ReactNode
  scope: Scope
}

interface BulkEmailRequest {
  inland_transport_ids: number[]
  subject: string
  reply_to: string | null
  cc: string[]
  send_to: string[]
  cc_yourself: boolean
  message: string
  scope: Scope
}

interface BulkEmailResponse {
  message: string
}

export interface FormData {
  to: { options: { title: string; id: string }[]; values: string[] }
  cc: { options: { title: string; id: string }[]; values: string[] }
  cc_yourself: boolean
  reply: string
  subject: string
  message: string
}

const CC_KEY = 'containers_share_cc'
const REPLY_TO_KEY = 'containers_share_reply_to'
const EMAILS_KEY = 'containers_share_to_email_addresses'

const getLocalStorageArray = (key: string, defaultJsonValue: string = '[]') => {
  try {
    return JSON.parse(window.localStorage.getItem(key) || defaultJsonValue)
  } catch {
    window.localStorage.setItem(key, defaultJsonValue)
    return []
  }
}

const EmailsFormContainer = ({
  selectedRowsIds,
  onClose,
  scope,
}: EmailsFormContainerProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [formData, setFormData] = useState<FormData>({
    to: { options: getLocalStorageArray(EMAILS_KEY), values: [] },
    cc: { options: getLocalStorageArray(CC_KEY), values: [] },
    cc_yourself: false,
    reply: window.localStorage.getItem(REPLY_TO_KEY) || '',
    subject: '',
    message: '',
  })

  const mutation = useMutation({
    mutationFn: ({ formData, ids }: { formData: FormData; ids: number[] }) => {
      const data: BulkEmailRequest = {
        inland_transport_ids: ids,
        subject: formData.subject,
        reply_to: isEmail(formData.reply) ? formData.reply : null,
        cc: formData.cc.values.filter((v) => isEmail(v)),
        cc_yourself: formData.cc_yourself,
        send_to: formData.to.values.filter((v) => isEmail(v)),
        message: formData.message,
        scope,
      }

      const cleanedData = omitBy<BulkEmailRequest>(
        data,
        (val) => !isBoolean(val) && isEmpty(val)
      )

      return apiClient.post<any, BulkEmailResponse>(
        'pickups_deliveries/bulk_email',
        JSON.stringify(cleanedData)
      )
    },
    onSuccess: () => {
      dispatch(
        showNotification({
          message: t(
            'transports.containers_sharing.notifications.sending_in_progress',
            'Sending in progress.'
          ),
          severity: 'info',
        })
      )
    },
    onError: () => {
      dispatch(
        showNotification({
          message: t('common.errors.something_wrong', 'Something went wrong.'),
          severity: 'error',
        })
      )
    },
  })

  const updateFormData = (value: any, name: string) => {
    if ((name === 'reply' && isEmail(value)) || value === '') {
      window.localStorage.setItem(REPLY_TO_KEY, value)
    }
    if (name === 'to' || name === 'cc') {
      const options = value.options.filter((v) => isEmail(v.title))
      window.localStorage.setItem(
        name === 'to' ? EMAILS_KEY : CC_KEY,
        JSON.stringify(options)
      )
      return setFormData((prevValue) => ({
        ...prevValue,
        [name]: { ...value, options: options },
      }))
    }
    setFormData((prevValue) => ({ ...prevValue, [name]: value }))
  }

  const onSave = (ids: number[]) => {
    return mutation.mutateAsync({
      formData,
      ids,
    })
  }

  const isValid =
    formData.to.values.length > 0 &&
    formData.to.values.every((email) => isEmail(email)) &&
    formData.subject.length > 0

  return (
    <EmailsFormDialog
      scope={scope}
      onSave={onSave}
      selectedRowsIds={selectedRowsIds}
      isLoading={mutation.isLoading}
      isValid={isValid}
      onClose={onClose}
    >
      <EmailsFormView value={formData} onChange={updateFormData} />
    </EmailsFormDialog>
  )
}

const EmailsFormDialog = ({
  selectedRowsIds,
  onClose,
  isLoading,
  isValid,
  onSave,
  children,
  scope,
}: EmailsFormDialogProps) => {
  const { t } = useTranslation()

  const onSend = async () => {
    await onSave(selectedRowsIds)
    onClose()
  }

  return (
    <Dialog
      open
      fullWidth
      maxWidth="md"
      title={
        scope === 'pickup'
          ? t(
              'transports.containers_sharing.pickup.dalog.title',
              'Send pickup email'
            )
          : t(
              'transports.containers_sharing.delivery.dalog.title',
              'Send delivery email'
            )
      }
      onClose={onClose}
      actions={
        <>
          <Button variant="outlined" onClick={onClose} size="large">
            {t('common.buttons.cancel', 'Cancel')}
          </Button>
          <Button
            data-testid="button-send"
            disabled={isLoading || !isValid}
            variant="contained"
            onClick={onSend}
            size="large"
          >
            {t('common.buttons.send', 'Send')}
          </Button>
        </>
      }
    >
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          {t(
            'transports.containers_sharing.dalog.text1',
            'You have selected {{count}} containers',
            { count: selectedRowsIds.length }
          )}
        </Typography>
        <Typography variant="body1">
          {t(
            'transports.containers_sharing.dalog.text2',
            'The row content will be shared both in the email and as an attachment.'
          )}
        </Typography>
        {children}
      </DialogContent>
    </Dialog>
  )
}

const ReplyToField = ({ onChange, value, ...props }) => {
  const { t } = useTranslation()
  const [isValid, setIsValid] = useState(true)

  const delayedValidation = useCallback(
    debounce((val) => {
      setIsValid(isEmail(val))
    }, 1000),
    [setIsValid]
  )

  const onFieldChange = (value, name) => {
    setIsValid(true)
    onChange(value, name)
    delayedValidation(value)
  }

  return (
    <>
      <InputLabel htmlFor="customer">
        {t('shipment_share.email.reply_to.label', 'Reply to address')}
      </InputLabel>
      <Input
        data-testid={props['data-testid']}
        required
        name="reply"
        placeholder={t(
          'shipment_share.email.reply_to.placeholder',
          'Enter email address'
        )}
        onChange={onFieldChange}
        value={value}
      />
      {value.length > 0 && !isValid && (
        <FormHelperText error>
          {t('common.errors.email_is_not_correct', 'Email is not correct.')}
        </FormHelperText>
      )}
    </>
  )
}

const EmailsFormView = ({
  value,
  onChange,
}: {
  value: FormData
  onChange: (value: any, name: string) => void
}) => {
  const { t } = useTranslation()

  return (
    <Box mt={2} sx={{ position: 'relative' }}>
      <EmailAddressesComponent
        data-testid="input-emails-to"
        name="to"
        label={t('shipment_share.email.to.label', 'To*')}
        values={value.to.values}
        options={value.to.options}
        onChange={({ options, values }) => {
          onChange({ options, values }, 'to')
        }}
      />
      <Box mt={2} sx={{ display: 'flex' }}>
        <Box sx={{ width: '50%' }}>
          <EmailAddressesComponent
            data-testid="input-emails-cc"
            name="cc"
            label={t('shipment_share.email.cc.label', 'Cc')}
            values={value.cc.values}
            options={value.cc.options}
            onChange={({ options, values }) => {
              onChange({ options, values }, 'cc')
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-emails-cc_yourself"
                name="cc_yourself"
                checked={value.cc_yourself}
                onChange={(event) =>
                  onChange(event.target.checked, event.target.name)
                }
              />
            }
            label={t(
              'transports.containers_sharing.fields.cc_yourself.label',
              'Send a copy to yourself'
            )}
          />
        </Box>
        <Box pl={2} sx={{ width: '50%' }}>
          <ReplyToField
            data-testid="input-emails-reply"
            onChange={onChange}
            value={value.reply}
          />
        </Box>
      </Box>
      <Box mt={2}>
        <Input
          name="subject"
          data-testid="input-emails-subject"
          label={t('shipment_share.email.subject.label', 'Subject*')}
          className="input-field"
          value={value.subject}
          onChange={onChange}
          placeholder={t(
            'transports.containers_sharing.fields.subject.placeholder',
            'Add an email subject'
          )}
        />
      </Box>
      <Box mt={2}>
        <TextArea
          data-testid="textarea-emails-messsage"
          name="message"
          id="textarea-field"
          maxLength={400}
          onChange={(event) => onChange(event.target.value, event.target.name)}
          value={value.message}
          placeholder={t(
            'shipment_share.email.message.placeholder',
            'Type to add message'
          )}
          label={t('shipment_share.email.message.label', 'Message')}
          minRows={4}
        />
      </Box>
    </Box>
  )
}

export { EmailsFormView }
export default EmailsFormContainer
