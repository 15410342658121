// @ts-nocheck
// FIXME

import { PureComponent, ReactNode, Fragment } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import bind from 'autobind-decorator'
import Dropzone from 'react-dropzone'
import { parse, ParsedQuery, stringify } from 'query-string'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import MuiLink from '@mui/material/Link'
import { Button, Box, LinearProgress } from '@mui/material'
import { Link, withRouter } from 'react-router-dom'
import { withTranslation, Trans } from 'react-i18next'
import {
  compact,
  includes,
  unionBy,
  map,
  pickBy,
  startCase,
  groupBy,
} from 'lodash'
import { initialChatState } from 'src/stores/reducers/chat'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { Alert } from 'src/stories/Alert'
import { history } from 'src/shyppleStore'
import MentionArea from 'src/components/MentionArea'
import AuthorAvatar from 'src/components/AuthorAvatar'
import TrackWindowFocus from 'src/components/Common/TrackWindowFocus'
import UserChannelClient from 'src/components/SocketHandlers/UserChannelClient'
import ChatChannelHandler from 'src/components/SocketHandlers/ChatChannelHandler'
import PinnedMessages from 'src/components/PinnedMessages'
import {
  convertDateToDateWithoutYear,
  FORMAT_TIME,
} from 'src/utils/helpers/shipmentDate'
import { Logger, permissionTo, promisifyAction } from '../../utils'
import {
  convertDatePickerToUniversalFormat,
  fromISO,
} from '../../utils/helpers'

import {
  chatGetData,
  chatGetDataSuccess,
  chatGetUsersSuccess,
  chatGetUsers,
  attachFileToShipment,
  chatPinComment,
  chatSaveMessage,
  submitChatComment,
  acceptApproval,
  rejectApproval,
  userChannelPerform,
  chatChannelPerform,
} from '../../stores/actionCreators'
import CSAT from './components/csat'
import ChatOrganizations from './components/ChatOrganizations'
import { getRequestRateMatchMessageData, prepareForRequest } from './helpers'
import { FORBIDDEN_FORMATS, ICONS } from './constants'
import 'src/assets/styles/commonStyles.scss'
import './styles.scss'
import InfiniteScroll from './InfiniteScroll'

interface IChatProps {
  csat: ICsat
  match: IMatch | null
  location: Location
  shipmentId: number
  chatId: number
  totalPages: number
  commentsLoading: boolean
  followers: IFollower[]
  organizations: IChatOrganization[]
  related_users: IChatUser[]
  getConversation: IActionPromiseFactory
  saveConversation: IActionPromiseFactory
  saveFollowers: IActionPromiseFactory
  getUsers: IActionPromiseFactory
  attachFileToShipment: IActionPromiseFactory
  pinComment: IActionPromiseFactory
  saveMessage: IActionPromiseFactory
  submit: IActionPromiseFactory
  acceptApproval: IActionPromiseFactory
  rejectApproval: IActionPromiseFactory
  performUserChannelCommand: IActionPromiseFactory
  performChatChannelCommand: IActionPromiseFactory
  toggleChatFollow: IActionPromiseFactory
  flagWindowActive: boolean
  showMessage: (notification: INotification) => void
}

interface ISocketResponse {
  [x: string]: any
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getConversation: promisifyAction(dispatch, chatGetData),
  saveConversation: promisifyAction(dispatch, chatGetDataSuccess),
  saveFollowers: promisifyAction(dispatch, chatGetUsersSuccess),
  getUsers: promisifyAction(dispatch, chatGetUsers),
  attachFileToShipment: promisifyAction(dispatch, attachFileToShipment),
  pinComment: promisifyAction(dispatch, chatPinComment),
  saveMessage: promisifyAction(dispatch, chatSaveMessage),
  submit: promisifyAction(dispatch, submitChatComment),
  acceptApproval: promisifyAction(dispatch, acceptApproval),
  rejectApproval: promisifyAction(dispatch, rejectApproval),
  performUserChannelCommand: promisifyAction(dispatch, userChannelPerform),
  performChatChannelCommand: promisifyAction(dispatch, chatChannelPerform),
  showMessage: (notification: INotification) =>
    dispatch(showNotification(notification)),
})

const mapStateToProps = (state: IGlobalState): any => ({
  csat: state.chat.csat,
  totalPages: state.chat.totalPages,
  commentsLoading: state.chat.commentsLoading,
  comments: state.chat.comments,
  followers: state.chat.followers,
  organizations: state.chat.organizations,
  related_users: state.chat.related_users,
  currentUserId: state.user.id,
  userFirstName: state.user.firstName,
  userLastName: state.user.lastName,
  flagWindowActive: state.flags.windowActive,
})

const initialState = {
  page: 1,
  csat: initialChatState.csat,
  comments: [],
  content: '',
  isWatcherAlert: false,
  outOfOfficeAlert: false,
  mentionFullName: '',
  mentionOutOfOfficeTill: '',
  filesToUpload: [],
  failedFiles: [],
  typers: [],
  replyCommentId: null,
  hover: false,
  typersNames: [],
  currentUserId: 0,
  invalid: false,
  interval: undefined,
  currentDropdownEl: null,
  currentDropdownMenu: null,
  approvalButtonPressed: false,
}

class Chat extends PureComponent<IChatProps, IChatComponentState> {
  public static defaultProps = {}
  public page: any
  public fileInput: any
  public commentsForm: any

  constructor(props, context) {
    super(props, context)
    this.state = initialState
    this.commentsContainer = React.createRef()
  }

  public async componentDidMount(): Promise<any> {
    const params = parse(window.location.search)
    try {
      await Promise.all([
        this.props.getUsers(this.props.chatId),
        this.props.getConversation(this.props.chatId, this.state.page),
      ])
    } catch (error) {
      Logger.error(error)
    }
    if (this.props.flagWindowActive) {
      this.markChatRead()
    }
    if (params && params.services) {
      const normalizedServices = (typeof params.services === 'string'
        ? [params.services]
        : params.services
      ).map((item) => startCase(item).toLowerCase())
      this.setState({
        content: this.props.t(
          'chats.messages.services',
          'Hi, I would like to add {{services}} services to my shipment. Can you help me with that? Best regards, {{firstName}} {{lastName}}',
          {
            firstName: this.props.userFirstName,
            lastName: this.props.userLastName,
            services: normalizedServices.join(
              this.props.t('common.list_separator.and', ' and ')
            ),
            count: normalizedServices.length,
          }
        ),
      })
    }

    this.sendRequestRateMatchMessage(params)
    this.chatScrollBottom(true)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.flagWindowActive && nextProps.flagWindowActive) {
      this.markChatRead()
    }

    const comments = nextProps.comments || []

    let csatComment = {
      id: `csat_${nextProps.csat.id}`,
      author: {},
      type: 'csat',
      created_at: nextProps.csat.csat_requested_at,
    } as any

    const commentsWithCsat = nextProps.csat.csat_requested_at
      ? [
          ...comments.filter((comment) => comment.id !== csatComment.id),
          csatComment,
        ]
      : comments

    this.setState({
      csat: nextProps.csat,
      comments: commentsWithCsat,
      currentUserId: nextProps.currentUserId,
    })
  }

  public componentDidUpdate() {
    const params = parse(window.location.search)

    if (params.scrollToChat === 'true') {
      window.scrollTo({
        top: window.innerWidth > 1200 ? 350 : 550,
        behavior: 'smooth',
      })

      setTimeout(
        () =>
          history.push({
            search: stringify(pickBy({ scrollToChat: false }), {
              arrayFormat: 'bracket',
            }),
          }),
        500
      )
    }
  }

  adjustScrollPosition = async (loadMessages: Promise<any>) => {
    const lastScrollHeight = this.commentsContainer.current.scrollHeight
    const hash: number = parseInt(this.props.location.hash.replace('#', ''))
    await loadMessages()
    if (!this.commentsContainer.current) return
    const scrollDiff =
      this.commentsContainer.current.scrollHeight - lastScrollHeight
    if (!hash) {
      this.commentsContainer.current.scrollTop += scrollDiff
    }
  }

  onScrollTop = async () => {
    const newPage = this.state.page + 1
    if (newPage > this.props.totalPages) return
    await this.adjustScrollPosition(() =>
      this.props.getConversation(this.props.chatId, newPage)
    )
    this.setState({ page: newPage })
  }

  public render(): ReactNode {
    const createCommentPermission = permissionTo('chats.comments.create')
    const createAttachmentPermission = permissionTo('chats.attachments.create')

    return (
      <Dropzone
        disabled={!createAttachmentPermission}
        onDrop={this.onDrop}
        onDragEnter={this.onDrag}
        onDragLeave={this.onDragOut}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()} className="relative">
              <UserChannelClient onReceived={this.onReceived} />
              <ChatChannelHandler
                onReceived={this.onReceivedChatData}
                chatId={this.props.chatId}
              />

              <TrackWindowFocus />
              <ChatOrganizations chatId={this.props.chatId} />
              <PinnedMessages chatId={this.props.chatId} />
              <article ref={(elem) => (this.page = elem)}>
                <div
                  className={`comments ${this.state.hover ? 'hovered' : ''}`}
                >
                  {this.props.commentsLoading && (
                    <Box
                      sx={{ width: '100%', position: 'absolute', zIndex: 1 }}
                    >
                      <LinearProgress />
                    </Box>
                  )}
                  <InfiniteScroll
                    id="messages-container"
                    className="comments__container"
                    loading={this.props.commentsLoading}
                    ref={this.commentsContainer}
                    onScrollTop={this.onScrollTop}
                  >
                    {this._renderChat()}
                    {this.state.outOfOfficeAlert ? (
                      <Alert
                        variant="filled"
                        color="warning"
                        handleClose={() => {
                          this.setState({ outOfOfficeAlert: false })
                        }}
                        showClose
                        message={this.props.t(
                          'chats.messages.out_of_office',
                          '{{fullName}} is out of office and scheduled to return on {{returnOn}}',
                          {
                            fullName: this.state.mentionFullName,
                            returnOn: convertDateToDateWithoutYear(
                              this.state.mentionOutOfOfficeTill
                            ),
                          }
                        )}
                      />
                    ) : null}
                    {this.state.isWatcherAlert ? (
                      <Alert
                        variant="filled"
                        color="info"
                        handleClose={() => {
                          this.setState({ isWatcherAlert: false })
                        }}
                        showClose
                        message={this.props.t(
                          'chats.messages.uset_not_watching_chat',
                          'Mentioned user is not watching this chat, but they will be notified after your message is sent.'
                        )}
                      />
                    ) : null}
                  </InfiniteScroll>
                  {
                    <div
                      className="comments__form"
                      ref={(elem) => (this.commentsForm = elem)}
                    >
                      {this._renderTypers()}
                      <div className="comments__form__inputs">
                        {createAttachmentPermission && (
                          <div className="comments__form__inputs__attachment">
                            <Tooltip
                              title={this.props.t(
                                'chats.tooltips.attach_file',
                                'Attach files to comment'
                              )}
                            >
                              <label htmlFor="comments__form__inputs__attachment__input">
                                +
                              </label>
                            </Tooltip>
                            <input
                              {...getInputProps()}
                              id="comments__form__inputs__attachment__input"
                              ref={(elem) => (this.fileInput = elem)}
                              className="hidden"
                              type="file"
                              multiple={true}
                              onChange={this.uploadFile}
                              data-testid="attach-file"
                            />
                          </div>
                        )}
                        <div className="comments__form__inputs__base">
                          {createCommentPermission && (
                            <div>
                              {this._renderFailedFiles()}
                              {this._renderUploadedFiles()}
                              <div
                                className={`comments__form__inputs__base__container ${
                                  this.state.invalid ? 'invalid' : ''
                                }`}
                              >
                                {this._renderReplyComment()}
                                <div className="flex">
                                  <MentionArea
                                    value={this.state.content}
                                    defaultValue={this.state.content}
                                    mentionables={{
                                      users: this.props.related_users.filter(
                                        (x) => x.can_be_mentioned
                                      ),
                                    }}
                                    followers={map(
                                      this.props.followers,
                                      (followers) => followers.id
                                    )}
                                    submit={this.mentionAreaSubmit}
                                    onAddMention={this.addMention}
                                    onBlur={this.onBlur}
                                    onFocus={this.onFocus}
                                    currentUserId={this.state.currentUserId}
                                    onChange={this.onChange}
                                  />
                                  <Button
                                    variant="outlined"
                                    onClick={this.submit}
                                    data-testid="send-button"
                                  >
                                    {this.props.t('chats.buttons.send', 'Send')}
                                  </Button>
                                </div>
                              </div>
                              <div className="comments__form__inputs__base--tips paragraph__small grey">
                                <Trans
                                  i18nKey="chats.tips.keyboard_shortcuts"
                                  components={{
                                    badge: (
                                      <span className="comments__form__inputs__base--tips__badge" />
                                    ),
                                  }}
                                  defaults="<badge>Shift</badge><badge>Enter</badge> for a new line, <badge>Enter</badge> to send a message"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </article>
            </div>
          </section>
        )}
      </Dropzone>
    )
  }

  @bind
  private onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ content: event.target.value })
  }

  private _renderTypers(): ReactNode {
    if (!this.state.typers.length) {
      return null
    }
    return (
      <div className="comments__form__inputs__base--typers">
        {this.state.typers.map((typer: ITyper) => (
          <div
            className="typer__name"
            style={{ color: typer.organization.color }}
          >
            {typer.full_name} | <b>{typer.organization.name}</b>
          </div>
        ))}
        {this.props.t('chats.messages.typing', 'is typing ...', {
          count: this.state.typers.length,
        })}
      </div>
    )
  }

  private _renderChat(): ReactNode {
    const groups = groupBy(this.state.comments, ({ created_at }) =>
      created_at.replace(/T.+/, '')
    )

    return Object.keys(groups).map((date) => (
      <Fragment key={date}>
        <div className="comments__container__date">
          <span className="comments__container__date__item">
            {convertDatePickerToUniversalFormat(date)}
          </span>
        </div>
        {groups[date].map((comment, index) =>
          this._renderCommentByType(comment, index)
        )}
      </Fragment>
    ))
  }

  private _renderCommentByType(comment: IComment, i: number): ReactNode {
    let commentEl: any
    let className: string
    if (comment.type === 'csat') {
      return (
        <CSAT
          key={comment.id}
          {...this.props.csat}
          shipmentId={this.props.shipmentId}
          userId={this.state.currentUserId}
        />
      )
    }
    if (comment.author.id === this.state.currentUserId) {
      className = 'outgoing'
      commentEl = comment.is_system
        ? this._renderSystem(comment)
        : this._renderOutgoing(comment, i)
    } else {
      className = 'incoming'
      commentEl = comment.is_system
        ? this._renderSystem(comment)
        : this._renderIncoming(comment, i)
    }
    return (
      <div
        key={i}
        id={`chat-comment-${comment.id}`}
        className={`comments__container__item ${className}`}
      >
        {commentEl}
      </div>
    )
  }

  private _renderSystem(comment: IComment) {
    return (
      <div className="system-comment">
        <span className="system-comment__time paragraph__small grey">
          {fromISO(comment.created_at).toFormat(FORMAT_TIME)}
        </span>
        <span className="system-comment__author paragraph__small">
          {this._renderAuthorWithOrg(comment.author)}
        </span>
        {comment.link ? (
          <Link
            className="system-comment__link paragraph__small grey"
            to={`/shipments/${this.props.shipmentId}${comment.link}`}
          >
            <span dangerouslySetInnerHTML={{ __html: comment.content }} />
          </Link>
        ) : (
          <span dangerouslySetInnerHTML={{ __html: comment.content }} />
        )}
      </div>
    )
  }

  private _renderOutgoing(comment: IComment, i: number) {
    return (
      <>
        <div
          className="flex comments__container__item__comment"
          data-testid="outgoing-chat-message"
        >
          {this._renderActions(comment)}
          {this._renderCommentContent(comment, true, i)}
        </div>
        <div className="comments__container__item__author">
          {this._renderAuthorPhoto(comment, i)}
        </div>
      </>
    )
  }

  private _renderIncoming(comment: IComment, i: number) {
    if (comment.approval_id && comment.approval) {
      return this._renderApproval(comment, i)
    }
    return (
      <div
        className="comments__container__item__comment"
        data-testid="incoming-chat-message"
      >
        <div className="comments__container__item__author">
          {this._renderAuthorPhoto(comment, i)}
        </div>
        <div className="flex">
          {this._renderCommentContent(comment, false, i)}
          {this._renderActions(comment)}
        </div>
      </div>
    )
  }

  private _renderApproval(comment: IComment, i: number) {
    if (!comment.approval) {
      return ''
    }

    return (
      <div>
        <div className="comments__container__item__author">
          {this._renderAuthorPhoto(comment, i)}
        </div>
        {this._renderApprovalDialog(comment, i)}
      </div>
    )
  }

  private _renderApprovalDialog(comment: IComment, i: number) {
    const approval = comment.approval

    if (!approval) {
      return ''
    }
    let buttons: ReactNode = null
    switch (approval.approved) {
      case null:
        if (
          (approval.mentioned_id === this.state.currentUserId ||
            !approval.mentioned_id) &&
          approval.actual
        ) {
          buttons = this._renderButtonsDialog(comment)
        } else {
          buttons = null
        }
        break
      case true:
        buttons = this._renderButtonsAccepted(comment)
        break
      case false:
        buttons = this._renderButtonsRejected()
        break
      default:
    }
    let approvableDocument: ReactNode = null
    if (comment.approval && comment.approval.document) {
      approvableDocument = this._renderAttachment(comment.approval.document)
    }

    return (
      <div className="flex approval-dialog">
        <div>
          <div className="comments__container__item__content flex">
            <div className="flex-1">
              {this._renderReplyToComment(comment)}
              <span
                dangerouslySetInnerHTML={{ __html: approval.approval_content }}
              />
              {approvableDocument}
              {comment.comment_attachments.map(
                (attachment: ICommentAttachment) =>
                  this._renderAttachment(attachment)
              )}
              {buttons}
            </div>
            {this._renderIconsWithinMessage(comment)}
          </div>
          {this.state.comments.length !== i + 1 &&
          !this.state.comments[i + 1].is_system &&
          convertDatePickerToUniversalFormat(comment.created_at) ===
            convertDatePickerToUniversalFormat(
              this.state.comments[i + 1].created_at
            ) &&
          comment.author.id === this.state.comments[i + 1].author.id ? null : (
            <div className="comments__container__item__references">
              <span className="paragraph__small grey">
                {fromISO(comment.created_at).toFormat(FORMAT_TIME)}
                {' - '}
              </span>
              <span
                className="paragraph__small"
                style={{ color: comment.author.organization.color }}
              >
                {this._renderAuthorWithOrg(comment.author)}
              </span>
            </div>
          )}
        </div>
        {this._renderActions(comment)}
      </div>
    )
  }

  private _renderButtonsDialog(comment: IComment) {
    return (
      <div className="comments__container__item__content__buttons">
        <Button
          variant="outlined"
          color="error"
          onClick={this.rejectApproval.bind(this, comment)}
        >
          {this.props.t('chats.buttons.decline', 'Decline')}
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={this.acceptApproval.bind(this, comment)}
        >
          {this.props.t('chats.buttons.approve', 'Approve')}
        </Button>
      </div>
    )
  }

  private _renderButtonsRejected() {
    return (
      <div className="comments__container__item__content__buttons">
        <Button variant="outlined" color="error">
          {this.props.t('chats.buttons.declined', 'Declined')}
        </Button>
      </div>
    )
  }

  private _renderButtonsAccepted(comment: IComment) {
    return (
      <div className="comments__container__item__content__buttons">
        <Button
          color="success"
          variant="outlined"
          startIcon={<CheckRoundedIcon />}
        >
          {this.props.t('chats.buttons.approved', 'Approved')}
        </Button>
      </div>
    )
  }

  private _renderAuthorPhoto(comment: IComment, i: number) {
    if (
      this.state.comments.length !== i + 1 &&
      !this.state.comments[i + 1].is_system &&
      convertDatePickerToUniversalFormat(comment.created_at) ===
        convertDatePickerToUniversalFormat(
          this.state.comments[i + 1].created_at
        ) &&
      comment.author.id === this.state.comments[i + 1].author.id
    ) {
      return null
    }

    return (
      <AuthorAvatar
        author={comment.author}
        size="medium"
        placement={
          comment.author.id === this.state.currentUserId ? 'right' : undefined
        }
      />
    )
  }

  private _renderCommentContent(
    comment: IComment,
    ownComment: boolean,
    i: number
  ) {
    const saved: string = comment.saved_for_user_ids.includes(
      this.state.currentUserId
    )
      ? 'saved'
      : ''

    return (
      <div>
        <div
          className={`comments__container__item__content ${saved} flex`}
          data-testid="sent-message"
        >
          {ownComment ? this._renderIconsWithinMessage(comment) : null}
          <div className="flex-1">
            {this._renderReplyToComment(comment)}
            <span dangerouslySetInnerHTML={{ __html: comment.content }} />
            {comment.comment_attachments.map((attachment: ICommentAttachment) =>
              this._renderAttachment(attachment)
            )}
          </div>
          {ownComment ? null : this._renderIconsWithinMessage(comment)}
        </div>
        {this.state.comments.length !== i + 1 &&
        !this.state.comments[i + 1].is_system &&
        convertDatePickerToUniversalFormat(comment.created_at) ===
          convertDatePickerToUniversalFormat(
            this.state.comments[i + 1].created_at
          ) &&
        comment.author.id === this.state.comments[i + 1].author.id ? null : (
          <div className="comments__container__item__references">
            <span className="paragraph__small grey">
              {fromISO(comment.created_at).toFormat(FORMAT_TIME)}
              {' - '}
            </span>
            <span
              className="paragraph__small"
              style={{ color: comment.author.organization.color }}
            >
              {this._renderAuthorWithOrg(comment.author)}
            </span>
          </div>
        )}
      </div>
    )
  }

  @bind
  private _renderIconsWithinMessage(comment: IComment): React.ReactNode {
    return (
      <div className="comments__container__item__content__icons">
        {this._renderUnsaveIcon(comment)}
        {this._renderUnpinIcon(comment)}
      </div>
    )
  }

  @bind
  private _renderUnpinIcon(comment: IComment): ReactNode {
    if (!comment.pinned || !permissionTo('chats.comments.pin')) {
      return null
    }

    return <i className={`icon ${ICONS.pinIcon}`} />
  }

  @bind
  private _renderUnsaveIcon(comment: IComment): ReactNode {
    if (!comment.saved_for_user_ids.includes(this.state.currentUserId)) {
      return null
    }

    return <i className={`icon ${ICONS.bookmarkIcon}`} />
  }

  private _renderReplyToComment(comment: IComment): ReactNode | void {
    if (comment.answered_comment_id) {
      const parentComment: IComment | undefined = this.state.comments.find(
        (c: IComment) => c.id === comment.answered_comment_id
      )
      if (parentComment) {
        let content = parentComment.content
        if (parentComment.approval && parentComment.approval.approval_content) {
          content = parentComment.approval.approval_content
        }
        return (
          <div className="comments__container__item__content__reply">
            <div
              dangerouslySetInnerHTML={{
                __html: `${parentComment.author.full_name}: ${content}`,
              }}
            />
            {parentComment.comment_attachments.map(
              (attach: ICommentAttachment) => (
                <div key={attach.id}>{attach.original_filename}</div>
              )
            )}
          </div>
        )
      }
    }
  }

  private _renderAttachment(attachment: ICommentAttachment, isReply?: boolean) {
    let fileName: string = attachment.original_filename
    const permission: boolean = permissionTo('shipments.documents.upload')
    const dropdownId: string = `dropdown-${attachment.id}`
    if (fileName.length >= 30) {
      fileName = `${fileName.substring(0, 20)}...${fileName.substring(
        fileName.length - 6,
        fileName.length
      )}`
    }

    return (
      <span
        key={attachment.id}
        className="comments__container__item__content__attachment"
        data-testid="uploaded-attachments"
      >
        <i className="icon paperclip" />
        {isReply ? (
          <span>{fileName}</span>
        ) : (
          <span>
            <MuiLink
              variant="body1"
              href={attachment.file_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {fileName}
            </MuiLink>
            {permission && (
              <span
                className="select-icon"
                aria-owns={dropdownId}
                onClick={this.handleClick.bind(this, dropdownId)}
                aria-haspopup="true"
              >
                ‹
              </span>
            )}
            {permission && (
              <Menu
                id="simple-menu"
                anchorEl={this.state.currentDropdownEl}
                open={this.state.currentDropdownMenu === dropdownId}
                onClose={this.handleClose.bind(this)}
              >
                <MenuItem
                  className="simple-menu__item"
                  onClick={this.handleItemClick.bind(
                    this,
                    attachment.id,
                    dropdownId
                  )}
                >
                  {this.props.t(
                    'chats.buttons.assign_to_shipment',
                    'Assign to Shipment'
                  )}
                </MenuItem>
              </Menu>
            )}
          </span>
        )}
      </span>
    )
  }

  private _renderActions(comment: IComment) {
    const hasApproval: boolean = !!comment.approval_id
    const pinnedClass: string = comment.pinned ? 'already-pinned' : ''
    const savedClass: string = comment.saved_for_user_ids.includes(
      this.state.currentUserId
    )
      ? 'already-saved'
      : ''
    return (
      <div className="comments__container__item__actions">
        {permissionTo('chats.comments.pin') && (
          <MuiLink
            href="#"
            className="action-name pin"
            data-testid="chat-actions-pin"
            onClick={this.pinMessage.bind(this, comment)}
          >
            {pinnedClass
              ? this.props.t('chats.comment_actions.unpin', 'Unpin')
              : this.props.t('chats.comment_actions.pin', 'Pin')}
          </MuiLink>
        )}
        {permissionTo('chats.comments.create') && (
          <MuiLink
            href="#"
            className="action-name reply"
            data-testid="chat-actions-reply"
            onClick={this.replyComment.bind(this, comment.id)}
          >
            {this.props.t('chats.comment_actions.reply', 'Reply')}
          </MuiLink>
        )}
        {!hasApproval && (
          <MuiLink
            href="#"
            className="action-name save"
            data-testid="chat-actions-save"
            onClick={this.saveMessage.bind(this, comment)}
          >
            {savedClass
              ? this.props.t('chats.comment_actions.unsave', 'Unsave')
              : this.props.t('chats.comment_actions.save', 'Save')}
          </MuiLink>
        )}
      </div>
    )
  }

  private _renderFailedFiles(): any {
    if (this.state.failedFiles.length) {
      return (
        <div className="comments__form__inputs__base__invalid-files">
          {this.state.failedFiles.map((file: any) => {
            return (
              <span className="file" key={file.name}>
                {this.props.t(
                  'chats.messages.invalid_file_extension',
                  `Invalid File extension at {{fileName}}`,
                  { fileName: file.name }
                )}
              </span>
            )
          })}
        </div>
      )
    }
  }

  private _renderUploadedFiles(): any {
    if (this.state.filesToUpload.length) {
      return (
        <div
          className="comments__form__inputs__base__valid-files"
          data-testid="selected-attachment"
        >
          {this.state.filesToUpload.map((file: any, i: number) => {
            return (
              <span key={i} className="file">
                <i className="icon paperclip" />
                {file.name}
                <i
                  className="icon close"
                  onClick={this.deleteFile.bind(this, i)}
                  data-testid="delete-file"
                />
              </span>
            )
          })}
        </div>
      )
    }
  }

  @bind
  private _renderAuthorWithOrg(author: IAuthor): ReactNode {
    if (author.id === this.state.currentUserId) {
      return this.props.t('chats.messages.you', 'You')
    }
    const { first_name, department, phone } = author
    const leftPart: string =
      author.type === 'AdminUser'
        ? compact([first_name, department, phone]).join(' | ')
        : author.full_name
    return (
      <Box component="span" color={author.organization.color || 'primary.main'}>
        {leftPart} | <b>{author.organization.name}</b>
      </Box>
    )
  }

  private _renderReplyComment(): ReactNode {
    if (!this.state.replyCommentId) {
      return null
    }

    const replyComment: IComment | undefined = this.state.comments.find(
      (c) => c.id === this.state.replyCommentId
    )

    if (!replyComment) {
      return null
    }

    return (
      <div className="comments__form__inputs__base__container__reply-comment">
        <i className="icon close" onClick={this.clearReply} />
        <span dangerouslySetInnerHTML={{ __html: replyComment.content }} />
        {replyComment.comment_attachments.map(
          (attachment: ICommentAttachment) =>
            this._renderAttachment(attachment, true)
        )}
      </div>
    )
  }

  @bind
  private IEScroll(top: number, isInitial: boolean = false): void {
    this.commentsContainer.current.scrollTop = top
  }

  @bind
  private scroll(top: number, isInitial: boolean = false): void {
    this.commentsContainer?.current?.scrollTo({
      top,
      behavior: isInitial ? 'auto' : 'smooth',
    })
  }

  private chatScrollBottom(isInitial: boolean = false) {
    if (!this.commentsContainer?.current) {
      return
    }

    let scrollValue = this.commentsContainer.current.scrollHeight
    const hash: number = parseInt(this.props.location.hash.replace('#', ''))

    if (hash && isInitial) {
      const messageElement: HTMLElement | null = document.getElementById(
        `chat-comment-${hash}`
      )

      if (messageElement) {
        scrollValue = messageElement.offsetTop
      }
    }

    const scrollTo: (top?: number, initial?: boolean) => void = this
      .commentsContainer.current.scrollTo
      ? this.scroll
      : this.IEScroll

    setTimeout(() => {
      scrollTo(scrollValue, isInitial)
    }, 0)
  }

  private handleClose(): void {
    this.setState({ currentDropdownMenu: null, currentDropdownEl: null })
  }

  private handleClick(
    dropdownName: string,
    event: React.FormEvent<HTMLElement>
  ): void {
    this.setState({
      currentDropdownMenu: dropdownName,
      currentDropdownEl: event.currentTarget,
    })
  }

  private deleteFile(index: number) {
    const filesToUpload = this.state.filesToUpload.map((e) => e)
    filesToUpload.splice(index, 1)
    this.setState({ filesToUpload })
  }

  private handleUploadedFiles(files: File[]) {
    const filesToUpload: File[] = this.state.filesToUpload.slice()
    const failedFiles: File[] = this.state.failedFiles.slice()
    if (files) {
      Object.keys(files).forEach((key: any) => {
        const file: any = files[key]
        const valid: boolean = FORBIDDEN_FORMATS.test(file.name)
        valid ? failedFiles.push(file) : filesToUpload.push(file)
      })
    }
    this.setState({ filesToUpload, failedFiles }, () => {
      this.fileInput.value = null
      setTimeout(() => {
        this.setState({ failedFiles: [] })
      }, 6000)
    })
  }

  private replyComment(
    replyCommentId: number,
    event: React.FormEvent<HTMLElement>
  ) {
    event.preventDefault()

    this.setState({ replyCommentId }, () => {
      window.scrollTo(0, document.body.scrollHeight)
    })
  }

  private async handleItemClick(
    attachmentId: string,
    stateName: string,
    event: any
  ): Promise<any> {
    this.handleClose()
    try {
      await this.props.attachFileToShipment(this.props.chatId, attachmentId)
      this.props.showMessage({
        message: this.props.t(
          'chats.messages.attachment_saved_to_shipment',
          'Comment Attachment is saved to Shipment Documents.'
        ),
        severity: 'success',
      })
    } catch (error) {
      Logger.error(error)
    }
  }

  private async saveMessage(
    message: IComment,
    event: React.FormEvent<HTMLElement>
  ) {
    event.preventDefault()
    try {
      await this.props.saveMessage(this.props.chatId, message.id)
      if (message.saved_for_user_ids.includes(this.state.currentUserId)) {
        this.props.showMessage({
          message: this.props.t(
            'chats.messages.message_saved',
            'Message successfully saved.'
          ),
          severity: 'success',
        })
      } else {
        this.props.showMessage({
          message: this.props.t(
            'chats.messages.message_removed',
            'Message is removed from saved.'
          ),
          severity: 'success',
        })
      }
    } catch (error) {
      Logger.error(error)
    }
  }

  private async pinMessage(
    comment: IComment,
    event: React.FormEvent<HTMLElement>
  ): Promise<any> {
    event.preventDefault()
    try {
      await this.props.pinComment(this.props.chatId, comment.id)
      if (comment.pinned) {
        this.props.showMessage({
          message: this.props.t(
            'chats.messages.message_pinned',
            'Message successfully pinned.'
          ),
          severity: 'success',
        })
      } else {
        this.props.showMessage({
          message: this.props.t(
            'chats.messages.message_unpinned',
            'Message is unpinned.'
          ),
          severity: 'success',
        })
      }
    } catch (error) {
      Logger.error(error)
    }
  }

  private async acceptApproval(comment: IComment): Promise<void> {
    if (this.state.approvalButtonPressed) {
      return
    }
    try {
      this.setState({ approvalButtonPressed: true })
      await this.props.acceptApproval(comment.approval_id)
      this.props.showMessage({
        message: this.props.t('chats.messages.approved', 'Approved.'),
        severity: 'success',
      })
    } catch (error) {
      Logger.error(error)
      if (error.response && error.response.data) {
        this.props.showMessage({
          message: error.response.data.message,
          severity: 'error',
        })
      }
    } finally {
      this.setState({ approvalButtonPressed: false })
    }
  }

  private async rejectApproval(comment: IComment): Promise<void> {
    if (this.state.approvalButtonPressed) {
      return
    }
    try {
      this.setState({ approvalButtonPressed: true })
      await this.props.rejectApproval(comment.approval_id)
      this.props.showMessage({
        message: this.props.t('chats.messages.declined', 'Declined.'),
        severity: 'success',
      })
    } catch (error) {
      Logger.error(error)
      if (error.response && error.response.data) {
        this.props.showMessage({
          message: error.response.data.message,
          severity: 'error',
        })
      }
    } finally {
      this.setState({ approvalButtonPressed: false })
    }
  }

  @bind
  private onBlur(event: React.FormEvent<HTMLElement>): void {
    clearInterval(this.state.interval)
    this.setState({ interval: undefined })
  }

  @bind
  private onFocus(event: React.FormEvent<HTMLElement>): void {
    this.setState({
      interval: window.setInterval(() => {
        this.props.performChatChannelCommand({
          name: 'typing',
          params: {},
        })
      }, 2000),
    })
  }

  @bind
  private mentionAreaSubmit(
    content: string,
    event: React.FormEvent<HTMLElement>
  ): void {
    this.setState({ content }, () => {
      this.submit()
    })
  }

  @bind
  private addMention(
    isWatcherAlert: boolean,
    mentionedUserOutOfOffice: string | null,
    mentionedUserFullName: string
  ): void {
    this.setState({ isWatcherAlert })
    if (mentionedUserOutOfOffice) {
      this.setState({ outOfOfficeAlert: true })
    } else {
      this.setState({ outOfOfficeAlert: false })
    }

    this.setState({ mentionFullName: mentionedUserFullName })
    this.setState({ mentionOutOfOfficeTill: mentionedUserOutOfOffice })

    if (isWatcherAlert || mentionedUserOutOfOffice) {
      this.chatScrollBottom()
    }
  }

  @bind
  private onReceived(data: ISocketResponse): void {
    if (
      this.props.flagWindowActive &&
      ((data.message_type === 'unread_count' && data.message > 0) ||
        data.message_type === 'unread_notifications')
    ) {
      this.markChatRead()
    }

    if (data.message_type === 'save_message') {
      const chatComments: IComment[] = this.state.comments.map(
        (comment: IComment) => {
          if (comment.id === data.message.data.comment.id) {
            comment.saved_for_user_ids =
              data.message.data.comment.saved_for_user_ids
          }
          return comment
        }
      )
      this.props.saveConversation({
        chat_comments: chatComments,
        csat: this.props.csat,
      })
    }
  }

  @bind
  private markChatRead(): void {
    this.props.performUserChannelCommand({
      name: 'mark_chat_comments_read',
      params: { chat_id: this.props.chatId },
    })
  }

  @bind
  private onReceivedChatData(data: ISocketResponse): void {
    Logger.log('RECEIVED CHAT DATA', data)
    switch (data.message_type) {
      case 'chat_message': {
        const comments = unionBy(
          this.state.comments,
          [data.message.data.comment],
          'id'
        )
        this.props.saveConversation({
          chat_comments: comments,
          csat: this.props.csat,
        })
        this.chatScrollBottom()
        break
      }

      case 'update_approval': {
        const chatComments: IComment[] = this.state.comments.map(
          (comment: IComment) => {
            if (comment.id === data.message.data.comment.id) {
              comment.approval = data.message.data.comment.approval
            }
            return comment
          }
        )
        this.props.saveConversation({
          chat_comments: chatComments,
          csat: this.props.csat,
        })
        break
      }

      case 'deleted_message': {
        const comments = this.state.comments.filter(
          (c: IComment) => c.id !== data.message.data.comment.id
        )
        this.props.saveConversation({
          chat_comments: comments,
          csat: this.props.csat,
        })
        break
      }

      case 'pin_comment': {
        const chatComments: IComment[] = this.state.comments.map(
          (comment: IComment) => {
            if (comment.id === data.message.data.comment.id) {
              comment.pinned = data.message.data.comment.pinned
            }
            return comment
          }
        )
        this.props.saveConversation({
          chat_comments: chatComments,
          csat: this.props.csat,
        })
        break
      }

      case 'followers': {
        const { related_users, organizations } = this.props
        this.props.saveFollowers({
          followers: { followers: data.message.followers },
          related_users,
          organizations,
        })
        break
      }

      case 'typing': {
        if (
          data.message.user_id !== this.state.currentUserId &&
          data.message.user_type !== 'User'
        ) {
          const typerName: string = data.message.full_name
          const typersNames = this.state.typersNames.slice()
          typersNames.push(typerName)
          this.setState(
            {
              typers: unionBy(
                this.state.typers,
                [data.message],
                'user_id',
                'user_type'
              ),
              typersNames,
            },
            () => {
              setTimeout(() => {
                const index = this.state.typersNames.indexOf(typerName)
                const stateTypersNames = this.state.typersNames.slice()
                stateTypersNames.splice(index, 1)
                this.setState({
                  typersNames: stateTypersNames,
                  typers: this.state.typers.filter((typer: ITyper) =>
                    includes(stateTypersNames, typer.full_name)
                  ),
                })
              }, 3000)
            }
          )
        }
        break
      }

      default: {
        Logger.log('Broadcast for: ', data.message_type)
        break
      }
    }
  }

  @bind
  private uploadFile(event: any) {
    this.handleUploadedFiles(event.target.files)
  }

  @bind
  private onDrag(): void {
    if (!this.state.hover) {
      this.setState({ hover: true })
    }
  }

  @bind
  private onDragOut() {
    if (this.state.hover) {
      this.setState({ hover: false })
    }
  }

  @bind
  private onDrop(files) {
    this.handleUploadedFiles(files)
    this.setState({ hover: false })
  }

  @bind
  private clearReply(event: any) {
    this.setState({ replyCommentId: null })
  }

  @bind
  private sendRequestRateMatchMessage(params: ParsedQuery<string>) {
    const formData = getRequestRateMatchMessageData(params)
    if (formData) {
      history.replace({
        search: '',
      })
      this.props.submit(formData, this.props.chatId)
    }
  }

  @bind
  private async submit(): Promise<any> {
    try {
      const { formData, valid } = prepareForRequest(this.state)
      if (valid) {
        this.setState({
          content: '',
          filesToUpload: [],
          failedFiles: [],
          replyCommentId: null,
          isWatcherAlert: false,
        })
        await this.props.submit(formData, this.props.chatId)
      } else {
        this.setState({ invalid: true }, () => {
          setTimeout(() => {
            this.setState({ invalid: false })
          }, 2000)
        })
      }
    } catch (error) {
      Logger.error(error)
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Chat)))
