import { FunctionComponent, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from '@mui/material'
import { showNotification } from 'src/stores/actionCreators/notifications'
import AuthorAvatar from 'src/components/AuthorAvatar'
import { LinkToChat } from 'src/components/Notifications/NotificationsSavedMessage'
import MessageCenterShipmentBlock from 'src/components/MessageCenter/MessageCenterShipmentBlock'
import { convertDateToDateTimeWithoutYear } from '../../../utils/helpers'
import { chatSaveMessage } from '../../../stores/actionCreators'
import { promisifyAction, Logger } from '../../../utils'

import './styles.scss'

type IProps = {
  comment: IComment
  saved?: boolean
}

const MessageCenterSingleMessage: FunctionComponent<IProps> = ({
  comment,
  saved,
}) => {
  const dispatch = useDispatch()
  const saveMessageAsync = promisifyAction(dispatch, chatSaveMessage)
  const { currentUserId } = useSelector((state: IGlobalState) => ({
    currentUserId: state.user.id,
  }))
  const { t } = useTranslation()

  const time: string =
    convertDateToDateTimeWithoutYear(comment.created_at) || ''

  const savedClass = saved ? 'saved' : ''
  const ownMessageClass =
    comment.author.id === currentUserId ? 'own-message' : ''
  const reply: IParentComment = comment.parent_comment || null
  const attachments: ICommentAttachment[] = comment.comment_attachments || []

  const renderOrg = useCallback((author: IAuthor): React.ReactNode => {
    return (
      <span>
        {author.full_name} | <b>{author.organization.name}</b>
      </span>
    )
  }, [])

  const renderAttachment = useCallback((attachment: ICommentAttachment) => {
    let fileName: string = attachment.original_filename
    if (fileName.length >= 30) {
      fileName = `${fileName.substring(0, 20)}...${fileName.substring(
        fileName.length - 6,
        fileName.length
      )}`
    }

    return (
      <span key={attachment.id} className="message-center-item-attachment">
        <i className="icon paperclip" />
        <span>
          <Link
            onClick={(event) => event.stopPropagation()}
            variant="body1"
            href={attachment.file_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {fileName}
          </Link>
        </span>
      </span>
    )
  }, [])

  const saveMessage = useCallback(async () => {
    try {
      await saveMessageAsync(comment.chat_id, comment.id)
      dispatch(
        showNotification({
          message: `Message successfully ${
            !comment.saved_for_user_ids.includes(currentUserId || 0) ? '' : 'un'
          }saved.`,
          severity: 'success',
        })
      )
    } catch (error) {
      Logger.error(error)
    }
  }, [comment, currentUserId])

  return (
    <div className={`message-center-item ${savedClass} ${ownMessageClass}`}>
      <div className="message-center-item-info">
        <div className="message-center-item-avatar">
          <AuthorAvatar author={comment.author} size="medium" />
        </div>
        <div className="message-center-item-content">
          <LinkToChat comment={comment}>
            <div className="message-center-item-message">
              {reply && (
                <div className="message-center-item-reply">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `${reply.author}: ${reply.content}`,
                    }}
                  />
                </div>
              )}
              <span dangerouslySetInnerHTML={{ __html: comment.content }} />
              {!!attachments.length && (
                <div className="message-center-item-attachBlock">
                  {attachments.map(renderAttachment)}
                </div>
              )}
            </div>
          </LinkToChat>
          <div className="message-center-item--bottom">
            <div className="message-center-item-name">
              {time} - {renderOrg(comment.author)}{' '}
              <LinkToChat variant="subtitle1" comment={comment} />
            </div>
            {saved ? (
              <Link variant="body1" component="button" onClick={saveMessage}>
                <i className="icon bookmark" />
                {t(
                  'top_navigation.notifications.saved_comments.remove_link',
                  'Remove from saved'
                )}
              </Link>
            ) : (
              ''
            )}
          </div>
        </div>
        <MessageCenterShipmentBlock comment={comment} />
      </div>
    </div>
  )
}

export default MessageCenterSingleMessage
