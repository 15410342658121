import {
  FunctionComponent,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useStore, useSelector } from 'react-redux'
import { isEmpty, trim, find } from 'lodash'
import Typography from '@mui/material/Typography'
import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'
import {
  Box,
  Checkbox,
  InputLabel,
  FormControlLabel,
  SelectChangeEvent,
} from '@mui/material'
import DatePicker from 'src/stories/DatePicker'
import CustomInput from 'src/components/Common/Input/MuiInput'
import { AutoCompleteSelect } from 'src/stories/MUI/Select/AutoCompleteSelect'
import { InfoTooltip } from 'src/stories/MUI/Tooltip/InfoTooltip'
import { DateTime } from 'luxon'
import { promisifyAction } from '../../../../utils'

import {
  addressSuggestions,
  purchaseOrdersCheckUnique,
} from '../../../../stores/actionCreators'

interface IProps {
  handleChangeOrder: (value: string | number, type: string) => void
  order: any
  isAlreadyExist: boolean
  isAlreadyExistShipper: boolean
  handleAlreadyExist: (status: boolean) => void
  handleAlreadyExistShipper: (status: boolean) => void
  isEdit: boolean
  newShipper: (status: boolean) => void
  handleChangeNewShipper: (field: string, value: string | number) => void
  existOrderNumber: string | null
  handleChangeAssignCondition: (status: boolean) => void
}

const DetailsBlock: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const [newPOL, setNewPOL] = useState<any>(null)
  const [assignConnection, setAssignConnection] = useState<boolean>(true)
  const dispatch = useDispatch()
  const store = useStore()
  const { incoterms, addresses } = useSelector((state: IGlobalState) => ({
    incoterms: state.bookings.incoterms,
    addresses: state.addresses.list,
  }))

  const { seller_address_id } = props.order

  useEffect(() => {
    const { loading_port } = props.order
    if (loading_port && !isEmpty(loading_port)) {
      const getDefaultPOL = async () => {
        await getPortsAsync({
          input: loading_port.code,
          only_ports: true,
        })
        const ports = store.getState().searchBooking.suggestions
        if (ports && !!ports.length) {
          setNewPOL(ports[0])
        }
      }
      getDefaultPOL()
    }
  }, [props.order.loading_port, store, setNewPOL])

  useEffect(() => {
    props.handleChangeAssignCondition(
      assignConnectionCondition && assignConnection
    )
  }, [props.order, assignConnection])

  const getPortsAsync = promisifyAction(dispatch, addressSuggestions)
  const checkOrderNumberAsync = promisifyAction(
    dispatch,
    purchaseOrdersCheckUnique
  )

  const fetchPOLs = useCallback((searchInput: string) => {
    const getPortsData = async (input: string) => {
      await getPortsAsync({
        input,
        only_ports: true,
      })
      const ports = store.getState().searchBooking.suggestions
      return ports.map((port) => {
        return {
          ...port,
          id: port.port_id,
        }
      })
    }
    return getPortsData(searchInput)
  }, [])

  const incotermOptions = useMemo(() => {
    return (incoterms || []).map((x) => ({
      id: x.code,
      label: `${x.code} - ${x.name}`,
    }))
  }, [incoterms])

  const updatePOL = (selectedPOL): void => {
    setNewPOL(selectedPOL)
    props.handleChangeOrder(
      selectedPOL ? selectedPOL.secondary_text : null,
      'loading_port_code'
    )
  }

  const handleChangeNumber = (value: string): void => {
    props.handleChangeOrder(trim(value), 'purchase_order_number')
    props.handleAlreadyExist(false)
  }

  const handleChange = (data, field): void => {
    const value: string | number = data?.id || null

    props.handleChangeOrder(value, field)
  }

  const onIncotermChange = (event: SelectChangeEvent<unknown>) => {
    props.handleChangeOrder(event.target.value as string, 'incoterm_code')
  }

  const setDate = (date: DateTime | null, fieldName: string): void => {
    if (!date) return
    const updatedDate = date.toISO()
    if (!updatedDate) return
    props.handleChangeOrder(updatedDate, fieldName)
  }

  const checkOrderNumber = async (event): Promise<any> => {
    if (event && props.existOrderNumber !== event) {
      await checkOrderNumberAsync({ purchase_order_number: event })
      const status = store.getState().purchaseOrders.isUniqueOrderNumber
      props.handleAlreadyExist(!status)
    }
  }

  const sellerId = useMemo((): number => {
    let sellerId: number = 0
    if (props.isEdit && props.order.seller.address) {
      sellerId = props.order.seller.address.id
    }

    return seller_address_id || sellerId
  }, [seller_address_id, props.order.seller])

  const isConnectionAssigned = useMemo(() => {
    const fullConnection = find(addresses, { id: sellerId })
    return !!fullConnection && !!fullConnection.connection
  }, [props.order, addresses])

  const assignConnectionCondition = useMemo(() => {
    return isConnectionAssigned && !props.order.seller.organization
  }, [props.order])

  const contacts: any[] = addresses.slice() || []

  const onAssignConnectionChange = () => {
    setAssignConnection(!assignConnection)
  }

  const shipper = useMemo(() => {
    if (sellerId) {
      return contacts.find((contact) => contact.id === sellerId)
    } else return null
  }, [sellerId])

  return (
    <>
      <Box mb={2} mt={2}>
        <Typography variant="h5">
          {t('purchase_orders.modals.purchase_modal.title', 'Order details')}
        </Typography>
      </Box>
      <div className="purchase-orders-window--block-content">
        <div className="purchase-orders-window--block-content-top">
          <div className="field-block long">
            <CustomInput
              name="purchase_order_number"
              required
              data-testid="po-number-input"
              label={t(
                'purchase_orders.modals.purchase_modal.order_number_input.label',
                'Order number'
              )}
              placeholder={t(
                'purchase_orders.modals.purchase_modal.order_number_input.placeholder',
                'Enter Order number'
              )}
              value={props.order.purchase_order_number}
              error={props.isAlreadyExist}
              helperText={
                props.isAlreadyExist
                  ? t(
                      'purchase_orders.modals.purchase_modal.order_number_input.error',
                      'PO number already exists'
                    )
                  : ''
              }
              onChange={handleChangeNumber}
              onBlur={checkOrderNumber}
            />
            {!props.isAlreadyExist && (
              <p style={{ height: '20px', margin: 0, padding: 0 }}></p>
            )}
          </div>
          <div className="field-block long">
            <AutoCompleteSelect
              required
              label={t(
                'purchase_orders.modals.purchase_modal.shipper_input.label',
                'Shipper'
              )}
              options={contacts || []}
              value={shipper}
              placeholder={t(
                'purchase_orders.modals.purchase_modal.shipper_input.placeholder',
                'Select shipper'
              )}
              onChange={(data) => handleChange(data, 'seller_address_id')}
              optionLabel={(option) => option?.name || ''}
              optionsKey="id"
            />
          </div>
          <div className="assign-block">
            <FormControlLabel
              control={
                <Checkbox
                  checked={assignConnection}
                  onChange={onAssignConnectionChange}
                  disabled={!assignConnectionCondition}
                  indeterminate={!assignConnectionCondition}
                />
              }
              label={t(
                'purchase_orders.modals.purchase_modal.add_connection_input.label',
                'Add connection as collaborator'
              )}
            />

            <InfoTooltip
              title={t(
                'purchase_orders.modals.purchase_modal.add_connection_input.tooltip',
                'By linking an address to an connection you are enabled to automatically add this organization as a collaborator to shipments and orders.'
              )}
            />
          </div>
        </div>
        <Box mb={3} className="flex new-line">
          <div className="field-block long">
            <InputLabel>
              {t(
                'purchase_orders.modals.purchase_modal.incoterm.label',
                'Incoterm'
              )}
            </InputLabel>
            <SingleSelect
              value={
                props.order?.incoterm_code || props.order?.incoterm?.code || ''
              }
              options={incotermOptions}
              onChange={onIncotermChange}
              placeholder={t('common.select', 'Select')}
              data-testid="purchase-order-incoterm-select"
            />
          </div>
          <div className="field-block long">
            <AutoCompleteSelect
              label={t(
                'purchase_orders.modals.purchase_modal.pol.label',
                'Port of Loading'
              )}
              onChange={updatePOL}
              value={newPOL}
              optionLabel={(option) => option?.main_text || ''}
              placeholder={t(
                'purchase_orders.modals.purchase_modal.pol.placeholder',
                'Search POL'
              )}
              getData={fetchPOLs}
              name="pol"
            />
          </div>
          <div className="field-block long">
            <DatePicker
              label={t(
                'purchase_orders.modals.purchase_modal.order_date.label',
                'Order date'
              )}
              value={props.order.order_date}
              onChange={(value) => setDate(value, 'order_date')}
            />
          </div>
        </Box>
        <div className="flex new-line">
          <div className="field-block long">
            <DatePicker
              label={t(
                'purchase_orders.modals.purchase_modal.ex_factory.label',
                'Ex factory'
              )}
              value={props.order.ex_factory_date}
              onChange={(value) => setDate(value, 'ex_factory_date')}
            />
          </div>
          <div className="field-block long">
            <DatePicker
              label={t(
                'purchase_orders.modals.purchase_modal.cargo_ready_date.label',
                'Cargo ready date'
              )}
              value={props.order.cargo_ready_date}
              onChange={(value) => setDate(value, 'cargo_ready_date')}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default DetailsBlock
