import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { StyledTriggerButton } from 'src/components/Overview/styled'
import ShowHideDialog from './ShowHideDialog'
import { Table, DataTableColumn } from './ShowHideDialog.props'

const ShowHideColumnsButton = ({ table }: { table: Table }) => {
  const [dialogOpen, setDialogOpen] = useState(false)

  const hiddenColumnsCount = table
    .getAllColumns()
    .filter((column: DataTableColumn) => !column.getIsVisible()).length

  const { t } = useTranslation()

  const handleClickOpen = () => {
    setDialogOpen(true)
  }
  const handleClose = () => {
    setDialogOpen(false)
  }

  return (
    <Box>
      <StyledTriggerButton
        value={hiddenColumnsCount > 0 ? ['filled'] : []}
        onClick={handleClickOpen}
        data-testid="show-hide-button"
        startIcon={<VisibilityOffIcon />}
      >
        {hiddenColumnsCount === 0 &&
          t('vessels_page.hide_fieds', 'Hide Fields')}
        {hiddenColumnsCount > 0 &&
          `${hiddenColumnsCount} ${t(
            'vessels_page.hidden_fields',
            'Hidden Fields'
          )}`}
      </StyledTriggerButton>
      <ShowHideDialog table={table} open={dialogOpen} onClose={handleClose} />
    </Box>
  )
}

export default ShowHideColumnsButton
