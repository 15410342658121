import Box from '@mui/material/Box'
import MUIAvatar from '@mui/material/Avatar'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { Avatar } from 'src/stories/Avatar'

interface ShipmentCostsPartyProps {
  logo?: string | null
  name?: string | null
  label?: string
  marginTop?: number
  className?: string
}

export const ShipmentCostsParty: React.FC<ShipmentCostsPartyProps> = ({
  logo = null,
  name,
  label = '',
  marginTop = 0,
  className = '',
}) => {
  return (
    <Box
      mt={marginTop}
      display="flex"
      sx={{ alignItems: 'center' }}
      data-testid={
        name ? 'shipment-costs-avatar' : 'shipment-costs-avatar-skeleton'
      }
    >
      {!name && (
        <>
          <Skeleton width="82px" sx={{ mr: 1 }}>
            <Typography variant="h4">.</Typography>
          </Skeleton>
          <Skeleton variant="circular">
            <MUIAvatar />
          </Skeleton>
          <Skeleton width="82px" sx={{ ml: 1 }}>
            <Typography variant="h4">.</Typography>
          </Skeleton>
        </>
      )}
      {name && (
        <>
          <Typography
            mr={1}
            variant="body1Strong"
            children={`${label}:`}
            component="span"
          />
          <Avatar
            {...{
              alt: name,
              className,
              src: logo ?? 'no-logo',
              variant: 'circular',
              size: 'small',
            }}
          />
          <Typography
            ml={1}
            variant="subtitle1"
            children={name}
            component="span"
          />
        </>
      )}
    </Box>
  )
}
