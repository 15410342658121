import { Alert, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ShipmentCostsTransactionItemsAlert = () => {
  const { t } = useTranslation()
  return (
    <Box
      sx={(theme) => ({
        pb: 1,
        px: 2,
        pt: 2,
        borderTop: `1px solid ${theme.palette.grey[50]}`,
      })}
    >
      <Alert variant="filled" severity="warning">
        {t(
          'shipment_costs.notifications.costs_are_subject_to_fluctuations',
          'These costs are subject to exchange rate fluctuations'
        )}
      </Alert>
    </Box>
  )
}

export default ShipmentCostsTransactionItemsAlert
