// @ts-nocheck
// FIXME

import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Tooltip from '@mui/material/Tooltip'
import {
  Typography,
  Button,
  Link,
  useMediaQuery,
  useTheme,
  Box,
} from '@mui/material'
import CreateRoundedIcon from '@mui/icons-material/CreateRounded'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import AltRouteIcon from '@mui/icons-material/AltRoute'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { useTranslation } from 'react-i18next'
import SelectableChip, { OptionsItem } from 'src/components/SelectableChip'
import { showNotification } from 'src/stores/actionCreators/notifications'
import ColorBadge from 'src/components/Common/ColorBadge'
import { getShipmentSelectableStatusOptions } from 'src/utils/helpers/shipmentStatus'
import { formatDesktopDate, FORMAT_DATE_TIME } from 'src/utils/helpers'
import SailingInformation from 'src/components/SailingInformation'
import { shipmentChipColor } from '../PickupsAndDeliveries/constants'
import { permissionTo, promisifyAction } from '../../utils'

import {
  getShipmentStatuses,
  mapModalDisplayChange,
  shipmentOverviewGetData,
  updateShipmentData,
} from '../../stores/actionCreators'
import { ShipmentHeadingLoader } from './ShipmentOverviewLoaders'

import './styles.scss'

type Props = {
  shipment: IOverviewShipment
  onOpenEditShipment: () => void
}

const ShipmentOverviewHeading: FunctionComponent<Props> = ({
  shipment,
  onOpenEditShipment,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const currentStatus: OptionsItem = {
    name: shipment.humanized_status,
    color: shipmentChipColor[shipment.shipment_phase],
  }
  const dispatch: Dispatch = useDispatch()
  const [selectedShipmentStatus, setShipmentStatus] = useState<OptionsItem>(
    currentStatus
  )

  useEffect(() => {
    if (shipment.visibility_only || permissionTo('shipments.status.manage')) {
      dispatch(getShipmentStatuses())
    }
  }, [])

  useEffect(() => {
    setShipmentStatus(currentStatus)
  }, [shipment.status])

  const { shipmentStatuses } = useSelector(
    (globalState: IGlobalState) => ({
      shipmentStatuses: globalState.shipments.shipmentStatuses,
    }),
    shallowEqual
  )

  const shipmentStatusesForSelect = useMemo(
    () => getShipmentSelectableStatusOptions(shipmentStatuses),
    [shipmentStatuses]
  )

  const updateShipment = promisifyAction(dispatch, updateShipmentData)

  const onShipmentStatusChange = useCallback(
    (option) => {
      setShipmentStatus(option)

      updateShipment(shipment.id.toString(), {
        status_id: option.id,
      }).then(() => {
        dispatch(
          showNotification({
            message: t('common.notifications.shipment_status_updated'),
            severity: 'success',
          })
        )
        dispatch(shipmentOverviewGetData(shipment.id.toString()))
      })
    },
    [shipment.id]
  )

  const shipmentIconClass: string = useMemo((): string => {
    switch (shipment.shipment_type) {
      case 'air':
        return 'airport'
      case 'rail':
        return 'train'
      case 'fcl':
        return 'sea'
      case 'lcl':
        return 'sea'
      default:
        return ''
    }
  }, [shipment.type])

  const shareLink = (): React.ReactElement => {
    return (
      <>
        <Link
          component="button"
          variant="body1"
          onClick={() => dispatch(mapModalDisplayChange(true))}
        >
          <i className="icon location" />
          {t('common.track_and_trace')}
        </Link>
      </>
    )
  }

  const sailingInformation = (): React.ReactElement => {
    return <SailingInformation shipmentLegs={shipment.shipment_legs} />
  }

  return !shipment.id ? (
    <ShipmentHeadingLoader />
  ) : (
    <>
      <div className="shipment-overview__title">
        <header className="shipment-overview__header">
          <div className="shipment-overview__header--names">
            <Tooltip title={shipment.loading_port} placement="bottom">
              <Typography variant="h3">{shipment.loading_port}</Typography>
            </Tooltip>
            <i className={`icon ${shipmentIconClass} mr-10 ml-10`} />
            <Tooltip title={shipment.discharge_port} placement="bottom">
              <Typography variant="h3">{shipment.discharge_port}</Typography>
            </Tooltip>
          </div>
          <div className="ml-10 shipment-overview__header--status-wrapper">
            <SelectableChip
              disabled={
                !(
                  shipment.visibility_only ||
                  permissionTo('shipments.status.manage')
                )
              }
              value={selectedShipmentStatus}
              onChange={onShipmentStatusChange}
              options={shipmentStatusesForSelect}
              variant="filled"
            />
          </div>
          {!(
            shipment.visibility_only || permissionTo('shipments.status.manage')
          ) && (
            <ColorBadge
              className={shipmentChipColor[shipment.shipment_phase]}
              content={shipment.status_name}
            />
          )}
        </header>
        {(shipment.visibility_only || permissionTo('shipments.manage')) && (
          <Button
            variant="text"
            onClick={onOpenEditShipment}
            startIcon={<CreateRoundedIcon />}
            data-testid="edit-shipment-trigger"
          >
            {t('common.buttons.edit_shipment', 'Edit shipment')}
          </Button>
        )}
        <div className="shipment-overview__header--mobile-select">
          <Tooltip title={shareLink()} placement="left-start">
            <div className="shipment-overview__header--mobile-select-dots">
              <i className="icon dots" />
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="shipment-overview__sailing-block">
        <Tooltip title={sailingInformation()} placement="bottom-start">
          <div className="shipment-overview__sailing-info">
            {shipment.shipment_legs.length === 1 && (
              <>
                <ArrowForwardIcon className="arrow" />
                <Typography>
                  {t('shipment_details.direct_shipment', 'Direct shipment')}
                </Typography>
              </>
            )}
            {shipment.shipment_legs.length > 1 && (
              <>
                <AltRouteIcon className="arrow" />
                <Typography>
                  {t('common.entities.shipmentWithCount', {
                    count: shipment.shipment_legs.length,
                    defaultValue: '{{count}} shipment legs',
                  })}
                </Typography>
              </>
            )}
          </div>
        </Tooltip>
      </div>

      {isMobile && (
        <Box mt={2}>
          <Typography component="span" variant="body1Strong">
            {t('common.etd', 'ETD')}:{' '}
          </Typography>
          <Typography component="span">
            {formatDesktopDate(shipment.estimated_departure, FORMAT_DATE_TIME)}
          </Typography>
          <br />
          <Typography component="span" variant="body1Strong">
            {t('common.etd', 'ETA')}:{' '}
          </Typography>
          <Typography component="span">
            {formatDesktopDate(shipment.estimated_arrival, FORMAT_DATE_TIME)}
          </Typography>
        </Box>
      )}
    </>
  )
}

export default ShipmentOverviewHeading
