import { createFilterOptions } from '@mui/material'
import {
  BookingPartyFormShipmentAddress,
  BookingPartyFormShipmentContact,
} from './BookingParties.props'

export const getAddressInfo = (shipmentAddress: IAddress) => {
  return {
    companyName: shipmentAddress?.name ?? '',
    addressId: shipmentAddress?.address_id ?? null,
    address: shipmentAddress?.address ?? '',
    postalCode: shipmentAddress?.postal_code ?? '',
    city: shipmentAddress?.city ?? '',
    comment: shipmentAddress?.comment ?? '',
    vatNumber: shipmentAddress?.vat_number ?? '',
    eoriNumber: shipmentAddress?.eori_number ?? '',
    country: shipmentAddress?.country?.id
      ? {
          id: shipmentAddress.country.id,
          name: shipmentAddress.country.name,
        }
      : null,
    connectionId: shipmentAddress?.connection?.id ?? null,
  }
}
export const getContactInfo = (shipmentContact: IContactItem) => {
  return {
    name: shipmentContact?.name ?? '',
    id: shipmentContact?.contact_id ?? null,
    email: shipmentContact?.email ?? '',
    phone: shipmentContact?.phone ?? '',
    comment: shipmentContact?.comment ?? '',
  }
}

export const getAutocompleteOptionLabel = (option) => {
  if (!option) {
    return ''
  }
  if (typeof option === 'string') {
    return option
  }
  if (option.inputValue) {
    return `Add "${option.inputValue}"`
  }
  return option.name
}

export const getAutocompleteFilteredOptions = (
  options,
  params,
  freeSolo: boolean = true
) => {
  const filter = createFilterOptions()
  const filtered = filter(options, params)
  const { inputValue } = params
  // Suggest the creation of a new value
  const isExisting = options.some((option) => inputValue === option.name)
  if (inputValue !== '' && !isExisting && freeSolo) {
    filtered.unshift({
      inputValue,
      id: 0,
      name: `Add "${inputValue}"`,
    })
  }

  return filtered
}

export const getAddressPayload = (
  shipmentAddress: BookingPartyFormShipmentAddress
) => {
  return {
    address_id: shipmentAddress?.addressId ?? null,
    company_name: shipmentAddress?.companyName ?? '',
    address: shipmentAddress?.address ?? '',
    city: shipmentAddress?.city ?? '',
    postal_code: shipmentAddress?.postalCode ?? '',
    country_id: shipmentAddress?.country?.id ?? null,
    vat_number: shipmentAddress?.vatNumber ?? '',
    eori_number: shipmentAddress?.eoriNumber ?? '',
    comment: shipmentAddress?.comment ?? '',
  }
}

export const getContactPayload = (
  shipmentContact: BookingPartyFormShipmentContact,
  companyAddressId: number | null
) => {
  return {
    name: shipmentContact?.name ?? null,
    email: shipmentContact?.email ?? '',
    phone: shipmentContact?.phone ?? '',
    comment: shipmentContact?.comment ?? '',
    contact_id: shipmentContact?.id ?? null,
    company_address_id: companyAddressId,
  }
}
