import { useEffect } from 'react'
import { useGetQuickFilters } from 'src/services/Api/savedFilters'
import { useGetAllowedFiltersAndColumns } from 'src/services/Api/overview'
import { getLocalStorage } from 'src/components/Common/Table/DataTable/TableWrapper.utils'
import { setLocalStorageFromSavedView } from 'src/pages/Overview/utils'
import { savedViewLocalStorageKey } from 'src/components/Overview/constants'
import { overviewPageName, pageClassName } from './constants'
import OverviewContent from './Content'
import './index.scss'

const Overview = () => {
  const {
    isFetching: isFetchingAllowedFilters,
  } = useGetAllowedFiltersAndColumns({
    params: {
      page_name: 'vessel',
    },
  })

  const { isFetching } = useGetQuickFilters(
    {
      page: overviewPageName,
      per_page: 100,
    },
    {
      refetchOnMount: false,
      onSuccess: (data) => {
        const savedViewId = getLocalStorage(savedViewLocalStorageKey, null)
        const savedView = data.find((view) => view.id === savedViewId)
        if (savedView) {
          return
        }
        const defaultView = data.find((view) => view.view_type === 'default')
        if (defaultView) {
          setLocalStorageFromSavedView(defaultView)
        } else if (data.length > 0) {
          setLocalStorageFromSavedView(data[data.length - 1])
        }
      },
    }
  )

  useEffect(() => {
    document.body.classList.add(pageClassName)

    return () => {
      document.body.classList.remove(pageClassName)
    }
  }, [])

  if (isFetching || isFetchingAllowedFilters) {
    return null
  }

  return <OverviewContent />
}

export default Overview
