import { FunctionComponent, useCallback } from 'react'
import Typography from '@mui/material/Typography'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { push } from 'connected-react-router'
import { orderBy } from 'lodash'
import { Link } from '@mui/material'
import { shipmentsForceTabClick } from '../../../stores/actionCreators'
import { promisifyAction } from '../../../utils'
import './styles.scss'

interface IProps {
  milestones: IMilestone[]
  shipmentId: number
  forQuote?: boolean
}

const selectTab = (milestone: IMilestone) => {
  if (milestone.booking_level) return 'bookings'
  if (milestone.container_level) return 'containers'
  return 'documentation'
}

const MilestonesProgressBar: FunctionComponent<IProps> = ({
  milestones,
  shipmentId,
  forQuote,
}) => {
  const dispatch: Dispatch = useDispatch()

  const forceTabClick = promisifyAction(dispatch, shipmentsForceTabClick)

  const toTab = useCallback(
    (tab: 'containers' | 'bookings' | 'price-structure' | 'documentation') => {
      dispatch(push(`/shipments/${shipmentId}/${tab}`))
      forceTabClick()
    },
    [shipmentId]
  )

  const milestoneLink = (milestone: IMilestone): React.ReactNode => {
    if (forQuote) {
      return (
        <Link
          variant="body1"
          component="button"
          onClick={toTab.bind(null, 'price-structure')}
        >
          {milestone.name}
        </Link>
      )
    }

    if (!milestone.tasks.length || milestone.reached) {
      return milestone.name
    }

    return (
      <Link
        variant="body1"
        component="button"
        onClick={toTab.bind(null, selectTab(milestone))}
      >
        {milestone.name}
      </Link>
    )
  }

  const milestoneItemPoint = (milestone: IMilestone): React.ReactNode => {
    if (!milestone.reached && milestone.status === 'overdue') {
      return (
        <div className="milestones-progress-bar--item-attention">
          <i className="icon attention" />
        </div>
      )
    } else {
      return (
        <div
          className={`milestones-progress-bar--item-point ${
            milestone.reached ? 'reached' : ''
          }`}
          data-testid={
            milestone.reached
              ? 'milestone-progress-reached'
              : 'milestone-progress-not-reached'
          }
        >
          {milestone.reached && <i className="icon check" />}
        </div>
      )
    }
  }

  return (
    <div className="milestones-progress-bar ml-15">
      {orderBy(milestones, ['reached'], ['desc']).map((milestone, index) => (
        <div
          className="milestones-progress-bar--item"
          key={`${index}_${milestone.name}`}
        >
          <div className="milestones-progress-bar--item-line" />
          <div
            className={`milestones-progress-bar--item-content ${
              milestone.reached ? 'bold' : ''
            }`}
          >
            <Typography
              variant={milestone.reached ? 'body1Strong' : 'body1'}
              children={milestoneLink(milestone)}
            />
          </div>
          {milestoneItemPoint(milestone)}
        </div>
      ))}
    </div>
  )
}

export default MilestonesProgressBar
