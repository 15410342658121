import debounce from 'lodash/debounce'
import { uniq, omit, isFunction, isEqual } from 'lodash'
import { useState, memo, useEffect, useRef } from 'react'
import {
  MRT_SortingState,
  MRT_Row,
  MRT_Updater,
  useMaterialReactTable,
  MaterialReactTable,
} from 'material-react-table'
import {
  OverviewRequest,
  OverviewFilters,
  OverviewResponse,
  OverviewResponseRecord,
} from 'src/services/Api/overview/types'
import { useFormContext } from 'react-hook-form'
import {
  useGetOverview,
  useGetOverviewVesselContainersAsync,
} from 'src/services/Api/overview'

import { WrappedTableProps } from 'src/components/Common/Table/DataTable/TableWrapper.props'
import CustomTableToolbar from 'src/components/Overview/Table/Toolbar'
import { getLocalStorage } from 'src/components/Common/Table/DataTable/TableWrapper.utils'
import { queryClient } from 'src/services/http-common'
import getMRTOptions from 'src/stories/MUI/ReactTable/utils'
import { useUpdateSavedFilter } from 'src/services/Api/savedFilters'
import { NoPickupDeliveriesScheduled } from 'src/pages/EmptyStates'
import {
  getOrderParams,
  getPreparedTableData,
  getExpandedRowData,
  isInViewport,
  handleUpdateViewSuccess,
  getLocallyStoredView,
  getUpdatedNestedTableRecords,
  handleUpdateErrors,
  getNewView,
} from '../Overview/utils'

import {
  customFields,
  groupingKey,
  entityGroupMap,
  subItemsPerPage,
  tableCellMutationKey,
  savedViewLocalStorageKey,
} from './constants'

export const getRequestFilterParams = (filters) => {
  return Object.keys(filters).reduce((acc, key) => {
    const value = filters[key]
    if (Array.isArray(value)) {
      acc[key] = value.map((v) => v?.value ?? v)
    } else {
      acc[key] = value
    }
    return acc
  }, {}) as OverviewRequest['params']
}

interface OverviewTableRow extends Omit<MRT_Row<{}>, 'original'> {
  original: {
    [key: string]: string | number | null
  }
}

const ContentTableColumns = (props: WrappedTableProps) => {
  const savedViewId = getLocalStorage(savedViewLocalStorageKey, null)
  const savedView = getLocallyStoredView()
  const { mutateAsync: updateSavedFilter } = useUpdateSavedFilter(savedViewId)
  const scrollContainerRef = useRef(null)
  const { watch, getValues, setValue } = useFormContext()
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [filterParams, setFilterParams] = useState(getValues())
  const [tableRecords, setTableRecords] = useState<OverviewResponseRecord[]>([])
  const pageIndex = (getValues('page') ? +getValues('page') : 1) - 1
  const pageSize = getValues('per_page') ? +getValues('per_page') : 20

  const pagination = {
    pageIndex,
    pageSize,
  }

  const grouping = entityGroupMap[getValues(groupingKey)] ?? null

  const tableGrouping = grouping ? [grouping] : []

  const filters = filterParams as OverviewFilters

  const { columnOrder } = props

  const columnOrderWithAggregation =
    tableGrouping.length > 0
      ? uniq(['mrt-row-expand', ...columnOrder])
      : columnOrder

  const searchAndFilterParams = getRequestFilterParams(filters)

  const tableGroupedByVessels = filterParams.group_by === 'vessel'

  const depth = tableGroupedByVessels ? { depth: 0 } : {}

  const tableData = getPreparedTableData(tableRecords)

  const { data: responseData, isLoading, isFetching } = useGetOverview(
    {
      keys: [searchAndFilterParams],
      params: {
        ...depth,
        ...searchAndFilterParams,
        custom_fields: JSON.stringify(customFields),
      },
    },
    {
      onSuccess: (data) => {
        setTableRecords(data?.data?.records ?? [])
        if (!savedView) {
          return
        }
        if (!isEqual(savedView.filter, omit(filters, 'page'))) {
          const newSavedView = getNewView(savedView.name ?? '', filters)
          updateView(newSavedView)
        }
      },
    }
  )

  const {
    isFetching: isFetchingContainers,
    fetchAsync: getVesselContainers,
  } = useGetOverviewVesselContainersAsync()

  const onPaginationChange = (updater) => {
    if (isLoading) {
      return
    }
    const newPagination = isFunction(updater) ? updater(pagination) : updater
    setValue('page', newPagination.pageIndex + 1)
    setValue('per_page', newPagination.pageSize)
  }

  const onSortingChange = (updater, oldValue: MRT_SortingState) => {
    if (isLoading) {
      return
    }
    const orderBy = getOrderParams(updater(oldValue))
    setValue('order_by', orderBy)
    setValue('page', 1)
  }

  const onGroupingChange = (updater) => {
    const newGrouping = isFunction(updater) ? updater(tableGrouping) : updater
    if (newGrouping.length === 0) {
      setValue(groupingKey, null)
    }
  }

  const setExpandedRowData = (
    res: OverviewResponse | undefined,
    id: number
  ) => {
    if (!res) {
      return
    }
    const updatedRecords = getExpandedRowData(res, id, tableRecords)

    setTableRecords(updatedRecords)
  }

  const getSubItemsData = ({ row, page }) => {
    const { groupingColumnId } = row
    if (!groupingColumnId.includes('vessel')) {
      return
    }
    const id = row.original?.shipment_vessel_id
    const subItemsSearchAndFilterParams = omit(searchAndFilterParams, [
      'page',
      'group_by',
      'per_page',
    ])
    getVesselContainers({
      id,
      page,
      per_page: subItemsPerPage,
      ...subItemsSearchAndFilterParams,
      custom_fields: JSON.stringify(customFields),
    }).then((res) => setExpandedRowData(res, id))
  }

  const handleClickOnExpandButton = ({ row }) => {
    const leafRows = row?.leafRows ?? []
    const firstBatchIsFetching = leafRows.some((leafRow: OverviewTableRow) => {
      const containerTypeName = leafRow?.original?.type_name ?? null
      return containerTypeName
    })
    if (row.getIsExpanded() || firstBatchIsFetching) {
      return
    }
    getSubItemsData({ row, page: 1 })
  }

  const loadMoreSubItems = ({ row }) => {
    if (!row.id.includes('vessel')) {
      return
    }

    const leafRowsLength = row?.leafRows?.length ?? 0
    const count = row.original?.containers_count ?? null
    const hasMore = count && leafRowsLength < count
    const totalPages = Math.ceil(count / subItemsPerPage)
    const remainingPages = Math.ceil((count - leafRowsLength) / subItemsPerPage)
    const page = totalPages - remainingPages + 1
    if (!row.getIsExpanded() || !hasMore) {
      return
    }
    getSubItemsData({ row, page })
  }

  const loadMoreSubItemsForExpandedRow = (rowID: string) => {
    const row = table.getRow(rowID) as OverviewTableRow
    if (!row) {
      return
    }
    const leafRowsLength = row?.getLeafRows().length ?? 0
    const triggerLoadMoreIndex = leafRowsLength - subItemsPerPage / 2
    const triggerLoadMoreRow = document.getElementById(
      `1-${row?.original?.shipment_vessel_id}-${triggerLoadMoreIndex}`
    )
    if (triggerLoadMoreRow) {
      const inViewport = isInViewport(
        triggerLoadMoreRow,
        scrollContainerRef?.current
      )
      if (!inViewport || isFetchingContainers) {
        return
      }
      loadMoreSubItems({ row })
    }
  }

  const onTableContainerScroll = () => {
    const expandedRows = Object.keys(table.getState().expanded)
    if (expandedRows.length === 0) {
      return
    }

    expandedRows.forEach(loadMoreSubItemsForExpandedRow)
  }

  const onTableContainerScrollWithDebounce = debounce(
    onTableContainerScroll,
    10
  )

  const getMuiTableBodyRowProps = ({ row }: { row: OverviewTableRow }) => {
    const leafRowIndex = row?.original.leafIndex ?? row.index
    return {
      id: `${row.depth}-${
        row?.original?.shipment_vessel_id ?? ''
      }-${leafRowIndex}`,
    }
  }

  const onTableSortingChange = (updater: MRT_Updater<MRT_SortingState>) => {
    if (!isFunction(updater)) {
      return
    }
    props.onSortingChange(updater)
    onSortingChange(updater, props.columnSorting)
  }

  const updateView = (requestBody) => {
    updateSavedFilter(requestBody).then((res: ShipmentQuickFilter) => {
      handleUpdateViewSuccess(res, savedViewId)
    })
  }

  const tableConfig = {
    columns: props.columns,
    enablePinning: true,
    manualFiltering: true,
    manualPagination: true,
    enableGrouping: true,
    manualSorting: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableMultiSort: true,
    enableColumnResizing: true,
    enableSorting: true,
    enableExpanding: tableGrouping.length > 0,
    autoResetPageIndex: false,
    enableGlobalFilter: false,
    enableColumnOrdering: true,
    enableColumnFilters: false,
    enableColumnPinning: true,
    onGroupingChange,
    onIsFullScreenChange: setIsFullScreen,
    data: tableData,
    paginateExpandedRows: true,
    rowCount: responseData?.data?.total_count ?? 0,
    onDensityChange: props.onDensityChange,
    onColumnOrderChange: props.onColumnOrderChange,
    onColumnSizingChange: props.onColumnSizingChange,
    onColumnPinningChange: props.onColumnPinningChange,
    onColumnVisibilityChange: props.onColumnVisibilityChange,
    muiExpandButtonProps: ({ row }) => ({
      onClick: () => {
        handleClickOnExpandButton({ row })
      },
    }),
    displayColumnDefOptions: {
      'mrt-row-expand': {
        size: 30,
      },
    },
    muiTableBodyRowProps: getMuiTableBodyRowProps,
    onSortingChange: onTableSortingChange,
    muiTableContainerProps: {
      ref: scrollContainerRef,
      className: 'scroll',
      sx: {
        flexGrow: 1,
        'tr:has(.grouped-cell) td': {
          backgroundColor: 'grey.50',
        },
        'td:has([aria-label="Expand"])': {
          paddingLeft: 0,
          paddingRight: 0,
        },
        'td:has(.filled-cell)': {
          padding: 0,
          position: 'relative',
        },
        'button[aria-label="Expand all"]': {
          display: 'none',
        },
      },
      onScroll: onTableContainerScrollWithDebounce,
    },
    muiTopToolbarProps: { sx: { mb: 1 } },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        maxHeight: '100%',
        width: '100%',
        position: 'absolute',
        left: 0,
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        '& tfoot': {
          display: 'none',
        },
      },
    },
    renderEmptyRowsFallback: NoPickupDeliveriesScheduled,
    renderTopToolbar: ({ table }) => {
      return <CustomTableToolbar table={table} />
    },
    onPaginationChange,
    initialState: { grouping: tableGrouping },
    state: {
      isLoading: isFetching || isLoading,
      pagination,
      isFullScreen,
      density: props.density,
      grouping: tableGrouping,
      showProgressBars: false,
      showColumnFilters: false,
      sorting: props.columnSorting,
      columnSizing: props.columnSizing,
      columnPinning: props.columnPinning,
      columnOrder: columnOrderWithAggregation,
      columnVisibility: props.columnVisibility,
    },
  }

  const tableConfigurations = getMRTOptions({
    tableConfig: { ...tableConfig, positionToolbarAlertBanner: 'none' },
  })

  const table = useMaterialReactTable(tableConfigurations)

  useEffect(() => {
    const debouncedCb = debounce((formValue) => {
      table.resetExpanded()

      setFilterParams(formValue)
    }, 300)

    const subscription = watch(debouncedCb)

    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    return queryClient.getMutationCache().subscribe(({ type, mutation }) => {
      if (
        type === 'updated' &&
        mutation.options.variables?.mutationKey?.[0] === tableCellMutationKey
      ) {
        const newTableRecords = getUpdatedNestedTableRecords(
          tableRecords,
          mutation
        )

        if (newTableRecords && !isEqual(tableRecords, newTableRecords)) {
          setTableRecords(newTableRecords)
        }

        handleUpdateErrors(mutation)
      }
    })
  }, [tableRecords])

  return <MaterialReactTable table={table} />
}

export default memo(ContentTableColumns)
