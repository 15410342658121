import { FunctionComponent, ReactNode, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { Button, Box, Link } from '@mui/material'
import AuthorAvatar from 'src/components/AuthorAvatar'
import { LinkToChat } from 'src/components/Notifications/NotificationsSavedMessage'
import MessageCenterShipmentBlock from 'src/components/MessageCenter/MessageCenterShipmentBlock'
import { promisifyAction, Logger, permissionTo } from '../../../utils'
import { convertDateToDateTimeWithoutYear } from '../../../utils/helpers'
import {
  acceptApproval,
  rejectApproval,
  approvalsGetData,
} from '../../../stores/actionCreators'

const MessageCenterSingleApproval: FunctionComponent<{ comment: IComment }> = ({
  comment,
}) => {
  const dispatch = useDispatch()
  const [componentBusy, setComponentBusy] = useState<boolean>(false)

  const getApprovalsAsync = promisifyAction(dispatch, approvalsGetData)
  const acceptApprovalAsync = promisifyAction(dispatch, acceptApproval)
  const rejectApprovalAsync = promisifyAction(dispatch, rejectApproval)

  let mentionedClass: string = ''
  if (comment.approval && comment.approval.mentioned_id) {
    mentionedClass = 'mentioned'
  }
  const reply: IParentComment = comment.parent_comment || null
  const attachments: ICommentAttachment[] = comment.comment_attachments || []

  const renderAttachment = useCallback(
    (attachment: ICommentAttachment): ReactNode => {
      let fileName: string = attachment.original_filename
      if (fileName.length >= 30) {
        fileName = `${fileName.substring(0, 20)}...${fileName.substring(
          fileName.length - 6,
          fileName.length
        )}`
      }

      return (
        <span key={attachment.id} className="message-center-item-attachment">
          <i className="icon paperclip" />
          <span>
            <Link
              variant="body1"
              href={attachment.file_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {fileName}
            </Link>
          </span>
        </span>
      )
    },
    []
  )

  const decline = async (event) => {
    event.stopProppagation()
    if (componentBusy) {
      return
    }

    try {
      setComponentBusy(true)
      await rejectApprovalAsync(comment.approval_id)
      dispatch(
        showNotification({ message: 'Approval declined.', severity: 'error' })
      )
    } catch (error) {
      const unknownError: any = error
      Logger.error(error)
      if (unknownError.response && unknownError.response.data) {
        dispatch(
          showNotification({
            message: unknownError.response.data.message,
            severity: 'error',
          })
        )
      }
    } finally {
      await getApprovalsAsync()
      setComponentBusy(false)
    }
  }

  const approve = async (event) => {
    event.stopPropagation()
    if (componentBusy) {
      return
    }

    try {
      setComponentBusy(true)
      await acceptApprovalAsync(comment.approval_id)
      dispatch(
        showNotification({ message: 'Approval accepted.', severity: 'success' })
      )
    } catch (error) {
      const unknownError: any = error
      Logger.error(error)
      if (unknownError.response && unknownError.response.data) {
        dispatch(
          showNotification({
            message: unknownError.response.data.message,
            severity: 'error',
          })
        )
      }
    } finally {
      await getApprovalsAsync()
      setComponentBusy(false)
    }
  }

  return (
    <div className="message-center-item">
      <div className="message-center-item-info">
        <div className="message-center-item-avatar">
          <AuthorAvatar author={comment.author} size="medium" />
        </div>
        <div className="message-center-item-content">
          <div className={`message-center-item-block ${mentionedClass}`}>
            <LinkToChat comment={comment}>
              <div className={`message-center-item-message ${mentionedClass}`}>
                {reply && (
                  <div className="message-center-item-reply">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `${reply.author}: ${reply.content}`,
                      }}
                    />
                  </div>
                )}
                {comment.approval ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: comment.approval.approval_content,
                    }}
                  />
                ) : (
                  ''
                )}
                {permissionTo(
                  `shipments.${comment.chat_type}.approvals.manage`
                ) && (
                  <div className="message-center-item-buttons">
                    <Button variant="outlined" size="large" onClick={decline}>
                      Decline
                    </Button>
                    <Box mr={1} />
                    <Button variant="contained" size="large" onClick={approve}>
                      Approve
                    </Button>
                  </div>
                )}
              </div>
            </LinkToChat>
            {!!attachments.length && (
              <div className="message-center-item-attachBlock">
                {attachments.map(renderAttachment)}
              </div>
            )}
          </div>
          <div className="message-center-item-name">
            {convertDateToDateTimeWithoutYear(comment.created_at)} -{' '}
            <span>
              {comment.author.full_name} |{' '}
              <b>{comment.author.organization.name}</b>
            </span>{' '}
            <LinkToChat variant="subtitle1" comment={comment} />
          </div>
        </div>
        <MessageCenterShipmentBlock comment={comment} />
      </div>
    </div>
  )
}

export default MessageCenterSingleApproval
