import { useTranslation } from 'react-i18next'
import { Stack, Typography } from '@mui/material'
import { ShipmentCostsParty } from './ShipmentCostsParty'

const ShipmentCostsTransactionItemsFclRowHeader = ({
  group,
  canManageCosts,
}) => {
  const { t } = useTranslation()
  return (
    <Stack
      direction="row"
      sx={{ width: '100%', px: 0.5 }}
      justifyContent="space-between"
    >
      <Stack direction="row" spacing={2}>
        <ShipmentCostsParty
          logo={group.parties[0].logo}
          name={group.parties[0].name}
          label={t('shipment_costs.offered_by', 'Seller')}
          className={group.parties[0].role_code}
        />
        {canManageCosts && (
          <ShipmentCostsParty
            logo={group.parties[1].logo}
            name={group.parties[1].name}
            label={t('shipment_costs.offered_to', 'Buyer')}
            className={group.parties[1].role_code}
          />
        )}
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography
          component="div"
          variant="body1Strong"
          children={`${group.totals.eur} ${t('common.currencies.eur', 'EUR')}`}
        />
      </Stack>
    </Stack>
  )
}

export default ShipmentCostsTransactionItemsFclRowHeader
