import { capitalize, groupBy, map } from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import MuiLink from '@mui/material/Link'
import { Link } from 'react-router-dom'
import { promisifyAction } from 'src/utils'
import { getShipmentTasks } from 'src/stores/actionCreators'
import { Table } from 'src/stories'
import { Box, Typography } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircleRounded'
import { NoTasks } from 'src/pages/EmptyStates'
import TaskStatusCell from './components/TaskStatusCell'
import DueDateComponent from './components/DueDateComponent'

export interface RowProps {
  row: IMilestoneTask
}

const columns = (props: { shipmentId: number; refreshTasks: () => void }) => ({
  name: {
    name: 'Name',
    component: (rowProps: RowProps) => rowProps.row.task_type.name,
  },
  status: {
    name: 'Status',
    component: (rowProps: RowProps) => (
      <TaskStatusCell {...rowProps} {...props} onChange={props.refreshTasks} />
    ),
  },
  due_date: {
    name: 'Due Date',
    component: DueDateComponent,
  },
  reference: {
    name: 'Reference',
    component: (rowProps: RowProps) => {
      const bookingValue =
        rowProps.row.booking?.shipper_name ||
        rowProps.row.booking?.booking_index
      const containerValue =
        rowProps.row.container?.number ||
        rowProps.row.container?.container_index

      if (bookingValue && containerValue) {
        return (
          <MuiLink
            component={Link}
            to={`/shipments/${rowProps.row.shipment.id}/bookings`}
          >
            {bookingValue} | {containerValue}
          </MuiLink>
        )
      }
      if (bookingValue) {
        return (
          <MuiLink
            component={Link}
            to={`/shipments/${rowProps.row.shipment.id}/bookings`}
          >
            {bookingValue}
          </MuiLink>
        )
      }
      if (containerValue) {
        return (
          <MuiLink
            component={Link}
            to={`/shipments/${rowProps.row.shipment.id}/containers`}
          >
            {containerValue}
          </MuiLink>
        )
      }
      return null
    },
  },
})

export default function ShipmentTasks({ match: { params } }) {
  const dispatch = useDispatch()
  const [tasks, setTasks] = useState<any>([])
  const [isLoading, setLoading] = useState(true)
  const getTasks = promisifyAction(dispatch, getShipmentTasks)

  const getTasksWithMilestones = async () => {
    const tasks = await getTasks(params.id)

    const groupedByMilestoneName = groupBy(tasks, 'milestone.name')
    const milestoneComponents = map(
      groupedByMilestoneName,
      (items, milestoneName) => {
        const milestone = items[0]?.milestone
        return {
          header: (
            <>
              <Typography variant="body1Strong">
                {capitalize(milestoneName)}
              </Typography>
              <Box ml={1} />
              {milestone?.reached && (
                <CheckCircleIcon
                  sx={{
                    color: 'primary.main',
                  }}
                  fontSize="small"
                />
              )}
            </>
          ),
          open: !milestone?.reached,
          items,
        }
      }
    )
    setTasks(milestoneComponents)
    setLoading(false)
  }

  useEffect(() => {
    getTasksWithMilestones()
  }, [])

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `.shipment-layout__content {
                  padding: 0;
                 }`,
        }}
      />
      <Table<any>
        loading={isLoading}
        columns={columns({
          shipmentId: params.id,
          refreshTasks: getTasksWithMilestones,
        })}
        rows={tasks}
        grouped={(row) => row.items}
        emptyState={<NoTasks />}
      />
    </>
  )
}
