import { DateTime } from 'luxon'
import { Button, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import DatePicker from 'src/stories/DatePicker'
import { getDateObjectFromISOString } from '../../utils'

const DateTimeCellDatePicker = ({
  value,
  onClick,
  open,
  testId,
  maxDate,
  minDate,
  onClose,
  displayDate,
  addButtonText = 'Add',
  inputFormat = 'dd-MM-yyyy HH:mm',
}: {
  value: string | null
  onClick: () => void
  open: boolean
  testId: string
  maxDate?: string
  minDate?: string
  onClose: (date: DateTime | null) => void
  addButtonText?: string
  inputFormat?: string
  displayDate: string | null
}) => {
  const dateObject: DateTime | null = value
    ? getDateObjectFromISOString(value)
    : null

  const [localValue, setLocalValue] = useState<DateTime | null>(dateObject)

  const onChange = (date: DateTime | null) => {
    setLocalValue(date)
  }

  useEffect(() => {
    if (open && dateObject !== localValue) {
      setLocalValue(dateObject)
    }
  }, [open])

  return (
    <>
      {!open && (
        <Button variant="text" size="small" onClick={onClick}>
          <Typography
            component="span"
            variant="body1"
            children={value ? displayDate : addButtonText}
          />
        </Button>
      )}
      {open && (
        <DatePicker
          closeOnSelect={false}
          open={open}
          data-testid={`${testId}-editable-date-picker`}
          inputFormat={inputFormat}
          clearable={false}
          minDate={minDate ? getDateObjectFromISOString(minDate) : undefined}
          maxDate={maxDate ? getDateObjectFromISOString(maxDate) : undefined}
          value={localValue}
          onClose={() => {
            onClose(localValue)
          }}
          onChange={onChange}
          textFieldSize="small"
        />
      )}
    </>
  )
}

export default DateTimeCellDatePicker
